import { computed, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { first, lastValueFrom } from 'rxjs';
import { CompanyDemoStateService, CompanyStateService } from '@dougs/company/shared';
import { SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import { MetricsEvent, MetricsService } from '@dougs/core/metrics';
import { ModalRef, ModalService } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { NotValidatedOperationsStateService, SourceDocumentAttachmentUtils } from '@dougs/operations/shared';
import { SalesInvoice } from '@dougs/sales-invoice/dto';
import { createdOperationDateValidatorForCompany } from '../validators/operation.validator';

@Injectable()
export class CashPaymentModalComponentService {
  constructor(
    private readonly companyStateService: CompanyStateService,
    private readonly modalRef: ModalRef,
    private readonly modalService: ModalService,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    private readonly metricsService: MetricsService,
    private readonly companyDemoStateService: CompanyDemoStateService,
  ) {}

  formGroup: FormGroup = new FormGroup({
    date: new FormControl(new Date(), [
      Validators.required,
      createdOperationDateValidatorForCompany(this.companyStateService.activeCompany, 'cashPayment'),
    ]),
    amount: new FormControl('', [Validators.required]),
    category: new FormControl('', [Validators.required]),
    memo: new FormControl(''),
  });

  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();
  operation?: Operation;

  private readonly toAttachSalesInvoices: WritableSignal<SalesInvoice[]> = signal([]);
  toAttachSalesInvoices$: Signal<SalesInvoice[]> = this.toAttachSalesInvoices.asReadonly();

  toAttachSalesInvoicesSourceDocumentAttachments$: Signal<SourceDocumentAttachment[]> = computed(() =>
    this.toAttachSalesInvoices$()?.map((salesInvoice) =>
      SourceDocumentAttachmentUtils.createSourceDocumentAttachmentFromSalesInvoice(salesInvoice),
    ),
  );

  filesMap: Map<string, File> = new Map<string, File>();

  private readonly tmpSourceDocumentAttachments: WritableSignal<SourceDocumentAttachment[]> = signal<
    SourceDocumentAttachment[]
  >([]);
  tmpSourceDocumentAttachments$: Signal<SourceDocumentAttachment[]> = this.tmpSourceDocumentAttachments.asReadonly();

  allSourceDocumentAttachments$: Signal<SourceDocumentAttachment[]> = computed(() => [
    ...this.toAttachSalesInvoicesSourceDocumentAttachments$(),
    ...this.tmpSourceDocumentAttachments$(),
  ]);

  get date(): AbstractControl {
    return this.formGroup.get('date') as FormControl;
  }

  get amount(): AbstractControl {
    return this.formGroup.get('amount') as FormControl;
  }

  get category(): AbstractControl {
    return this.formGroup.get('category') as FormControl;
  }

  get memo(): AbstractControl {
    return this.formGroup.get('memo') as FormControl;
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.invalid) {
      this.isLoading.set(true);
      if (await this.saveCashPayment()) {
        if (await lastValueFrom(this.companyDemoStateService.hasDemoMode$.pipe(first()))) {
          const ctaEvent: MetricsEvent = {
            action: `CTA Confirmed`,
            category: `Accounting Demo NDF`,
          };
          this.metricsService.pushGAEvent(ctaEvent);
          this.metricsService.pushMixpanelEvent(ctaEvent);
        }

        this.modalRef.close();
      }
      this.isLoading.set(false);
    }
  }

  private async saveCashPayment(): Promise<boolean> {
    const { date, category, memo, amount } = this.formGroup.value;
    const createdOperation: Operation | null = await this.notValidatedOperationsStateService.createOperation(
      this.companyStateService.activeCompany.id,
      {
        type: 'cashPayment',
        date,
        memo,
        amount,
        breakdowns: [
          {
            amount,
            categoryId: category.id,
          },
        ],
      },
      Array.from(this.filesMap.values()),
    );
    if (createdOperation && this.toAttachSalesInvoices$()?.length) {
      await Promise.all(
        this.toAttachSalesInvoices$().map((salesInvoice) =>
          this.notValidatedOperationsStateService.attachSourceDocument(createdOperation, salesInvoice.sourceDocumentId),
        ),
      );
    }
    return !!createdOperation;
  }

  onUploadFiles(files: FileList): void {
    Array.from(files).forEach((file: File) => {
      const tmpSda: SourceDocumentAttachment =
        SourceDocumentAttachmentUtils.createSourceDocumentAttachmentFromFile(file);
      this.filesMap.set(tmpSda.sourceDocument.tempUuid as string, file);
      this.tmpSourceDocumentAttachments.update((tmpSdas) => [...tmpSdas, tmpSda]);
    });
  }

  onDeleteSourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment): void {
    if (
      sourceDocumentAttachment.sourceDocument?.type === SourceDocumentType.SALES_INVOICE &&
      sourceDocumentAttachment.sourceDocument?.externalId
    ) {
      this.removeTempSalesInvoiceAttachment(sourceDocumentAttachment.sourceDocument.externalId);
    } else {
      this.deleteTmpSourceDocumentAttachment(sourceDocumentAttachment);
    }
  }

  deleteTmpSourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment): void {
    this.tmpSourceDocumentAttachments.update((tmpSda) =>
      tmpSda.filter((tmp) => tmp.sourceDocument?.tempUuid !== sourceDocumentAttachment.sourceDocument?.tempUuid),
    );
    this.filesMap.delete(sourceDocumentAttachment.sourceDocument.tempUuid as string);
  }

  async openAttachSalesInvoiceItem(): Promise<void> {
    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { AttachSalesInvoiceModalComponent } = await import('@dougs/sales-invoice/ui');
    const toAttachSalesInvoices: SalesInvoice[] =
      (await lastValueFrom(this.modalService.open<SalesInvoice[]>(AttachSalesInvoiceModalComponent, {}).afterClosed$))
        ?.data || [];
    this.toAttachSalesInvoices.set(toAttachSalesInvoices);
  }

  async closeModal(): Promise<void> {
    if (await lastValueFrom(this.companyDemoStateService.hasDemoMode$.pipe(first()))) {
      const ctaEvent: MetricsEvent = {
        action: `CTA Closed`,
        category: `Accounting Demo NDF`,
      };
      this.metricsService.pushGAEvent(ctaEvent);
      this.metricsService.pushMixpanelEvent(ctaEvent);
    }
    this.modalRef.close();
  }

  removeTempSalesInvoiceAttachment(salesInvoiceId: string): void {
    this.toAttachSalesInvoices.update((salesInvoices) =>
      salesInvoices.filter((salesInvoice) => salesInvoice.id !== salesInvoiceId),
    );
  }
}
