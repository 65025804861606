import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MetricsService } from '@dougs/core/metrics';
import { MODAL_DATA, ModalRef, ModalService } from '@dougs/ds';
import { AssociationSlotCandidate, AssociationSlotCandidates, Operation } from '@dougs/operations/dto';

@Injectable()
export class SelectCandidateSalesChannelComponentService {
  constructor(
    @Inject(MODAL_DATA)
    public data: {
      candidates: AssociationSlotCandidates;
      operation: Operation;
      enableSearch: boolean;
      disableClose: boolean;
    },
    private readonly modalRef: ModalRef<Record<string, string>, AssociationSlotCandidate>,
    private readonly metricsService: MetricsService,
    private readonly modalService: ModalService,
  ) {}

  selectItem(item: Record<string, string>): void {
    this.modalRef.close(item);
  }

  async openCreateSalesChannelModal(e: Event): Promise<void> {
    e.preventDefault();
    const { CreateSalesChannelModalComponent } = await import('@dougs/ecommerce/ui');
    const createdSalesChannelId = (
      await lastValueFrom(this.modalService.open<number>(CreateSalesChannelModalComponent).afterClosed$)
    ).data;
    if (createdSalesChannelId) {
      this.selectItem({ id: createdSalesChannelId.toString() });
    }
    this.metricsService.pushMixpanelEvent('e-Commerce Sales Channels Created', {
      'CTA Location': 'Accounting Module',
    });
  }
}
