<div class="operation-detail__breakdown-association" dougsFormFieldControl>
  <ng-container *ngFor="let part of association.messageParts">
    <span *ngIf="part.type === 'text'" [innerHTML]="part.text"></span>
    <div
      class="operation-detail__breakdown-association__slot"
      (click)="
        $event.stopPropagation();
        operationBreakdownAssociationComponentService.handleSlotAction(
          operation,
          breakdown,
          association,
          association.slots[part.slotName],
          isInOperationListModal,
          mixpanelModalName
        )
      "
      [ngClass]="{ 'is-disabled': !operationBreakdownAssociationComponentService.isAssignable(association, part) }"
      *ngIf="operationBreakdownAssociationComponentService.isRecordOrEnum(association, part)"
    >
      <dougs-avatar
        *ngIf="operationBreakdownAssociationComponentService.shouldShowAvatar(association, part)"
        class="mr-4"
        size="tiny"
        [image]="operationBreakdownAssociationComponentService.getIconInlineUrl(association, part)"
      ></dougs-avatar>
      <img
        class="operation-detail__breakdown-association__slot__icon mr-4"
        [src]="operationBreakdownAssociationComponentService.getIconInlineUrl(association, part)"
        *ngIf="
          operationBreakdownAssociationComponentService.getIconInlineUrl(association, part) &&
          operationBreakdownAssociationComponentService.getIconInlineUrl(association, part) !== '' &&
          operationBreakdownAssociationComponentService.getSlotType(association.slots[part.slotName]) === 'enum'
        "
      />
      {{ operationBreakdownAssociationComponentService.getInlineLabel(association, part) }}
    </div>
    <i
      *ngIf="operationBreakdownAssociationComponentService.shouldShowSelector(association, part)"
      (click)="
        $event.stopPropagation();
        operationBreakdownAssociationComponentService.handleSlotAction(
          operation,
          breakdown,
          association,
          association.slots[part.slotName],
          isInOperationListModal,
          mixpanelModalName
        )
      "
      class="fal fa-angle-down ml-4 fa-lg operation-detail__breakdown-association__select-icon"
    ></i>
    &nbsp;
    <ng-container *ngIf="operationBreakdownAssociationComponentService.isBoolean(association, part)">
      <dougs-checkbox
        appearance="toggle"
        [showYesNo]="true"
        (click)="$event.stopPropagation()"
        [ngModel]="breakdown.associationData[association.slots[part.slotName].key]"
        (ngModelChange)="
          operationBreakdownAssociationComponentService.handleBooleanChange(
            breakdown,
            association.slots[part.slotName],
            $event
          )
        "
      ></dougs-checkbox>
    </ng-container>
    <ng-container *ngIf="operationBreakdownAssociationComponentService.isInteger(association, part)">
      <span
        class="operation-detail__breakdown-association__integer-slot mr-8 py-4 px-8"
        contenteditable="true"
        (click)="$event.stopPropagation()"
        [ngModelOptions]="{ updateOn: 'blur' }"
        #contenteditable
        ngDefaultControl
        [ngModel]="breakdown.associationData[association.slots[part.slotName].key]"
        (ngModelChange)="
          operationBreakdownAssociationComponentService.handleIntegerChange(
            content,
            breakdown,
            association.slots[part.slotName]
          )
        "
      >
        {{ breakdown.associationData[association.slots[part.slotName].key] }}
      </span>
    </ng-container>
  </ng-container>
</div>
