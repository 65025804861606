import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { PanelInfoComponent, PillComponent } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { EcommerceOperationSynchronizationService } from '../../../../../services/ecommerce-operation-synchronization.service';

const ANIMATION = '300ms ease-in-out';

@Component({
  selector: 'dougs-ecommerce-shopify-operation-panel',
  templateUrl: 'ecommerce-shopify-operation-panel.component.html',
  styleUrls: ['ecommerce-shopify-operation-panel.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, PillComponent, PanelInfoComponent],
  animations: [
    trigger('slideUpDown', [
      transition(':enter', [
        style({
          height: 0,
          paddingTop: 0,
          paddingBottom: 0,
          marginTop: 0,
          overflow: 'hidden',
        }),
        animate(
          ANIMATION,
          style({
            height: '*',
            paddingTop: '*',
            paddingBottom: '*',
            marginTop: '*',
          }),
        ),
      ]),
      transition(':leave', [
        style({
          overflow: 'hidden',
        }),
        animate(
          ANIMATION,
          style({
            height: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginTop: 0,
          }),
        ),
      ]),
    ]),
  ],
})
export class EcommerceShopifyOperationPanelComponent {
  constructor(
    public readonly ecommerceOperationSynchronizationService: EcommerceOperationSynchronizationService,
    public readonly companyStateService: CompanyStateService,
    public readonly userStateService: UserStateService,
  ) {}
}
