import { Inject, Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { first, take } from 'rxjs/operators';
import { CompanyDemoStateService, CompanyStateService } from '@dougs/company/shared';
import { MetricsEvent, MetricsService } from '@dougs/core/metrics';
import { ConfirmationModalComponent, MODAL_DATA, ModalRef, ModalService, OverlayCloseEvent } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { NotValidatedOperationsStateService } from '@dougs/operations/shared';
import { createdOperationDateValidatorForCompany } from '../validators/operation.validator';

@Injectable()
export class KilometricIndemnityModalComponentService {
  formGroup: FormGroup = new FormGroup({
    date: new FormControl(new Date(), [
      Validators.required,
      createdOperationDateValidatorForCompany(this.companyStateService.activeCompany, 'indemnity'),
    ]),
    kilometers: new FormControl(0, [Validators.required, Validators.min(0.01)]),
    memo: new FormControl(null),
  });

  isLoading = false;

  constructor(
    @Inject(MODAL_DATA)
    public readonly data: {
      operation: Operation;
      carId?: number;
    },
    private readonly modalRef: ModalRef,
    private readonly companyStateService: CompanyStateService,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
    private readonly companyDemoStateService: CompanyDemoStateService,
  ) {
    if (data?.operation) {
      this.date.setValue(data.operation.date, { emitEvent: false });
      this.kilometers.setValue(this.operationKilometers, { emitEvent: false });
      this.memo.setValue(data.operation.memo, { emitEvent: false });
    }
  }

  get date(): AbstractControl {
    return this.formGroup.get('date') as FormControl;
  }

  get kilometers(): AbstractControl {
    return this.formGroup.get('kilometers') as FormControl;
  }

  get memo(): AbstractControl {
    return this.formGroup.get('memo') as FormControl;
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.invalid) {
      this.isLoading = true;
      let result: boolean;
      if (this.data?.operation) {
        result = await this.updateKilometricIndemnity();
      } else {
        result = await this.saveKilometricIndemnity(this.data?.carId);
      }

      if (result) {
        if (await lastValueFrom(this.companyDemoStateService.hasDemoMode$.pipe(first()))) {
          const ctaEvent: MetricsEvent = {
            action: `CTA Confirmed`,
            category: `Accounting Demo NDF`,
          };
          this.metricsService.pushGAEvent(ctaEvent);
          this.metricsService.pushMixpanelEvent(ctaEvent);
        }

        this.modalRef.close(true);
      }
      this.isLoading = false;
    }
  }

  private async saveKilometricIndemnity(carId?: number): Promise<boolean> {
    const { date, kilometers, memo } = this.formGroup.value;
    const operation = {
      type: 'kilometricIndemnity',
      date,
      memo,
      breakdowns: [{ amount: 0, associationData: { kilometers, carId }, categoryId: -1 }],
    };
    return !!(await this.notValidatedOperationsStateService.createOperation(
      this.companyStateService.activeCompany.id,
      operation,
    ));
  }

  removeKilometricIndemnity(): void {
    this.modalService
      .open(ConfirmationModalComponent, {
        data: {
          title: "Supprimer l'indemnité kilométrique",
          body: `Êtes-vous sûr de vouloir supprimer cette indemnité kilométrique ? Cette action est définitive.`,
          noText: 'Annuler',
          yesText: "Oui, supprimer l'indemnité kilométrique",
        },
      })
      .afterClosed$.pipe(take(1))
      .subscribe(async (res: OverlayCloseEvent<unknown>) => {
        if (res.data && this.data?.operation) {
          const success = await this.notValidatedOperationsStateService.removeOperation(this.data.operation);
          if (success) {
            this.modalRef.close();
          }
        }
      });
  }

  async closeModal(): Promise<void> {
    if (await lastValueFrom(this.companyDemoStateService.hasDemoMode$.pipe(first()))) {
      const ctaEvent: MetricsEvent = {
        action: `CTA Closed`,
        category: `Accounting Demo KM`,
      };
      this.metricsService.pushGAEvent(ctaEvent);
      this.metricsService.pushMixpanelEvent(ctaEvent);
    }
    this.modalRef.close();
  }

  private async updateKilometricIndemnity(): Promise<boolean> {
    const { date, kilometers, memo } = this.formGroup.value;
    const updatedOperation: Operation = {
      ...this.data.operation,
      date,
      memo,
      breakdowns: this.data.operation?.breakdowns.map((b) =>
        b.isCounterpart
          ? { ...b }
          : {
              ...b,
              associationData: { ...b.associationData, kilometers },
            },
      ),
    } as Operation;
    return !!(await this.notValidatedOperationsStateService.updateOperation(updatedOperation));
  }

  private get operationKilometers(): number {
    const mainBreakdown = this.data?.operation.breakdowns.find((b) => !b.isCounterpart);
    return mainBreakdown?.associationData?.kilometers ?? 0;
  }
}
