import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PanelInfoComponent, TrackByPipe } from '@dougs/ds';
import { EcommercePanelInfo } from '@dougs/ecommerce/dto';

@Component({
  selector: 'dougs-ecommerce-panel-info',
  templateUrl: './ecommerce-panel-info.component.html',
  styleUrls: ['./ecommerce-panel-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, PanelInfoComponent, TrackByPipe],
})
export class EcommercePanelInfoComponent {
  @Input() panelInfo!: EcommercePanelInfo;
}
