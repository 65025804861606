import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { AbstractQueueService } from '@dougs/core/queue';
import { Operation, OperationQueue } from '@dougs/operations/dto';
import {
  AbstractOperationsStateService,
  OPERATION_STATE_TOKEN,
  OperationsEventsService,
} from '@dougs/operations/shared';

@Injectable()
export class OperationQueueService extends AbstractQueueService<Operation, OperationQueue> {
  constructor(
    private readonly operationsEventsService: OperationsEventsService,
    @Inject(OPERATION_STATE_TOKEN) private readonly abstractOperationsStateService: AbstractOperationsStateService<any>,
  ) {
    super();
  }

  private readonly shouldShowOperationSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public shouldShowOperation$: Observable<boolean> = this.shouldShowOperationSubject.asObservable();

  updateModelInState: (model: Operation, queueParams: OperationQueue) => void = (
    operationUpdated: Operation,
    { validate },
  ) => {
    validate
      ? this.operationsEventsService.propagateValidateOperation(operationUpdated)
      : this.operationsEventsService.propagateUpdateOperation(operationUpdated);
  };
  resetCall: (queueParams: OperationQueue) => Observable<Operation> = () => {
    return from(this.abstractOperationsStateService.refreshOperationById(this.model.companyId, this.model.id));
  };

  preCallTreatmentFn: (queueParams: OperationQueue) => void = (queueParams: OperationQueue) => {
    const { validate, useOptimistic } = queueParams;
    this.model && validate && this.hideOperation();
    useOptimistic && this.updateModelInState(this.model, queueParams);
  };

  postCallTreatmentFn: (callResult: Operation | null, queueParams: OperationQueue) => void = (
    operationUpdated: Operation | null,
    { propagateValidation, validate, autoCategorize, autoAssociate },
  ) => {
    if (propagateValidation && !validate && operationUpdated) {
      this.abstractOperationsStateService.propagateValidateOperation(operationUpdated);
    }

    if (operationUpdated && autoCategorize) {
      void this.abstractOperationsStateService.autoCategorizeOperations(operationUpdated);
    }
    if (operationUpdated && autoAssociate) {
      void this.abstractOperationsStateService.autoAssociateOperations(operationUpdated);
    }
    if (validate && !operationUpdated) {
      this.showOperation();
    }
  };

  buildCall: (updatedOperation: Operation, queueParams: OperationQueue) => () => Observable<Operation | null> = (
    updatedOperation: Operation,
    queueParams: OperationQueue,
  ) => {
    const { breakdown } = queueParams;
    return (): Observable<Operation | null> =>
      from(this.abstractOperationsStateService.updateOperationWithoutStateUpdate(updatedOperation, breakdown));
  };

  hideOperation(): void {
    this.shouldShowOperationSubject.next(false);
  }

  showOperation(): void {
    this.shouldShowOperationSubject.next(true);
  }
}
