import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { AssociationSlotCandidate, AssociationSlotCandidates } from '@dougs/operations/dto';
import { EstablishmentsStateService } from '@dougs/settings/shared';

@Injectable()
export class SelectCandidateEstablishmentComponentService {
  refreshEstablishments$: Observable<void> = this.companyStateService.activeCompany$.pipe(
    concatMap((activeCompany) => this.establishmentsStateService.refreshEstablishments(activeCompany.id)),
  );

  constructor(
    @Inject(MODAL_DATA)
    public data: { candidates: AssociationSlotCandidates; companyId: number },
    private readonly modalRef: ModalRef<AssociationSlotCandidate, AssociationSlotCandidate>,
    private readonly establishmentsStateService: EstablishmentsStateService,
    private readonly companyStateService: CompanyStateService,
  ) {}

  selectItem(item: AssociationSlotCandidate): void {
    this.modalRef.close(item);
  }
}
