import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  AvatarComponent,
  BlankStateComponent,
  ControlFormFieldDirective,
  DougsDatePipe,
  FormFieldComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
  PanelInfoComponent,
  PrefixFormFieldDirective,
} from '@dougs/ds';
import { SelectCandidatePartnerComponentService } from '../../services/select-candidate-partner.component.service';

@Component({
  selector: 'dougs-select-candidate-partner',
  templateUrl: './select-candidate-partner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    FormFieldComponent,
    PanelInfoComponent,
    PrefixFormFieldDirective,
    ControlFormFieldDirective,
    AvatarComponent,
    CommonModule,
    ReactiveFormsModule,
    BlankStateComponent,
    DougsDatePipe,
  ],
  providers: [SelectCandidatePartnerComponentService],
})
export class SelectCandidatePartnerComponent {
  constructor(public readonly selectCandidatePartnerComponentService: SelectCandidatePartnerComponentService) {}
}
