import { Inject, Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { concatMap, debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { RoutingService, URL } from '@dougs/core/routing';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import {
  Association,
  AssociationSlot,
  AssociationSlotCandidate,
  AssociationSlotDescriptor,
  Breakdown,
} from '@dougs/operations/dto';
import { OperationAssociationStateService } from '@dougs/operations/shared';

@Injectable()
export class SelectCandidatePartnerComponentService {
  shouldShowAllPartners = true;
  searchControl = new FormControl('', { nonNullable: true });
  hasEndedPartners = false;

  private readonly candidates: BehaviorSubject<AssociationSlotCandidate[]> = new BehaviorSubject<
    AssociationSlotCandidate[]
  >([]);

  candidates$: Observable<AssociationSlotCandidate[]> = this.candidates.asObservable();

  searchControlValueChanges$ = this.searchControl.valueChanges.pipe(
    debounceTime(300),
    distinctUntilChanged(),
    concatMap((search: string) =>
      this.operationAssociationStateService.getBreakdownAssociationCandidates(
        this.data.breakdown.companyId,
        this.data.breakdown.operationId,
        this.data.breakdown.id,
        this.data.association.name,
        this.data.slot.name,
        0,
        search,
      ),
    ),
    tap((candidates) => this.candidates.next(candidates.items)),
  );

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      descriptor: AssociationSlotDescriptor;
      breakdown: Breakdown;
      association: Association;
      slot: AssociationSlot;
      items: AssociationSlotCandidate[];
    },
    private readonly routingService: RoutingService,
    private readonly operationAssociationStateService: OperationAssociationStateService,
    private readonly modalRef: ModalRef<AssociationSlotCandidate, AssociationSlotCandidate>,
    private readonly router: Router,
  ) {
    this.candidates.next(this.data.items);
    this.hasEndedPartners = this.data.items?.some((partner) => partner.isEnded);
  }

  selectItem(item: AssociationSlotCandidate): void {
    this.modalRef.close(item);
  }

  async showSettings(e: Event): Promise<void> {
    e.preventDefault();
    await this.router.navigateByUrl(this.routingService.createUrl([URL.SETTINGS]).toString());
  }

  showAllPartners(e: Event): void {
    e.preventDefault();
    this.shouldShowAllPartners = true;
  }
}
