<div dougsModalTitle>
  <h6>{{ selectCandidateCarComponentService.data?.candidates.descriptor.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent class="modal-container">
  <p
    class="small"
    *ngIf="
      !selectCandidateCarComponentService.data?.candidates.items.length &&
      !selectCandidateCarComponentService.data?.candidates.descriptor.allowRental
    "
  >
    Vous n'avez pas de {{ selectCandidateCarComponentService.data?.candidates.descriptor.description.long }}
  </p>
  <ul class="select-candidate">
    <li
      *ngFor="let item of selectCandidateCarComponentService.notEndedCars$ | async"
      class="p-8"
      (click)="selectCandidateCarComponentService.selectItem({ id: item.value })"
    >
      <img *ngIf="item.iconUrl" class="mr-16" [src]="item.iconUrl" [alt]="item.label" />
      <div>
        {{ item.label }}
        <p class="small" [innerHTML]="item.description"></p>
        <p class="small" *ngIf="item.isEnded">Véhicule sorti le {{ item.endDate | date: 'dd/MM/yyyy' }}</p>
      </div>
    </li>
    <ng-container *ngIf="selectCandidateCarComponentService.showEndedCars$ | async">
      <li
        *ngFor="let item of selectCandidateCarComponentService.endedCars$ | async"
        class="p-8"
        (click)="selectCandidateCarComponentService.selectItem({ id: item.value })"
      >
        <img *ngIf="item.iconUrl" class="mr-16" [src]="item.iconUrl" [alt]="item.label" />
        <div>
          {{ item.label }}
          <p class="small" [innerHTML]="item.description"></p>
          <p class="small" *ngIf="item.isEnded">Véhicule sorti le {{ item.endDate | date: 'dd/MM/yyyy' }}</p>
        </div>
      </li>
    </ng-container>
    <li
      *ngIf="selectCandidateCarComponentService.data?.candidates.descriptor.allowRental"
      class="p-8"
      (click)="selectCandidateCarComponentService.selectRentalCar()"
    >
      <img class="mr-16" src="/dynamic-svg/fa-car-side" alt="Rental car" />
      <div>Véhicule de location courte durée</div>
    </li>
  </ul>
  <a
    href="#"
    *ngIf="
      !(selectCandidateCarComponentService.showEndedCars$ | async) &&
      (selectCandidateCarComponentService.endedCars$ | async).length
    "
    (click)="selectCandidateCarComponentService.displayAllCars($event)"
  >
    Afficher les véhicules sortis
  </a>
  <dougs-panel-info class="mt-8">
    Votre {{ selectCandidateCarComponentService.data?.candidates.descriptor.description.long }} n'est pas dans la
    liste&nbsp;? Allez dans vos paramètres pour
    <a href="#" (click)="selectCandidateCarComponentService.createCar($event)">modifier ou ajouter un véhicule</a>
    . Merci de vérifier que le champ "date de sortie" n'est pas rempli.

    <span
      *ngIf="
        selectCandidateCarComponentService.data?.candidates.descriptor?.candidateRecordFilter?.financingType ===
        'leaseWithPurchaseOption'
      "
    >
      <br />
      <br />
      Si votre véhicule est un LLD, choisissez la catégorie "Location de longue durée".
    </span>
    <span
      *ngIf="
        selectCandidateCarComponentService.data?.candidates.descriptor?.candidateRecordFilter?.financingType ===
        'longTermRental'
      "
    >
      <br />
      <br />
      Si votre véhicule est un LOA, choisissez la catégorie "Location avec Option d'Achat (crédit bail)".
    </span>
  </dougs-panel-info>
</div>
