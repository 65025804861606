import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  CheckboxComponent,
  ControlFormFieldDirective,
  FormFieldComponent,
  InputDatepickerComponent,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
} from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { OperationDetailsActionsComponentService } from '../../../../../services/operation-details-actions.component.service';
import { OperationComponentService } from '../../../../../services/operation.component.service';

@Component({
  selector: 'dougs-operation-details-actions',
  templateUrl: './operation-details-actions.component.html',
  styleUrls: ['./operation-details-actions.component.scss'],
  providers: [OperationDetailsActionsComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ControlFormFieldDirective,
    InputDatepickerComponent,
    ReactiveFormsModule,
    FormFieldComponent,
    SelectComponent,
    SelectOptionComponent,
    CheckboxComponent,
    PanelInfoComponent,
  ],
})
export class OperationDetailsActionsComponent {
  @Input() shouldShowDate = true;
  @Input() isCompact = false;

  @Input()
  set operation(operation: Operation) {
    this.operationDetailsActionsComponentService.operation = operation;
  }

  constructor(
    @Inject(OPERATION_STATE_TOKEN) private readonly operationsStateService: AbstractOperationsStateService<any>,
    public readonly operationComponentService: OperationComponentService,
    public readonly operationDetailsActionsComponentService: OperationDetailsActionsComponentService,
  ) {}

  trackByIndex(index: number): number {
    return index;
  }
}
