<form
  class="form-investment"
  (ngSubmit)="cashPaymentModalComponentService.onSubmit()"
  [formGroup]="cashPaymentModalComponentService.formGroup"
  *ngIf="cashPaymentModalComponentService.formGroup"
>
  <div dougsModalTitle>
    <h6>Ajouter une recette en espèces</h6>
    <i (click)="cashPaymentModalComponentService.closeModal()" class="fal fa-times pointer"></i>
  </div>
  <div dougsModalContent>
    <dougs-panel-info
      *ngIf="cashPaymentModalComponentService.date?.hasError('companyLockDate')"
      [type]="'error'"
      class="mb-16"
    >
      <span>{{ cashPaymentModalComponentService.date?.getError('companyLockDate') }}</span>
    </dougs-panel-info>
    <dougs-panel-info type="info" class="mb-16">
      Déclarer une
      <b>recette en espèces</b>
      vous évite de porter vos espèces à la banque. Elles seront comptabilisées comme une recette prélevée pour une
      utilisation personnelle&nbsp;: vous êtes ainsi libre d’utiliser ces sommes d’argent comme vous le souhaitez.
    </dougs-panel-info>
    <div class="form-field-line">
      <dougs-input-datepicker formControlName="date"></dougs-input-datepicker>
      <dougs-form-field>
        <label dougsFormFieldLabel for="amount">Montant</label>
        <input
          id="amount"
          type="number"
          formControlName="amount"
          min="0"
          step="0.01"
          pattern="^[0-9]+(,|\.[0-9]{1,2})?$"
          dougsFormFieldControl
        />
        <i dougsFormFieldSuffix class="fas fa-euro-sign"></i>
        <span *ngIf="formService.isControlInvalid(cashPaymentModalComponentService.amount)" dougsFormFieldError>
          <span *ngIf="cashPaymentModalComponentService.amount.hasError('required')">Ce champ est requis</span>
          <span *ngIf="cashPaymentModalComponentService.amount.hasError('pattern')">
            Maximum deux chiffres après la virgule
          </span>
        </span>
      </dougs-form-field>
    </div>
    <dougs-form-field>
      <label dougsFormFieldLabel>Catégorie</label>
      <dougs-category
        formControlName="category"
        categoryType="cashPayment"
        [breakdownIsInbound]="true"
        [operationDate]="cashPaymentModalComponentService.formGroup.get('date')?.value"
        dougsFormFieldControl
      ></dougs-category>
      <span *ngIf="formService.isControlInvalid(cashPaymentModalComponentService.category)" dougsFormFieldError>
        <span *ngIf="cashPaymentModalComponentService.category.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>
    <dougs-form-field>
      <label dougsFormFieldLabel for="memo">Mémo</label>
      <input id="memo" type="text" formControlName="memo" dougsFormFieldControl />
    </dougs-form-field>
    <div class="files mt-8">
      <div class="file-list">
        <dougs-file-input
          (uploadFiles)="cashPaymentModalComponentService.onUploadFiles($event)"
          [shouldAddSalesInvoice]="true"
          (attachFromSalesInvoice)="cashPaymentModalComponentService.openAttachSalesInvoiceItem()"
        ></dougs-file-input>
        <dougs-source-document-pill
          *ngFor="
            let sourceDocumentAttachment of cashPaymentModalComponentService.allSourceDocumentAttachments$();
            trackBy: 'id' | trackBy
          "
          [sourceDocumentAttachment]="sourceDocumentAttachment"
          [canDelete]="true"
          (deleteSourceDocumentAttachment)="cashPaymentModalComponentService.onDeleteSourceDocumentAttachment($event)"
        ></dougs-source-document-pill>
      </div>
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" (click)="cashPaymentModalComponentService.closeModal()">Annuler</dougs-button>
    <dougs-button type="submit">Ajouter</dougs-button>
  </div>
</form>
<dougs-loader-fullpage *ngIf="cashPaymentModalComponentService.isLoading$()"
  >Création en cours...
</dougs-loader-fullpage>
