import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MODAL_DATA, ModalRef, ModalService } from '@dougs/ds';
import { AssociationSlotCandidate, AssociationSlotCandidates } from '@dougs/operations/dto';
import { Supplier } from '@dougs/suppliers/dto';

@Injectable()
export class SelectCandidateSupplierComponentService {
  constructor(
    @Inject(MODAL_DATA)
    public data: { candidates: AssociationSlotCandidates; enableSearch: boolean },
    private readonly modalRef: ModalRef<{ id: unknown }, AssociationSlotCandidate>,
    private readonly modalService: ModalService,
  ) {}

  selectItem(item: { id: unknown }): void {
    this.modalRef.close(item);
  }

  async createSupplier(e: Event): Promise<void> {
    e.preventDefault();

    const { SupplierCreationModalComponent } = await import('@dougs/suppliers/ui');

    const supplierCreated: Supplier | null | undefined = (
      await lastValueFrom(this.modalService.open<Supplier>(SupplierCreationModalComponent).afterClosed$)
    ).data;

    if (supplierCreated) {
      this.modalRef.close({ id: supplierCreated.id });
    }
  }
}
