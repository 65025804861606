import { Injectable } from '@angular/core';
import { AccountingLine } from '@dougs/operations/dto';

@Injectable()
export class OperationDetailsAccountingLinesComponentService {
  getLineCredit(line: AccountingLine): number {
    return line.isCredit ? line.amount / 100 : 0;
  }

  getLineDebit(line: AccountingLine): number {
    return line.isCredit ? 0 : line.amount / 100;
  }
}
