import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { AvatarComponent } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';

@Component({
  selector: 'dougs-operation-message',
  templateUrl: './operation-message.component.html',
  styleUrls: ['./operation-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, AvatarComponent],
})
export class OperationMessageComponent {
  @Input() operation!: Operation;

  @Input()
  @HostBinding('class.compact')
  isCompact = false;
}
