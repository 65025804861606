import { Location } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { SourceDocumentAttachment } from '@dougs/core/files';
import { RoutingService, URL } from '@dougs/core/routing';
import { RecursivePartial } from '@dougs/core/utils';
import { AssociationSlot, Breakdown, Category, Operation, OperationSection } from '@dougs/operations/dto';
import { AbstractOperationsStateService, OPERATION_STATE_TOKEN, OperationService } from '@dougs/operations/shared';
import { SynchronizedAccount } from '@dougs/synchronized-accounts/dto';
import { OperationMetricsService } from './operation-metrics.service';
import { OperationQueueService } from './operation-queue.service';

@Injectable()
export class OperationComponentService {
  isInOperationListModal = false;

  public get operation(): Operation {
    return this.operationQueueService.model;
  }

  shouldDisplayOperation$: Observable<boolean> = this.operationQueueService.shouldShowOperation$.pipe(
    map((showOperation: boolean) => this.isInOperationListModal || showOperation),
  );

  constructor(
    @Inject(OPERATION_STATE_TOKEN) private readonly abstractOperationsStateService: AbstractOperationsStateService<any>,
    private readonly operationQueueService: OperationQueueService,
    private readonly operationMetricsService: OperationMetricsService,
    private readonly location: Location,
    private readonly operationService: OperationService,
    private readonly routingService: RoutingService,
  ) {}

  setCurrentOperation(operation: Operation): void {
    this.operationQueueService.setCurrentModel(operation);
  }

  hideOperation(): void {
    this.operationQueueService.hideOperation();
  }

  showOperation(): void {
    this.operationQueueService.showOperation();
  }

  public updateOperation(
    partialOperation: RecursivePartial<Operation>,
    validate?: boolean,
    propagateValidation?: boolean,
    breakdown?: Breakdown,
    autoCategorize?: boolean,
    useOptimistic?: boolean,
    autoAssociate?: boolean,
  ): void {
    this.operationQueueService.updateModel(partialOperation, {
      validate,
      propagateValidation,
      breakdown,
      autoCategorize,
      useOptimistic,
      autoAssociate,
    });
  }

  public createClassFromAccountId(operation: Operation, synchronizedAccounts: SynchronizedAccount[]): string {
    if (operation && synchronizedAccounts) {
      const synchronizedAccount: SynchronizedAccount | undefined = synchronizedAccounts.find(
        (synchroAccount) => synchroAccount.id === operation.transaction?.accountId,
      );

      return synchronizedAccount ? `m-${synchronizedAccount.color}` : '';
    }

    return '';
  }

  public updateMemo(memo: string): void {
    this.updateOperation({
      memo,
    });
  }

  public updateCategory(breakdown: Breakdown | null, category: Category): void {
    if (breakdown) {
      this.updateOperation(
        {
          breakdowns: this.operation.breakdowns.map((breakdownIterated) =>
            breakdownIterated.id === breakdown.id
              ? {
                  ...breakdownIterated,
                  categoryId: category.id,
                  categoryWording: category.wording,
                }
              : breakdownIterated,
          ) as RecursivePartial<Breakdown>[],
        },
        false,
        false,
        {
          ...breakdown,
          categoryId: category.id,
          categoryWording: category.wording,
        },
        true,
        true,
      );
    }
  }

  public updateAmount(breakdown: Breakdown | null, signedAmount: number, section: OperationSection): void {
    if (breakdown) {
      const isInbound: boolean = this.operationService.isInbound(this.operation, signedAmount, section);
      const amount: number = Math.abs(signedAmount);

      this.updateOperation(
        {
          breakdowns: this.operation.breakdowns.map((breakdownIterated) =>
            breakdownIterated.id === breakdown.id
              ? {
                  ...breakdownIterated,
                  amount,
                  isInbound,
                }
              : breakdownIterated,
          ) as RecursivePartial<Breakdown>[],
        },
        false,
        false,
        {
          ...breakdown,
          amount,
          isInbound,
        },
      );
    }
  }

  public updateAssociation(breakdown: Breakdown | null, slot: AssociationSlot, value: unknown): void {
    if (breakdown) {
      this.updateOperation(
        {
          breakdowns: this.operation.breakdowns.map((breakdownIterated) =>
            breakdownIterated.id === breakdown.id
              ? {
                  ...breakdownIterated,
                  associationData: {
                    ...breakdownIterated.associationData,
                    [slot.key]: value,
                  },
                }
              : breakdownIterated,
          ) as RecursivePartial<Breakdown>[],
        },
        false,
        false,
        {
          ...breakdown,
          associationData: {
            ...breakdown.associationData,
            [slot.key]: value,
          },
        },
        false,
        undefined,
        true,
      );
    }
  }

  public updateVat(breakdown: Breakdown | null, vatAmount: number): void {
    if (breakdown) {
      this.updateOperation({
        breakdowns: this.operation.breakdowns.map((breakdownIterated) =>
          breakdownIterated.id === breakdown.id
            ? {
                ...breakdownIterated,
                vatAmount,
                manualVatAmount: vatAmount,
              }
            : breakdownIterated,
        ) as RecursivePartial<Breakdown>[],
      });
    }
  }

  public updateMessage(message: string): void {
    this.updateOperation(
      {
        message,
        needsAttention: !(message === ''),
      },
      false,
      true,
    );
  }

  public removeBreakdown(breakdown: Breakdown): void {
    this.operationMetricsService.trackBreakdownRemoving();
    this.updateOperation(
      {
        breakdowns: this.operation.breakdowns.filter(
          (breakdownIterated) => breakdownIterated.id !== breakdown.id,
        ) as RecursivePartial<Breakdown>[],
      },
      false,
      false,
      breakdown,
      false,
      true,
    );
  }

  public addBreakdown(breakdown: Breakdown): void {
    this.updateOperation(
      {
        breakdowns: [...this.operation.breakdowns, breakdown] as RecursivePartial<Breakdown>[],
      },
      false,
      false,
      breakdown,
      false,
      true,
    );
  }

  async detachSourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment): Promise<boolean> {
    if (this.operation) {
      const initialOperation: Operation = { ...(this.operation as Operation) };
      const updatedOperation: Operation = {
        ...initialOperation,
        sourceDocumentAttachments:
          initialOperation.sourceDocumentAttachments?.filter(
            (sourceDocumentAttachmentIterated) => sourceDocumentAttachmentIterated.id !== sourceDocumentAttachment.id,
          ) ?? [],
      };
      this.setCurrentOperation(updatedOperation);
      const hasBeenDetached = !!(await this.abstractOperationsStateService.detachSourceDocument(
        updatedOperation,
        sourceDocumentAttachment.id,
      ));
      if (!hasBeenDetached) {
        this.setCurrentOperation(initialOperation);
      }
      return hasBeenDetached;
    }
    return false;
  }

  public async uploadFiles(files: FileList): Promise<void> {
    await this.abstractOperationsStateService.uploadSourceDocuments(this.operation, Array.from(files));
  }

  public handleUrlChange(showOperationDetails: boolean): void {
    const url: string = this.routingService
      .createUrl(
        showOperationDetails ? [URL.ACCOUNTING, URL.OPERATIONS, this.operation.id.toString()] : [URL.ACCOUNTING],
      )
      .toString();
    this.location.go(url);
  }
}
