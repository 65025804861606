<div
  (fileDropped)="onFileDropped($event)"
  [ngClass]="{ 'is-deleted': operation.deleted }"
  class="operation-container"
  [@slideInOut]
  *ngIf="operationComponentService.shouldDisplayOperation$ | async"
  dougsDragNDrop
>
  <div class="operation-container__upload-files"></div>
  <div
    (click)="canToggleOperationDetails && onOpenOperationDetails()"
    class="operation-detail py-16"
    [ngClass]="{ 'px-24': !isCompact, 'px-16 compact': isCompact, 'details-open': showOperationDetails }"
  >
    <div class="operation-detail__upload-files-text">
      <p class="small">
        <i class="fal fa-cloud-upload mb-8"></i>
        Pour attacher un fichier, déposez le ici
      </p>
    </div>
    <div class="operation-detail__header" [ngClass]="{ compact: isCompact }">
      <div class="operation-detail__header__left">
        <div *ngIf="operation.creator" class="operation-detail__header__creator">
          <dougs-avatar
            [dougsTooltip]="
              'Opération créée par ' +
              operation.creator.profile.fullName +
              ', le ' +
              (operation.createdAt | date: 'dd/MM/yyyy')
            "
            [image]="
              operation.creator.profile.avatarUrl
                ? operation.creator.profile.avatarUrl
                : operation.creator.profile.alternateAvatarUrl
            "
            size="tiny"
          ></dougs-avatar>
        </div>
        <dougs-operation-date
          [operation]="operation"
          class="operation-detail__header__date-container"
        ></dougs-operation-date>
        <div [ngClass]="classColorBorderOperation" class="operation-detail__header__divider"></div>
        <h5
          [ngClass]="{ 'line-break-anywhere': operation.wording | lengthWord, compact: isCompact, 'mr-24': !isCompact }"
          class="operation-detail__header__title pl-8"
        >
          <span
            [ngClass]="{ compact: isCompact }"
            [dougsTooltip]="operation.wording"
            [dougsTooltipDisable]="!isCompact"
          >
            <i *ngIf="operation.metadata?.isTmpInvoice" class="fa-light fa-triangle-exclamation"></i>
            {{ operation.wording }}
          </span>
          <span *ngIf="additionalWording !== ''" class="mt-4 operation-detail__header__title__additional-wording">
            {{ additionalWording }}
          </span>
          <dougs-memo
            (click)="$event.stopPropagation()"
            (ngModelChange)="operationComponentService.updateMemo($event)"
            [light]="true"
            [ngModel]="operation.memo"
            class="mt-4 operation-detail__header__title__memo"
          ></dougs-memo>
          <dougs-operation-pills [operation]="operation"></dougs-operation-pills>
        </h5>
      </div>
      <ng-container *ngIf="isCompact">
        <dougs-operation-amount [isCompact]="isCompact" [operation]="operation"></dougs-operation-amount>
        <dougs-category
          (click)="$event.stopPropagation(); operationMetricsService.trackCategoryModalOpening()"
          [hasAlreadySendHelp]="
            operation?.metadata?.categorizationHelpStatus === OperationCategorizationHelpStatus.PENDING
          "
          (ngModelChange)="onCategoryChange($event)"
          [(ngModel)]="selectedCategory"
          [breakdownId]="mainBreakdown?.id"
          [breakdownIsInbound]="mainBreakdown?.isInbound"
          categoryType="operation"
          [disabled]="disabledCategory"
          [operationId]="mainBreakdown?.operationId"
          [toFilled]="selectedCategory?.id === -1"
          [wrap]="true"
          dougsFormFieldControl
        ></dougs-category>
      </ng-container>
      <div
        *ngIf="!isCompact"
        [ngClass]="{ 'is-in-modal': isInOperationListModal }"
        class="operation-detail__header__right"
      >
        <dougs-category
          (click)="$event.stopPropagation(); operationMetricsService.trackCategoryModalOpening()"
          (ngModelChange)="onCategoryChange($event)"
          [hasAlreadySendHelp]="
            operation?.metadata?.categorizationHelpStatus === OperationCategorizationHelpStatus.PENDING
          "
          [(ngModel)]="selectedCategory"
          [breakdownId]="mainBreakdown?.id"
          [breakdownIsInbound]="mainBreakdown?.isInbound"
          categoryType="operation"
          [disabled]="disabledCategory"
          [operationId]="mainBreakdown?.operationId"
          [toFilled]="selectedCategory?.id === -1"
          [disableCategoryHelp]="disableCategoryHelp"
          [wrap]="true"
          class="operation-detail__category"
          dougsFormFieldControl
        ></dougs-category>
        <dougs-operation-amount
          [operation]="operation"
          class="operation-detail__header__right__amount"
        ></dougs-operation-amount>
        <dougs-operation-actions
          *ngIf="!actionsTemplate"
          [operation]="operation"
          [isInOperationListModal]="isInOperationListModal"
          [mixpanelModalName]="mixpanelModalName"
          (emitOperationAction)="emitOperationAction.emit($event)"
        ></dougs-operation-actions>
        <ng-container *ngIf="actionsTemplate">
          <ng-container [ngTemplateOutlet]="actionsTemplate"></ng-container>
        </ng-container>
      </div>
    </div>
    <dougs-operation-associations
      [isOperationOpen]="showOperationDetails"
      [operation]="operation"
      [isInOperationListModal]="isInOperationListModal"
      [mixpanelModalName]="mixpanelModalName"
    ></dougs-operation-associations>
    <dougs-operation-receipt-matching
      *ngIf="!disableReceiptMatching && operation.sourceDocumentCandidate && !hasInvoiceSourceDocumentAttachments"
      [sourceDocumentMatch]="operation.sourceDocumentCandidate"
    ></dougs-operation-receipt-matching>
    <div
      *ngIf="!showOperationDetails"
      class="file-list"
      [ngClass]="{
        'mt-8': operation?.sourceDocumentAttachments?.length
      }"
    >
      @for (sourceDocumentAttachment of operation?.sourceDocumentAttachments?.slice(0, 3) ?? []; track 'id') {
        <dougs-source-document-pill
          (click)="$event.stopPropagation()"
          [sourceDocumentAttachment]="sourceDocumentAttachment"
          [enableAnimation]="enableFileAnimation"
          [canDelete]="!operation.validated"
          [disableRouting]="disableReceiptMatching"
          (deleteSourceDocumentAttachment)="onDetachSourceDocumentAttachment($event)"
        />
      }
      <dougs-pill *ngIf="operation?.sourceDocumentAttachments?.length > 3">
        <i class="mr-4 fal fa-paperclip"></i>
        +{{ operation.sourceDocumentAttachments.length - 3 }}
      </dougs-pill>
    </div>
    <dougs-operation-message
      *ngIf="operation.needsAttention"
      [operation]="operation"
      [isCompact]="isCompact"
    ></dougs-operation-message>
    <ng-container *ngIf="isCompact">
      <dougs-operation-actions
        *ngIf="!actionsTemplate"
        [operation]="operation"
        [isInOperationListModal]="isInOperationListModal"
        [mixpanelModalName]="mixpanelModalName"
        (emitOperationAction)="emitOperationAction.emit($event)"
      ></dougs-operation-actions>
      <ng-container *ngIf="actionsTemplate">
        <ng-container [ngTemplateOutlet]="actionsTemplate"></ng-container>
      </ng-container>
    </ng-container>
  </div>
  <dougs-operation-details
    *ngIf="showOperationDetails"
    [@slideInOutOperationDetail]
    [operation]="operation"
    [isCompact]="isCompact"
    [enableFileAnimation]="enableFileAnimation"
    [isInOperationListModal]="isInOperationListModal"
    [mixpanelModalName]="mixpanelModalName"
    [disableReceiptMatching]="disableReceiptMatching"
    [disableCall]="disableRefreshById"
    (emitOperationAction)="emitOperationAction.emit($event)"
  ></dougs-operation-details>
</div>
