<ng-container *ngIf="operationDetailsActionsComponentService.noteFormControlValueChanges$ | async"></ng-container>
<ng-container *ngIf="operationDetailsActionsComponentService.dateFormControlValueChanges$ | async"></ng-container>
<ng-container *ngIf="operationDetailsActionsComponentService.journalFormControlValueChanges$ | async"></ng-container>
<ng-container *ngIf="operationDetailsActionsComponentService.reversableFormControlValueChanges$ | async"></ng-container>
<ng-container *ngIf="operationDetailsActionsComponentService.excludedFormControlValueChanges$ | async"></ng-container>
<ng-container
  *ngIf="operationDetailsActionsComponentService.manuallyLockedFormControlValueChanges$ | async"
></ng-container>
<ng-container
  *ngIf="operationDetailsActionsComponentService.accountingReportFormControlValueChanges$ | async"
></ng-container>
<ng-container
  *ngIf="operationDetailsActionsComponentService.detachDeletedFormControlValueChanges$ | async"
></ng-container>
<div
  *ngIf="operationDetailsActionsComponentService.operation$ | async as operation"
  class="operation-detail-actions mt-16"
  [ngClass]="{ 'px-48': !isCompact }"
>
  <textarea
    [ngModel]="operation.message"
    [ngModelOptions]="{ updateOn: 'blur' }"
    (ngModelChange)="operationComponentService.updateMessage($event)"
    dougsFormFieldControl
    class="operation-detail-actions__message"
    placeholder="Ecrivez un message pour attirer l'attention du client sur cette opération"
  ></textarea>
  <span
    class="operation-detail-actions__message__button"
    (click)="operationDetailsActionsComponentService.setDefaultMessage()"
  >
    Préremplir avec une demande de justificatif
  </span>
  <textarea
    [formControl]="operationDetailsActionsComponentService.noteFormControl"
    dougsFormFieldControl
    class="operation-detail-actions__note mt-16"
    placeholder="Écrivez une note non visible par le client..."
  ></textarea>
  <dougs-input-datepicker
    [autoWidth]="true"
    class="mt-8"
    *ngIf="(operationDetailsActionsComponentService.showDatepicker$ | async) && shouldShowDate"
    label="Date de l'opération"
    [formControl]="operationDetailsActionsComponentService.dateFormControl"
  ></dougs-input-datepicker>
  <dougs-form-field
    class="operation-detail-actions__journal mt-8"
    autoWidth="true"
    noMargin="true"
    *ngIf="
      operation.metadata?.allowedJournals && !operationDetailsActionsComponentService.accountingReportFormControl?.value
    "
  >
    <label dougsFormFieldLabel>Choix du journal</label>
    <dougs-select
      class="select"
      [formControl]="operationDetailsActionsComponentService.journalFormControl"
      dougsFormFieldControl
    >
      <dougs-select-option
        *ngFor="let journal of operation.metadata.allowedJournals; trackBy: trackByIndex"
        [value]="journal"
      >
        {{ journal }}
      </dougs-select-option>
    </dougs-select>
  </dougs-form-field>
  <div
    class="operation-detail-actions__accounting-report mt-8"
    *ngIf="operationDetailsActionsComponentService.shouldShowAccountingReportFormControl$ | async"
  >
    <dougs-checkbox
      appearance="toggle"
      [formControl]="operationDetailsActionsComponentService.accountingReportFormControl"
      label="Il s'agit d'un <b>à-nouveau</b> (reprise de balances) ?"
    ></dougs-checkbox>
    <dougs-panel-info
      class="info-panel"
      *ngIf="operationDetailsActionsComponentService.accountingReportFormControl.value"
    >
      Attention, pensez à solder les comptes d'attente (notamment de TVA) le cas échéant. Ces comptes doivent absolument
      être soldés en même temps que la reprise des balances, pour éviter des erreurs dans le calcul des futures
      déclarations de TVA.
    </dougs-panel-info>
  </div>
  <div class="operation-detail-actions__revserse mt-8" *ngIf="operation.type === 'miscellaneous:manual'">
    <dougs-checkbox
      appearance="toggle"
      [tinyLabel]="isCompact"
      [formControl]="operationDetailsActionsComponentService.reversableFormControl"
      label="Extourner l'opération ?"
    ></dougs-checkbox>
  </div>
  <div class="operation-detail-actions__excluded mt-8">
    <dougs-checkbox
      appearance="toggle"
      [tinyLabel]="isCompact"
      [formControl]="operationDetailsActionsComponentService.excludedFormControl"
      label="Exclure cette opération de la compta ?"
    ></dougs-checkbox>
  </div>
  <div class="operation-detail-actions__locked mt-8">
    <dougs-checkbox
      appearance="toggle"
      [tinyLabel]="isCompact"
      [formControl]="operationDetailsActionsComponentService.manuallyLockedFormControl"
      label="Verrouiller cette opération ?"
    ></dougs-checkbox>
  </div>
  <div class="operation-detail-actions__force mt-8">
    <dougs-checkbox
      appearance="toggle"
      [tinyLabel]="isCompact"
      [formControl]="operationDetailsActionsComponentService.detachDeletedFormControl"
      label="Forcer cette opération ?"
    ></dougs-checkbox>
  </div>

  <div class="operation-detail-actions__admin-infos mt-8" *ngIf="operation?.transaction">
    <span>
      accountId: {{ operation.transaction.accountId }}, providerId: {{ operation.transaction.providerId }} , externalId:
      {{ operation.transaction.externalId }}
    </span>
  </div>
  <div
    class="operation-detail-actions__changes-history"
    (click)="operationDetailsActionsComponentService.onShowChangesHistoryModal(operation)"
  >
    <span class="operation-detail-actions__changes-history__label mr-4">Voir l'historique des modifications</span>
    <i class="fal fa-window-restore"></i>
  </div>
</div>
