<div class="table mt-16" [ngClass]="{ 'px-48': !isCompact, compact: isCompact }">
  <table>
    <thead>
      <tr>
        <th class="journal-column">Jnl</th>
        <th class="date-column">Date</th>
        <th class="account-column">Compte</th>
        <th class="debit-column">Débit</th>
        <th class="credit-column">Crédit</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let line of accountingLines; trackBy: trackByLine">
        <td>{{ line.journal }}</td>
        <td>{{ line.date | date: 'dd/MM/yyyy' }}</td>
        <td>{{ line.accountNumber }}</td>
        <td>{{ operationDetailsAccountingLinesComponentService.getLineDebit(line) | numberToString: true }}</td>
        <td>{{ operationDetailsAccountingLinesComponentService.getLineCredit(line) | numberToString: true }}</td>
      </tr>
    </tbody>
  </table>
</div>
