import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DougsDatePipe, PillComponent } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { UserStateService } from '@dougs/user/shared';
import { OperationPillsComponentService } from '../../../../services/operation-pills.component.service';

@Component({
  selector: 'dougs-operation-pills',
  templateUrl: './operation-pills.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [PillComponent, CommonModule, DougsDatePipe],
  providers: [OperationPillsComponentService],
})
export class OperationPillsComponent {
  @Input()
  set operation(operation: Operation) {
    this.operationPillsComponentService.operation = operation;
  }

  constructor(
    public readonly userStateService: UserStateService,
    public readonly operationPillsComponentService: OperationPillsComponentService,
  ) {}
}
