import { ElementRef, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import {
  Association,
  AssociationSlot,
  AssociationSlotCandidate,
  Breakdown,
  MessagePart,
  Operation,
} from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
import { HandleSlotActionComponentService } from './handle-slot-action.component.service';
import { OperationMetricsService } from './operation-metrics.service';
import { OperationComponentService } from './operation.component.service';

@Injectable()
export class OperationBreakdownAssociationComponentService {
  constructor(
    private readonly handleSlotActionComponentService: HandleSlotActionComponentService,
    public readonly operationComponentService: OperationComponentService,
    private readonly operationMetricsService: OperationMetricsService,
    private readonly operationService: OperationService,
  ) {}

  async handleSlotAction(
    operation: Operation,
    breakdown: Breakdown,
    association: Association,
    slot: AssociationSlot,
    isInOperationListModal: boolean,
    mixpanelModalName?: string,
  ): Promise<void> {
    if (!slot.isAssignable) {
      return;
    }
    const mainBreakdown: Breakdown | null = this.operationService.getMainBreakdown(operation);
    const oldValue: AssociationSlotCandidate | undefined = slot.selectedItem;
    const value: unknown | null | undefined = await lastValueFrom(
      this.handleSlotActionComponentService.handleSlotAction(operation, breakdown, association, slot),
    );
    this.operationComponentService.updateAssociation(breakdown, slot, value);

    this.operationMetricsService.trackOperationAssociation(
      this.operationService.getSelectedCategory(mainBreakdown).wording,
      association.name,
      this.getValueChangeType(oldValue?.value, value),
      isInOperationListModal,
      mixpanelModalName,
    );
  }

  private getValueChangeType(
    oldValue: unknown | undefined,
    newValue: unknown | undefined,
  ): 'new' | 'updated' | 'deleted' | 'unchanged' {
    if (oldValue === newValue) {
      return 'unchanged';
    } else if (!oldValue) {
      return 'new';
    } else if (!newValue) {
      return 'deleted';
    }
    return 'updated';
  }

  handleIntegerChange(content: ElementRef, breakdown: Breakdown, slot: AssociationSlot): void {
    if (content && content.nativeElement.innerText !== '') {
      this.operationComponentService.updateAssociation(breakdown, slot, parseFloat(content.nativeElement.innerText));
    }
  }

  handleBooleanChange(breakdown: Breakdown, slot: AssociationSlot, value: boolean): void {
    this.operationComponentService.updateAssociation(breakdown, slot, value);
  }

  public getSlotType(associationSlot: AssociationSlot): 'record' | 'enum' | 'boolean' | 'integer' {
    return associationSlot?.type;
  }

  public getIconInlineUrl(association: Association, part: MessagePart): string {
    if (part.slotName) {
      return association.slots[part.slotName].selectedItem.inlineIconUrl;
    }
    return '';
  }

  public getInlineLabel(association: Association, part: MessagePart): string {
    if (part.slotName) {
      return association.slots[part.slotName].selectedItem.inlineLabel;
    }

    return '';
  }

  public isAssignable(association: Association, part: MessagePart): boolean {
    return part.slotName ? association.slots[part.slotName].isAssignable : false;
  }

  public shouldShowAvatar(association: Association, part: MessagePart): boolean {
    return !!(
      part.slotName &&
      this.getIconInlineUrl(association, part) &&
      this.getIconInlineUrl(association, part) !== '' &&
      this.getSlotType(association.slots[part.slotName]) === 'record'
    );
  }

  public shouldShowSelector(association: Association, part: MessagePart): boolean {
    return !!(
      part.slotName &&
      part.type === 'slot' &&
      this.isAssignable(association, part) &&
      this.getSlotType(association.slots[part.slotName]) !== 'boolean' &&
      this.getSlotType(association.slots[part.slotName]) !== 'integer'
    );
  }

  public isBoolean(association: Association, part: MessagePart): boolean {
    return !!(
      part.slotName &&
      part.type === 'slot' &&
      this.getSlotType(association.slots[part.slotName]) === 'boolean'
    );
  }

  public isInteger(association: Association, part: MessagePart): boolean {
    return !!(
      part.slotName &&
      part.type === 'slot' &&
      this.getSlotType(association.slots[part.slotName]) === 'integer'
    );
  }

  public isRecordOrEnum(association: Association, part: MessagePart): boolean {
    return !!(
      part.slotName &&
      part.type === 'slot' &&
      (this.getSlotType(association.slots[part.slotName]) === 'record' ||
        this.getSlotType(association.slots[part.slotName]) === 'enum')
    );
  }
}
