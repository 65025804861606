import { computed, Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { concatMap, lastValueFrom, Observable } from 'rxjs';
import { first, map, take } from 'rxjs/operators';
import { CompanyDemoStateService, CompanyStateService } from '@dougs/company/shared';
import { SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import { MetricsEvent, MetricsService } from '@dougs/core/metrics';
import { ConfirmationModalComponent, MODAL_DATA, ModalRef, ModalService, OverlayCloseEvent } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { NotValidatedOperationsStateService, SourceDocumentAttachmentUtils } from '@dougs/operations/shared';
import { Partner } from '@dougs/partners/dto';
import { PartnerStateService } from '@dougs/partners/shared';
import { VendorInvoice } from '@dougs/vendor-invoice/dto';
import { VendorInvoiceStateService } from '@dougs/vendor-invoice/shared';
import { createdOperationDateValidatorForCompany } from '../validators/operation.validator';

@Injectable()
export class ExpenseOperationModalComponentService {
  constructor(
    @Inject(MODAL_DATA)
    public readonly data: {
      operation: Operation;
    },
    private readonly companyStateService: CompanyStateService,
    private readonly modalRef: ModalRef,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    private readonly partnerStateService: PartnerStateService,
    private readonly vendorInvoiceStateService: VendorInvoiceStateService,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
    private readonly companyDemoStateService: CompanyDemoStateService,
  ) {
    if (data?.operation) {
      this.date.setValue(data.operation.date, { emitEvent: false });
      this.amount.setValue(data.operation.amount, { emitEvent: false });
    }

    if (!data?.operation) {
      this.formGroup.addControl('category', new FormControl('', [Validators.required]));
      this.formGroup.addControl('partner', new FormControl('', [Validators.required]));
      this.formGroup.addControl('memo', new FormControl(null));
    }
  }

  refreshPartners$: Observable<void> = this.companyStateService.activeCompany$.pipe(
    concatMap((activeCompany) => this.partnerStateService.refreshPartners(activeCompany.id)),
  );

  populateDefaultPartner$: Observable<void> = this.partnerStateService.partnersAssociateCeoDirector$.pipe(
    map((partners: Partner[]) => {
      if (partners.length === 1) {
        this.partner?.setValue(partners[0].id);
      }
    }),
  );

  activeAssociateCeoDirectorPartners$: Observable<Partner[]> =
    this.partnerStateService.partnersAssociateCeoDirector$.pipe(
      map(
        (partners: Partner[]) =>
          partners?.filter((partner) =>
            partner.positions?.some((position) => position.isActive && !position.isEnded),
          ) ?? [],
      ),
    );

  formGroup: FormGroup = new FormGroup({
    date: new FormControl(new Date(), [
      Validators.required,
      createdOperationDateValidatorForCompany(this.companyStateService.activeCompany, 'expense'),
    ]),
    amount: new FormControl('', [Validators.required]),
  });

  filesMap: Map<string, File> = new Map<string, File>();

  private readonly vendorInvoices: WritableSignal<VendorInvoice[]> = signal<VendorInvoice[]>([]);
  public vendorInvoices$: Signal<VendorInvoice[]> = computed(() => this.vendorInvoices());
  vendorInvoiceSourceDocumentAttachment$: Signal<SourceDocumentAttachment[]> = computed(() =>
    this.vendorInvoices$().map((vendorInvoice) =>
      SourceDocumentAttachmentUtils.createSourceDocumentAttachmentFromVendorInvoice(vendorInvoice),
    ),
  );

  private readonly tmpSourceDocumentAttachments: WritableSignal<SourceDocumentAttachment[]> = signal<
    SourceDocumentAttachment[]
  >([]);
  tmpSourceDocumentAttachments$: Signal<SourceDocumentAttachment[]> = this.tmpSourceDocumentAttachments.asReadonly();

  allSourceDocumentAttachments$: Signal<SourceDocumentAttachment[]> = computed(() => [
    ...this.vendorInvoiceSourceDocumentAttachment$(),
    ...this.tmpSourceDocumentAttachments$(),
  ]);

  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  get date(): AbstractControl {
    return this.formGroup.get('date') as FormControl;
  }

  get amount(): AbstractControl {
    return this.formGroup.get('amount') as FormControl;
  }

  get category(): AbstractControl {
    return this.formGroup.get('category') as FormControl;
  }

  get partner(): AbstractControl {
    return this.formGroup.get('partner') as FormControl;
  }

  get memo(): AbstractControl {
    return this.formGroup.get('memo') as FormControl;
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.invalid) {
      this.isLoading.set(true);
      let result: boolean;

      if (this.data?.operation) {
        result = await this.updateExpenseOperation();
      } else {
        result = await this.saveExpenseOperation();
      }

      if (result) {
        if (await lastValueFrom(this.companyDemoStateService.hasDemoMode$.pipe(first()))) {
          const ctaEvent: MetricsEvent = {
            action: `CTA Confirmed`,
            category: `Accounting Demo Espèces`,
          };
          this.metricsService.pushGAEvent(ctaEvent);
          this.metricsService.pushMixpanelEvent(ctaEvent);
        }

        this.modalRef.close();
      }
      this.isLoading.set(false);
    }
  }

  private async saveExpenseOperation(): Promise<boolean> {
    const { date, category, memo, amount, partner } = this.formGroup.value;
    const operationCreated: Operation | null = await this.notValidatedOperationsStateService.createOperation(
      this.companyStateService.activeCompany.id,
      {
        type: 'expense',
        date,
        memo,
        amount,
        attachments: [],
        breakdowns: [
          {
            amount,
            categoryId: category.id,
          },
          {
            amount: 0,
            categoryId: -1,
            isCounterpart: true,
            associationData: {
              partnerId: partner,
            },
          },
        ],
      },
      Array.from(this.filesMap.values()),
    );

    if (operationCreated && this.vendorInvoices()?.length) {
      await Promise.all(
        this.vendorInvoices().map((vendorInvoice) =>
          this.vendorInvoiceStateService.attachOperation(operationCreated, vendorInvoice),
        ),
      );
      await this.notValidatedOperationsStateService.refreshOperationById(
        operationCreated.companyId,
        operationCreated.id,
      );
    }

    return !!operationCreated;
  }

  private async updateExpenseOperation(): Promise<boolean> {
    const { date, amount } = this.formGroup.value;
    const updatedOperation: Operation = {
      ...this.data.operation,
      date,
      amount,
    } as Operation;
    return !!(await this.notValidatedOperationsStateService.updateOperation(updatedOperation));
  }

  removeExpenseOperation(): void {
    this.modalService
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Supprimer la note de frais',
          body: `Êtes-vous sûr de vouloir supprimer cette note de frais ? Cette action est définitive.`,
          noText: 'Annuler',
          yesText: 'Oui, supprimer la note de frais',
        },
      })
      .afterClosed$.pipe(take(1))
      .subscribe(async (res: OverlayCloseEvent<unknown>) => {
        if (res.data && this.data?.operation) {
          const success = await this.notValidatedOperationsStateService.removeOperation(this.data.operation);
          if (success) {
            this.modalRef.close();
          }
        }
      });
  }

  onUploadFiles(files: FileList): void {
    Array.from(files).forEach((file: File) => {
      const tmpSda: SourceDocumentAttachment =
        SourceDocumentAttachmentUtils.createSourceDocumentAttachmentFromFile(file);
      this.filesMap.set(tmpSda.sourceDocument.tempUuid as string, file);
      this.tmpSourceDocumentAttachments.update((tmpSdas) => [...tmpSdas, tmpSda]);
    });
  }

  deleteTmpSourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment): void {
    this.tmpSourceDocumentAttachments.update((tmpSda) =>
      tmpSda.filter((tmp) => tmp.sourceDocument?.tempUuid !== sourceDocumentAttachment.sourceDocument?.tempUuid),
    );
    this.filesMap.delete(sourceDocumentAttachment.sourceDocument.tempUuid as string);
  }

  async openVendorInvoiceModal(operation?: Operation): Promise<void> {
    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { AttachVendorInvoiceModalComponent } = await import('@dougs/vendor-invoice/ui');
    this.vendorInvoices.set(
      (
        await lastValueFrom(
          this.modalService.open<VendorInvoice[]>(AttachVendorInvoiceModalComponent, { data: operation }).afterClosed$,
        )
      ).data || [],
    );
  }

  onDetachTempVendorInvoice(vendorInvoiceId: string): void {
    this.vendorInvoices.update((currentVendorInvoices: VendorInvoice[]) =>
      currentVendorInvoices.filter((tempVendorInvoice: VendorInvoice) => tempVendorInvoice.id !== vendorInvoiceId),
    );
  }

  async closeModal(): Promise<void> {
    if (await lastValueFrom(this.companyDemoStateService.hasDemoMode$.pipe(first()))) {
      const ctaEvent: MetricsEvent = {
        action: `CTA Closed`,
        category: `Accounting Demo Espèces`,
      };
      this.metricsService.pushGAEvent(ctaEvent);
      this.metricsService.pushMixpanelEvent(ctaEvent);
    }
    this.modalRef.close();
  }

  onDeleteSourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment): void {
    if (
      sourceDocumentAttachment.sourceDocument?.type === SourceDocumentType.VENDOR_INVOICE &&
      sourceDocumentAttachment.sourceDocument?.externalId
    ) {
      this.onDetachTempVendorInvoice(sourceDocumentAttachment.sourceDocument.externalId);
    } else {
      this.deleteTmpSourceDocumentAttachment(sourceDocumentAttachment);
    }
  }
}
