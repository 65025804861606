import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, concatMap, map, Observable } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import { ModalService } from '@dougs/ds';
import { SalesChannel } from '@dougs/ecommerce/dto';
import { EcommerceSaleStateService, SalesChannelStateService } from '@dougs/ecommerce/shared';
import { Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { OperationMetricsService } from './operation-metrics.service';

@Injectable()
export class OperationEcommerceDispatchFilesComponentService {
  private _operation!: Operation;
  private _salesChannels!: SalesChannel[];
  private _amazonTaxReportFileAttachment: SourceDocumentAttachment | undefined;
  private readonly _isTaxReportProcessed: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _hasTaxReport: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _adminErrorMessage: BehaviorSubject<string | undefined> = new BehaviorSubject<string | undefined>(
    '',
  );
  private readonly _hasAmazonEu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _shouldShow: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  refreshSalesChannels$: Observable<void> = this.companyStateService.activeCompany$.pipe(
    concatMap((activeCompany) => this.salesChannelStateService.refreshSalesChannels(activeCompany.id)),
  );
  isTaxReportProcessed$: Observable<boolean> = this._isTaxReportProcessed.asObservable();
  hasTaxReport$: Observable<boolean> = this._hasTaxReport.asObservable();

  isOperationValidatedWithoutTR$: Observable<boolean> = this.hasTaxReport$.pipe(
    map((hasTaxReport: boolean) => {
      return !hasTaxReport && this._operation.validated;
    }),
  );

  adminErrorMessage$: Observable<string | undefined> = this._adminErrorMessage.asObservable();
  hasAmazonEu$: Observable<boolean> = this._hasAmazonEu.asObservable();
  shouldShow$: Observable<boolean> = this._shouldShow.asObservable();

  constructor(
    @Inject(OPERATION_STATE_TOKEN) public readonly refactoOperationsStateService: AbstractOperationsStateService<any>,
    private readonly modalService: ModalService,
    private readonly operationMetricsService: OperationMetricsService,
    private readonly companyStateService: CompanyStateService,
    private readonly salesChannelStateService: SalesChannelStateService,
    private readonly ecommerceStateService: EcommerceSaleStateService,
  ) {}

  get amazonTaxReportFileAttachment(): SourceDocumentAttachment | undefined {
    return this._amazonTaxReportFileAttachment;
  }

  get operation(): Operation {
    return this._operation;
  }

  set operation(operation: Operation) {
    this._operation = operation;
    this._amazonTaxReportFileAttachment = this.amazonTaxReportAttachment(operation);
    this._isTaxReportProcessed.next(!!this._amazonTaxReportFileAttachment?.isTaxReportProcessed);
    this._hasTaxReport.next(!!this._amazonTaxReportFileAttachment);
    this._adminErrorMessage.next(this.operation.metadata?.error?.messageCodeInstance?.userMessage);
    this.shouldShowDispatchFile();
  }

  get salesChannels(): SalesChannel[] {
    return this._salesChannels;
  }

  set salesChannels(salesChannels: SalesChannel[]) {
    this._salesChannels = salesChannels;
    this.shouldShowDispatchFile();
  }

  public shouldShowDispatchFiles(operation: Operation, salesChannel: SalesChannel[]): boolean {
    return !(
      operation.type === 'dispatch:ecommerce:salesChannel' && !this.hasOperationAmazonEuChannel(operation, salesChannel)
    );
  }

  public amazonTaxReportAttachment(operation: Operation): SourceDocumentAttachment | undefined {
    return operation.sourceDocumentAttachments?.find(
      (sda) => sda.sourceDocument?.type === SourceDocumentType.AMAZON_TAX_REPORT,
    );
  }

  public async onUploadFiles(operation: Operation, file: File): Promise<void> {
    this.operationMetricsService.trackOperationFileAttached(operation);
    await this.refactoOperationsStateService.uploadAmazonTaxReport(operation, file);
    await this.ecommerceStateService.refreshEcommerceSales(operation);
  }

  public async onDeleteFile(operation: Operation, sourceDocumentAttachment: SourceDocumentAttachment): Promise<void> {
    const sourceDocumentDetached = !!(await this.refactoOperationsStateService.detachSourceDocument(
      operation,
      sourceDocumentAttachment.id,
    ));
    if (sourceDocumentDetached) {
      await this.ecommerceStateService.refreshEcommerceSales(operation);
    }
  }

  public async openCreateSalesChannelModal(e: Event): Promise<void> {
    e.preventDefault();
    const { CreateSalesChannelModalComponent } = await import('@dougs/ecommerce/ui');
    this.modalService.open<number>(CreateSalesChannelModalComponent);
  }

  public hasOperationAmazonEuChannel(operation: Operation, salesChannels: SalesChannel[]): boolean {
    if (operation?.type === 'dispatch:ecommerce') {
      return !!salesChannels?.some((salesChannel) => salesChannel.type === 'amazoneu');
    } else if (operation?.type === 'dispatch:ecommerce:salesChannel') {
      return !!salesChannels?.some(
        (salesChannel) => salesChannel.type === 'amazoneu' && operation?.metadata?.salesChannelId === salesChannel.id,
      );
    }
    return false;
  }

  private shouldShowDispatchFile(): void {
    if (this.operation && this.salesChannels) {
      this._hasAmazonEu.next(this.hasOperationAmazonEuChannel(this.operation, this.salesChannels));
      this._shouldShow.next(this.shouldShowDispatchFiles(this.operation, this.salesChannels));
    }
  }
}
