import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { MODAL_DATA, ModalRef, ModalService } from '@dougs/ds';
import { Loan } from '@dougs/loans/dto';
import { AssociationSlotCandidate, AssociationSlotCandidates } from '@dougs/operations/dto';

@Injectable()
export class SelectCandidateLoanComponentService {
  constructor(
    @Inject(MODAL_DATA)
    public data: { candidates: AssociationSlotCandidates; enableSearch: boolean },
    private readonly modalRef: ModalRef<{ id: unknown }, AssociationSlotCandidate>,
    private readonly modalService: ModalService,
  ) {}

  selectItem(item: { id: unknown }): void {
    this.modalRef.close(item);
  }

  async createLoan(e: Event): Promise<void> {
    e.preventDefault();

    const { LoanCreationModalComponent } = await import('@dougs/loans/ui');

    const loanCreated: Loan | undefined | null = (
      await lastValueFrom(this.modalService.open<Loan>(LoanCreationModalComponent).afterClosed$)
    ).data;

    if (loanCreated) {
      this.modalRef.close({ id: loanCreated.id });
    }
  }
}
