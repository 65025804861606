<ng-container *ngIf="selectCandidateEstablishmentComponentService.refreshEstablishments$ | async"></ng-container>

<div dougsModalTitle>
  <h6>{{ selectCandidateEstablishmentComponentService.data.candidates.descriptor.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent class="modal-container">
  <p class="small" *ngIf="selectCandidateEstablishmentComponentService.data.candidates.items.length === 0">
    Vous n'avez pas de {{ selectCandidateEstablishmentComponentService.data.candidates.descriptor.description.long }}
  </p>
  <ul class="select-candidate">
    <li
      *ngFor="let item of selectCandidateEstablishmentComponentService.data.candidates.items"
      class="p-8 candidate"
      (click)="selectCandidateEstablishmentComponentService.selectItem(item)"
    >
      <div class="mr-16 icon-container">
        <i class="fal fa-building"></i>
      </div>
      <div>
        {{ item.label }}
        <p class="small" [innerHTML]="item.description"></p>
      </div>
    </li>
  </ul>
  <dougs-company-refresh-establishment
    [company]="companyStateService.activeCompany$ | async"
  ></dougs-company-refresh-establishment>
</div>
