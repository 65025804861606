<form class="form-investment" (ngSubmit)="onSubmit()" [formGroup]="formGroup" *ngIf="formGroup">
  <div dougsModalTitle>
    <h6>{{ operation ? "Editer une facture d'achat" : "Ajouter une facture d'achat" }}</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-panel-info *ngIf="date?.hasError('companyLockDate')" [type]="'error'" class="mb-16">
      <span>{{ date?.getError('companyLockDate') }}</span>
    </dougs-panel-info>
    <div class="form-field-line">
      <dougs-input-datepicker formControlName="date"></dougs-input-datepicker>
      <dougs-form-field>
        <label dougsFormFieldLabel for="amount">Montant</label>
        <input id="amount" type="number" formControlName="amount" min="0" step="0.01" dougsFormFieldControl />
        <i dougsFormFieldSuffix class="fas fa-euro-sign"></i>
        <span *ngIf="formService.isControlInvalid(amount)" dougsFormFieldError>
          <span *ngIf="amount.hasError('required')">Ce champ est requis</span>
        </span>
      </dougs-form-field>
    </div>
    <dougs-form-field *ngIf="!operation">
      <label dougsFormFieldLabel>Catégorie</label>
      <dougs-category formControlName="category" categoryType="expense" dougsFormFieldControl></dougs-category>
      <span *ngIf="formService.isControlInvalid(category)" dougsFormFieldError>
        <span *ngIf="category.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>
    <dougs-form-field *ngIf="!operation">
      <label dougsFormFieldLabel>Fournisseur</label>
      <dougs-select
        maxHeight="200"
        [searchable]="true"
        formControlName="vendor"
        placeholder="Sélectionner un fournisseur"
        dougsFormFieldControl
      >
        <dougs-select-option *ngFor="let vendor of vendors" [value]="vendor.id">
          {{ vendor.fullName }}
        </dougs-select-option>
      </dougs-select>
      <span *ngIf="formService.isControlInvalid(vendor)" dougsFormFieldError>
        <span *ngIf="vendor.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>
    <dougs-form-field *ngIf="!operation">
      <label dougsFormFieldLabel for="memo">Mémo</label>
      <input id="memo" type="text" formControlName="memo" dougsFormFieldControl />
    </dougs-form-field>
    <dougs-form-field *ngIf="!operation">
      <div class="files mt-8">
        <div class="file-list">
          <dougs-file-input
            (uploadFiles)="onUploadFiles($event)"
            [shouldAddVendorInvoice]="true"
            (attachFromVendorInvoice)="openVendorInvoiceModal(operation)"
          ></dougs-file-input>
          <dougs-source-document-pill
            *ngFor="let sourceDocumentAttachment of allSourceDocumentAttachments$(); trackBy: 'id' | trackBy"
            [sourceDocumentAttachment]="sourceDocumentAttachment"
            [canDelete]="true"
            (deleteSourceDocumentAttachment)="onDeleteSourceDocumentAttachment($event)"
          ></dougs-source-document-pill>
        </div>
      </div>
    </dougs-form-field>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button *ngIf="operation" color="delete" (click)="removeVendorInvoice()">
      Supprimer la facture d'achat
    </dougs-button>
    <dougs-button type="submit">
      {{ operation ? 'Modifier' : 'Ajouter' }}
    </dougs-button>
  </div>
</form>
<dougs-loader-fullpage *ngIf="isLoading$()">
  {{ operation ? 'Modification' : 'Création' }} en cours...
</dougs-loader-fullpage>
