import { Injectable, Type } from '@angular/core';
import { from, lastValueFrom, Observable, of } from 'rxjs';
import { map, switchMap, take } from 'rxjs/operators';
import { ModalService, OverlayCloseEvent, SelectModalComponent } from '@dougs/ds';
import {
  Association,
  AssociationSlot,
  AssociationSlotCandidate,
  AssociationSlotCandidates,
  Breakdown,
  Operation,
  SLOT_NAME,
} from '@dougs/operations/dto';
import { OperationAssociationStateService } from '@dougs/operations/shared';
import { RENTAL_CARS, VehicleStateService } from '@dougs/vehicles/shared';
import { SelectAssociationCandidateComponent } from '../modals/select-association-candidate/select-association-candidate.component';
import { SelectCandidateCarComponent } from '../modals/select-candidate-car/select-candidate-car.component';
import { SelectCandidateCustomerComponent } from '../modals/select-candidate-customer/select-candidate-customer.component';
import { SelectCandidateEstablishmentComponent } from '../modals/select-candidate-establishment/select-candidate-establishment.component';
import { SelectCandidateInvestmentComponent } from '../modals/select-candidate-investment/select-candidate-investment.component';
import { SelectCandidateLoanComponent } from '../modals/select-candidate-loan/select-candidate-loan.component';
import { SelectCandidatePartnerComponent } from '../modals/select-candidate-partner/select-candidate-partner.component';
import { SelectCandidatePaymentsProcessorComponent } from '../modals/select-candidate-payments-processor/select-candidate-payments-processor.component';
import { SelectCandidateSalesChannelComponent } from '../modals/select-candidate-sales-channel/select-candidate-sales-channel.component';
import { SelectCandidateSupplierComponent } from '../modals/select-candidate-supplier/select-candidate-supplier.component';

@Injectable()
export class HandleSlotActionComponentService {
  constructor(
    private readonly operationAssociationStateService: OperationAssociationStateService,
    private readonly vehicleStateService: VehicleStateService,
    private readonly modalService: ModalService,
  ) {}

  handleSlotAction(
    operation: Operation,
    breakdown: Breakdown,
    association: Association,
    slot: AssociationSlot,
  ): Observable<unknown | null | undefined> {
    if (slot.isAssignable) {
      if (
        slot.modelName === 'operation' &&
        (slot.name === SLOT_NAME.INVOICE ||
          slot.name === SLOT_NAME.INVOICE_SUPPLIER ||
          slot.name === SLOT_NAME.INVOICE_CUSTOMER ||
          slot.name === SLOT_NAME.INVOICE_CUSTOMER_CREDIT_NOTE_OR_OVER_PAYMENT ||
          slot.name === SLOT_NAME.INVOICE_SUPPLIER_CREDIT_NOTE_OR_OVER_PAYMENT)
      ) {
        /* As handleOperationAction will not use candidates, we skip the call for candidates*/
        return from(this.handleOperationAction(operation, breakdown, association.name, slot.name));
      }

      const candidates = this.operationAssociationStateService.getBreakdownAssociationCandidates(
        operation.companyId,
        operation.id,
        breakdown.id,
        association.name,
        slot.name,
      );

      return candidates.pipe(
        switchMap((candidates: AssociationSlotCandidates) =>
          this.switchHandleAction(candidates, operation, association, slot, breakdown),
        ),
      );
    }

    return of(null);
  }

  private switchHandleAction(
    candidates: AssociationSlotCandidates,
    operation: Operation,
    association: Association,
    slot: AssociationSlot,
    breakdown: Breakdown,
  ): Observable<unknown> {
    if (slot.type === 'enum') {
      return this.handleAction(candidates);
    }

    if (slot.type === 'record') {
      switch (slot.modelName) {
        case 'customer':
          return this.handleCustomerAction(candidates);
        case 'supplier':
          return this.handleSupplierAction(candidates);
        case 'synchronizedAccount':
        case 'declaration':
          return this.handleAction(candidates);
        case 'partner':
          return this.handlePartnerAction(association, candidates, breakdown, slot);
        case 'car':
          return this.handleCarAction(operation, candidates);
        case 'establishment':
          return this.handleEstablishmentAction(candidates, operation.companyId);
        case 'investment':
          return from(this.handleInvestmentAction(candidates, operation, breakdown, association));
        case 'loan':
          return this.handleLoanAction(candidates);
        case 'operation':
          return this.handleAction(candidates);
        case 'salesChannel':
          return this.handleSaleChannelAction(candidates, operation);
        case 'paymentsProcessor':
          return this.handlePaymentsProcessorAction(candidates, operation);
        default:
          return of(null);
      }
    }

    return of(null);
  }

  private async handleOperationAction(
    operation: Operation,
    breakdown: Breakdown,
    associationName: string,
    slotName: string,
  ): Promise<unknown | null | undefined> {
    const { SelectCandidateInvoiceComponent } = await import(
      '../modals/select-candidate-invoice/select-candidate-invoice.component'
    );
    return await lastValueFrom(
      this.openModal<{ id: number }>(SelectCandidateInvoiceComponent, {
        operation,
        breakdown,
        associationName,
        slotName,
      }).pipe(map((res: OverlayCloseEvent<{ id: number } | null | undefined>) => res.data?.id)),
    );
  }

  private handleAction(candidates: AssociationSlotCandidates): Observable<unknown | null | undefined> {
    return this.openModal<AssociationSlotCandidate>(SelectAssociationCandidateComponent, {
      candidates,
      enableSearch: true,
    }).pipe(map((res: OverlayCloseEvent<AssociationSlotCandidate | null | undefined>) => res.data?.value));
  }

  private handleCustomerAction(candidates: AssociationSlotCandidates): Observable<unknown | null | undefined> {
    return this.openModal<{ id: number }>(SelectCandidateCustomerComponent, {
      candidates,
    }).pipe(map((res: OverlayCloseEvent<{ id: number } | null | undefined>) => res.data?.id));
  }

  private handleSupplierAction(candidates: AssociationSlotCandidates): Observable<unknown | null | undefined> {
    return this.openModal<{ id: number }>(SelectCandidateSupplierComponent, {
      candidates,
    }).pipe(map((res: OverlayCloseEvent<{ id: number } | null | undefined>) => res.data?.id));
  }

  private handleLoanAction(candidates: AssociationSlotCandidates): Observable<unknown | null | undefined> {
    return this.openModal<{ id: number }>(SelectCandidateLoanComponent, {
      candidates,
    }).pipe(map((res: OverlayCloseEvent<{ id: number } | null | undefined>) => res.data?.id));
  }

  private handleSaleChannelAction(
    candidates: AssociationSlotCandidates,
    operation: Operation,
  ): Observable<unknown | null | undefined> {
    return this.openModal<{ id: number }>(SelectCandidateSalesChannelComponent, {
      candidates,
      operation,
      disableClose: false,
    }).pipe(map((res: OverlayCloseEvent<{ id: number } | null | undefined>) => res.data?.id));
  }

  private handlePaymentsProcessorAction(
    candidates: AssociationSlotCandidates,
    operation: Operation,
  ): Observable<unknown | null | undefined> {
    return this.openModal<{ id: number }>(SelectCandidatePaymentsProcessorComponent, {
      candidates,
      operation,
    }).pipe(map((res: OverlayCloseEvent<{ id: number } | null | undefined>) => res.data?.id));
  }

  private async handleInvestmentAction(
    candidates: AssociationSlotCandidates,
    operation: Operation,
    breakdown: Breakdown,
    association: Association,
  ): Promise<number | undefined> {
    if (candidates.items.length === 0 && candidates.descriptor.fallbackToCreation) {
      // eslint-disable-next-line @nx/enforce-module-boundaries
      const { InvestmentModalComponent } = await import('@dougs/investment/ui');
      return await lastValueFrom(
        this.openModal<{ id: number }>(InvestmentModalComponent, {
          breakdown,
          operation,
          candidates,
        }).pipe(map((res: OverlayCloseEvent<{ id: number } | null | undefined>) => res.data?.id)),
      );
    }

    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { AskForSaleDateModalComponent } = await import('@dougs/investment/ui');

    return await lastValueFrom(
      this.openModal<{ id: number }>(SelectCandidateInvestmentComponent, {
        breakdown,
        operation,
        candidates,
        association,
      }).pipe(
        switchMap((res: OverlayCloseEvent<{ id: number } | null | undefined>) => {
          if (res.data?.id && candidates.descriptor.askForSaleDate) {
            return this.openModal<{ id: number }>(AskForSaleDateModalComponent, {
              investmentId: res.data?.id,
              operation,
              scrapping: false,
            }).pipe(map((res: OverlayCloseEvent<{ id: number } | null | undefined>) => res.data?.id));
          }

          return of(res.data?.id);
        }),
      ),
    );
  }

  private handleCarAction(
    operation: Operation,
    candidates: AssociationSlotCandidates,
  ): Observable<unknown | null | undefined> {
    let modalResult: Observable<OverlayCloseEvent<Record<string, string> | null | undefined>>;

    if (candidates.descriptor.allowedRecordFilter?.type === 'rental') {
      modalResult = this.openModal<Record<string, string>>(SelectModalComponent, {
        items: RENTAL_CARS.items,
        title: candidates.descriptor.title,
      });
    } else {
      modalResult = this.openModal<Record<string, string>>(SelectCandidateCarComponent, {
        candidates,
      });
    }

    return modalResult.pipe(
      switchMap((res: OverlayCloseEvent<Record<string, string> | null | undefined>) =>
        this.createCarIfNotExist(operation, res.data),
      ),
      map((res: Record<string, string> | null) => (res ? res.id : null)),
    );
  }

  private handleEstablishmentAction(
    candidates: AssociationSlotCandidates,
    companyId: number,
  ): Observable<unknown | null | undefined> {
    return this.openModal<{ value: number }>(SelectCandidateEstablishmentComponent, {
      candidates,
      companyId,
    }).pipe(map((res: OverlayCloseEvent<{ value: number } | null | undefined>) => res.data?.value));
  }

  private createCarIfNotExist(
    operation: Operation,
    data: Record<string, string> | null | undefined,
  ): Observable<Record<string, string> | null> {
    if (data) {
      return data.id
        ? of(data)
        : this.vehicleStateService.createCar(operation.companyId, data as Record<string, string>);
    }

    return of(null);
  }

  private handlePartnerAction(
    association: Association,
    candidates: AssociationSlotCandidates,
    breakdown: Breakdown,
    slot: AssociationSlot,
  ): Observable<unknown | null | undefined> {
    return this.openModal<AssociationSlotCandidate>(SelectCandidatePartnerComponent, {
      descriptor: candidates.descriptor,
      breakdown,
      association,
      slot,
      items: candidates.items,
    }).pipe(map((res: OverlayCloseEvent<AssociationSlotCandidate | null | undefined>) => res.data?.value));
  }

  private openModal<T>(component: Type<unknown>, data: unknown) {
    return this.modalService
      .open<T>(component, {
        data,
      })
      .afterClosed$.pipe(take(1));
  }
}
