<div
  *ngIf="isEcommerceSaleLineVisible"
  @slideOut
  (mouseover)="showClose = true"
  (mouseleave)="showClose = false"
  class="sale-line ml-16 px-8 py-4"
  [ngClass]="{ 'mb-4': !isLast }"
>
  <img *ngIf="sale.flag" class="mr-8" src="images/flags/{{ sale.flag }}.svg" alt="Drapeau" />
  <div class="sale-line__label" [class.extended]="!sale.isRefund">
    <p class="small color-primary-700 sale-line__label__title">
      {{ sale.label }}
      <span *ngIf="sale.subLabel.hasSeparator && sale.subLabel.label">-</span>
    </p>
    <p
      [dougsTooltip]="sale.subLabel.label"
      [dougsTooltipDisable]="sale.taxRate > 0"
      class="tiny sale-line__label__sub-label"
      [class.has-tax-rate]="sale.taxRate > 0"
      *ngIf="sale.subLabel.label"
    >
      &nbsp;{{ sale.subLabel.label }}
    </p>
    <p [dougsTooltip]="sale.subLabel.packageInfo" class="tiny sale-line__information" *ngIf="sale.subLabel.packageInfo">
      <span *ngIf="sale.subLabel.packageInfo">&nbsp;-</span>
      {{ sale.subLabel.packageInfo }}
    </p>
    <p
      [dougsTooltip]="sale.subLabel.specificVatRateReason"
      class="tiny sale-line__information"
      *ngIf="sale.subLabel.specificVatRateReason"
    >
      <span>&nbsp;-</span>
      {{ sale.subLabel.specificVatRateReason }}
    </p>
  </div>
  <dougs-pill type="success" *ngIf="sale.isRefund">Remboursement</dougs-pill>
  <dougs-form-field size="small" [noMargin]="true" [autoWidth]="true">
    <input
      class="sale-line__input"
      type="text"
      dougsFormFieldControl
      [disabled]="disabled"
      [ngModel]="saleAmount | numberToString"
      [ngModelOptions]="{ updateOn: 'blur' }"
      (ngModelChange)="onAmountChange($event)"
    />
    <i dougsFormFieldSuffix class="fal fa-euro-sign"></i>
  </dougs-form-field>
  <ng-container *ngIf="sale.subLabel.hasVat; else withoutVAT">
    <p class="sale-line__vat xtiny">dont TVA</p>
    <dougs-form-field size="small" [noMargin]="true" [autoWidth]="true">
      <input
        class="sale-line__input min-width"
        type="text"
        dougsFormFieldControl
        [disabled]="!sale.subLabel?.isVatAmountEditable || operation.validated"
        [ngModel]="sale.vatAmount | numberToString"
        (ngModelChange)="onVatAmountChange($event)"
      />
      <i dougsFormFieldSuffix class="fal fa-euro-sign"></i>
    </dougs-form-field>
  </ng-container>
  <ng-template #withoutVAT>
    <div class="sale-line__help">
      <p class="xtiny mr-4">HT</p>
      <i
        class="fal fa-question-circle"
        [dougsTooltip]="'La TVA est collectée et reversée par la marketplace. Veuillez saisir le montant hors taxe.'"
      ></i>
    </div>
  </ng-template>
  <div class="sale-line__close pointer">
    <i *ngIf="showClose && !disabled" (click)="onRemoveSaleLine()" class="fal fa-times color-primary-700"></i>
  </div>
</div>
