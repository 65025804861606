import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { Customer } from '@dougs/customers/dto';
import { MODAL_DATA, ModalRef, ModalService } from '@dougs/ds';
import { AssociationSlotCandidate, AssociationSlotCandidates } from '@dougs/operations/dto';

@Injectable()
export class SelectCandidateCustomerComponentService {
  constructor(
    @Inject(MODAL_DATA)
    public data: { candidates: AssociationSlotCandidates; enableSearch: boolean },
    private readonly modalRef: ModalRef<{ id: unknown }, AssociationSlotCandidate>,
    private readonly modalService: ModalService,
  ) {}

  selectItem(item: { id: unknown }): void {
    this.modalRef.close(item);
  }

  async createCustomer(e: Event): Promise<void> {
    e.preventDefault();

    const { CustomerCreationModalComponent } = await import('@dougs/customers/ui');

    const customerCreated: Customer | null | undefined = (
      await lastValueFrom(this.modalService.open<Customer>(CustomerCreationModalComponent).afterClosed$)
    ).data;

    if (customerCreated) {
      this.modalRef.close({ id: customerCreated.id });
    }
  }
}
