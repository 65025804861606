import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, ElementRef, Input, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { AvatarComponent, CheckboxComponent, ControlFormFieldDirective } from '@dougs/ds';
import { Association, Breakdown, Operation } from '@dougs/operations/dto';
import { OperationBreakdownAssociationComponentService } from '../../../../services/operation-breakdown-association.component.service';

@Component({
  selector: 'dougs-operation-breakdown-association',
  templateUrl: './operation-breakdown-association.component.html',
  styleUrls: ['./operation-breakdown-association.component.scss'],
  providers: [OperationBreakdownAssociationComponentService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ControlFormFieldDirective, AvatarComponent, CheckboxComponent, FormsModule],
})
export class OperationBreakdownAssociationComponent {
  @Input() operation!: Operation;
  @Input() breakdown!: Breakdown;
  @Input() association!: Association;
  @Input() isInOperationListModal = false;
  @Input() mixpanelModalName?: string;

  @ViewChild('contenteditable') content?: ElementRef;

  constructor(
    public readonly operationBreakdownAssociationComponentService: OperationBreakdownAssociationComponentService,
  ) {}
}
