import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DougsDatePipe } from '@dougs/ds';
import { AccountingLine } from '@dougs/operations/dto';
import { NumberToStringPipe } from '../../../../../pipes/number-to-string.pipe';
import { OperationDetailsAccountingLinesComponentService } from '../../../../../services/operation-details-accounting-lines.component.service';

@Component({
  selector: 'dougs-operation-details-accounting-lines',
  templateUrl: './operation-details-accounting-lines.component.html',
  styleUrls: ['./operation-details-accounting-lines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NumberToStringPipe, DougsDatePipe],
  providers: [OperationDetailsAccountingLinesComponentService],
})
export class OperationDetailsAccountingLinesComponent {
  constructor(
    public readonly operationDetailsAccountingLinesComponentService: OperationDetailsAccountingLinesComponentService,
  ) {}

  @Input() accountingLines!: AccountingLine[];

  @Input() isCompact = false;

  trackByLine(index: number, item: AccountingLine) {
    return item.id;
  }
}
