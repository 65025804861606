import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalCloseDirective, ModalContentDirective, ModalTitleDirective, PanelInfoComponent } from '@dougs/ds';
import { SelectCandidateCustomerComponentService } from '../../services/select-candidate-customer.component.service';

@Component({
  selector: 'dougs-select-candidate-customer',
  templateUrl: './select-candidate-customer.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, CommonModule, PanelInfoComponent],
  providers: [SelectCandidateCustomerComponentService],
})
export class SelectCandidateCustomerComponent {
  constructor(public readonly selectCandidateCustomerComponentService: SelectCandidateCustomerComponentService) {}
}
