<div dougsModalTitle>
  <h6>{{ selectCandidateInvestmentComponentService.data.candidates.descriptor.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div
  dougsModalContent
  class="modal-select-association-container"
  [immediateCheck]="true"
  infiniteScroll
  (scrolled)="onScroll()"
  [scrollWindow]="false"
>
  <p
    class="small"
    *ngIf="
      !selectCandidateInvestmentComponentService.data.candidates.items.length &&
      selectCandidateInvestmentComponentService.data.candidates.descriptor.emptyText
    "
    [innerHTML]="selectCandidateInvestmentComponentService.data.candidates.descriptor.emptyText"
  ></p>
  <ul class="select-candidate">
    <li
      *ngFor="let item of selectCandidateInvestmentComponentService.data.candidates.items; trackBy: trackByIndex"
      class="p-8"
      (click)="selectCandidateInvestmentComponentService.selectItem({ id: item.value })"
    >
      <div>
        {{ item.label }}
        <p class="small" [innerHTML]="item.description"></p>
      </div>
    </li>
  </ul>
  <dougs-panel-info
    class="mt-8"
    *ngIf="selectCandidateInvestmentComponentService.data.candidates.descriptor.fallbackToCreation"
  >
    Votre immobilisation n'est pas dans la liste&nbsp;?
    <a href="#" (click)="selectCandidateInvestmentComponentService.createInvestment($event)">Créez-la ici</a>
    .
  </dougs-panel-info>
</div>
