import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DougsDatePipe } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { OperationDateComponentService } from '../../../../services/operation-date.component.service';

@Component({
  selector: 'dougs-operation-date',
  templateUrl: './operation-date.component.html',
  styleUrls: ['./operation-date.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, DougsDatePipe],
  providers: [OperationDateComponentService],
})
export class OperationDateComponent {
  incomingOperation = false;

  private _operation!: Operation;

  get operation(): Operation {
    return this._operation;
  }

  @Input()
  set operation(operation: Operation) {
    this._operation = operation;
    this.incomingOperation = this.operationDateComponentService.incomingOperation(operation);
  }

  constructor(private readonly operationDateComponentService: OperationDateComponentService) {}
}
