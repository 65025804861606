import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FileInputComponent,
  FilePillComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  LoaderFullpageComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
  SalesInvoicePillComponent,
  SourceDocumentPillComponent,
  SuffixFormFieldDirective,
  TrackByPipe,
} from '@dougs/ds';
import { CashPaymentModalComponentService } from '../../services/cash-payment-modal.component.service';
import { CategoryComponent } from '../category-modal/category.component';

@Component({
  selector: 'dougs-cash-payment-modal',
  templateUrl: './cash-payment-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    PanelInfoComponent,
    InputDatepickerComponent,
    FormFieldComponent,
    ControlFormFieldDirective,
    LabelFormFieldDirective,
    SuffixFormFieldDirective,
    ErrorFormFieldDirective,
    CategoryComponent,
    FileInputComponent,
    FilePillComponent,
    ButtonComponent,
    LoaderFullpageComponent,
    TrackByPipe,
    SalesInvoicePillComponent,
    SourceDocumentPillComponent,
  ],
  providers: [CashPaymentModalComponentService],
})
export class CashPaymentModalComponent {
  constructor(
    public readonly cashPaymentModalComponentService: CashPaymentModalComponentService,
    public formService: FormService,
  ) {}
}
