<div dougsModalTitle>
  <h6>{{ selectCandidateSupplierComponentService.data.candidates.descriptor.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent class="modal-select-association-container">
  <p class="small" *ngIf="!selectCandidateSupplierComponentService.data.candidates.items.length">
    Vous n'avez pas encore renseigné de fournisseur dans Dougs.
  </p>
  <ul class="select-candidate">
    <ng-container *ngFor="let item of selectCandidateSupplierComponentService.data.candidates.items">
      <li
        *ngIf="item.label && item.value"
        class="p-8"
        (click)="selectCandidateSupplierComponentService.selectItem({ id: item.value })"
      >
        <div>
          {{ item.label }}
          <p class="small" [innerHTML]="item.description"></p>
        </div>
      </li>
    </ng-container>
  </ul>
  <dougs-panel-info class="mt-8">
    Votre fournisseur n'est pas dans la liste&nbsp;?
    <a href="#" (click)="selectCandidateSupplierComponentService.createSupplier($event)">Créez-le ici</a>
    .
  </dougs-panel-info>
</div>
