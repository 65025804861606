<dougs-avatar
  [size]="isCompact ? 'small' : 'medium'"
  [image]="
    operation.messenger
      ? operation.messenger.profile.avatarUrl || operation.messenger.profile.alternateAvatarUrl
      : 'https://images.prismic.io/test-dougs/059d75a6-aa18-472d-891d-20a83105a3f7_sarah-small.jpeg'
  "
></dougs-avatar>
<p class="message" [ngClass]="{ 'p-8 ml-8 tiny': !isCompact, 'p-4 ml-4 xtiny': isCompact }">
  {{ operation.message }}
</p>
