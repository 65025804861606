<div class="section-title">
  <h3>{{ section?.label }}</h3>
  <p
    *ngIf="section?.panelInfo"
    (click)="ecommerceSectionTitleComponentService.toggle(section?.id, operation, salesChannel)"
    class="tiny color-primary-700 pointer"
  >
    En savoir plus
    <i class="fal fa-chevron-down ml-8" [class.rotate]="ecommerceSectionTitleComponentService.isOpen$()"></i>
  </p>
</div>
<dougs-ecommerce-panel-info
  *ngIf="section?.panelInfo && ecommerceSectionTitleComponentService.isOpen$()"
  [panelInfo]="section.panelInfo"
  class="mt-8"
  @slideInOut
></dougs-ecommerce-panel-info>
