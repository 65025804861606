import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Signal,
  signal,
  WritableSignal,
} from '@angular/core';
import {
  AbstractControl,
  FormGroup,
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { SourceDocumentAttachment } from '@dougs/core/files';
import { FormService } from '@dougs/core/form';
import { Customer } from '@dougs/customers/dto';
import { CustomerStateService } from '@dougs/customers/shared';
import {
  ButtonComponent,
  CheckboxComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FileInputComponent,
  FilePillComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  LoaderFullpageComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
  SourceDocumentPillComponent,
  SuffixFormFieldDirective,
  TrackByPipe,
  VendorInvoicePillComponent,
} from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { NotValidatedOperationsStateService, SourceDocumentAttachmentUtils } from '@dougs/operations/shared';
import { createdOperationDateValidatorForCompany } from '../../validators/operation.validator';
import { CategoryComponent } from '../category-modal/category.component';

@Component({
  selector: 'dougs-customer-invoice-operation-modal',
  standalone: true,
  imports: [
    CommonModule,
    ButtonComponent,
    CategoryComponent,
    ControlFormFieldDirective,
    ErrorFormFieldDirective,
    FileInputComponent,
    FilePillComponent,
    FormFieldComponent,
    InputDatepickerComponent,
    LabelFormFieldDirective,
    LoaderFullpageComponent,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    ModalTitleDirective,
    PanelInfoComponent,
    ReactiveFormsModule,
    SelectComponent,
    SelectOptionComponent,
    SuffixFormFieldDirective,
    TrackByPipe,
    VendorInvoicePillComponent,
    CheckboxComponent,
    SourceDocumentPillComponent,
  ],
  templateUrl: './customer-invoice-operation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomerInvoiceOperationModalComponent {
  customers: Customer[] = [];
  operation?: Operation;

  formGroup!: FormGroup;

  filesMap: Map<string, File> = new Map<string, File>();

  private readonly tmpSourceDocumentAttachments: WritableSignal<SourceDocumentAttachment[]> = signal<
    SourceDocumentAttachment[]
  >([]);
  tmpSourceDocumentAttachments$: Signal<SourceDocumentAttachment[]> = this.tmpSourceDocumentAttachments.asReadonly();

  private readonly isLoading: WritableSignal<boolean> = signal(false);
  isLoading$: Signal<boolean> = this.isLoading.asReadonly();

  get date(): AbstractControl | null {
    return this.formGroup.get('date');
  }

  get amount(): AbstractControl | null {
    return this.formGroup.get('amount');
  }

  get isCreditInvoice(): AbstractControl | null {
    return this.formGroup.get('isCreditInvoice');
  }

  get category(): AbstractControl | null {
    return this.formGroup.get('category');
  }

  get customer(): AbstractControl | null {
    return this.formGroup.get('customer');
  }

  get memo(): AbstractControl | null {
    return this.formGroup.get('memo');
  }

  get invoiceNumber(): AbstractControl | null {
    return this.formGroup.get('invoiceNumber');
  }

  constructor(
    @Inject(MODAL_DATA)
    private readonly data: {
      operation: Operation;
    },
    private readonly companyStateService: CompanyStateService,
    private readonly customerStateService: CustomerStateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
    private readonly notValidatedOperationsStateService: NotValidatedOperationsStateService,
    private readonly fb: UntypedFormBuilder,
    public formService: FormService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.operation = this.data?.operation;
    await this.customerStateService.refreshCustomers(this.companyStateService.activeCompany.id);
    this.customers = await lastValueFrom(this.customerStateService.customers$.pipe(take(1)));
    this.buildForm();

    if (this.customers.length === 1) {
      this.customer?.setValue(this.customers[0].id);
    }

    this.cdr.markForCheck();
  }

  buildForm(): void {
    this.formGroup = this.fb.group({
      date: [
        this.operation?.date ? this.operation.date : new Date(),
        [
          Validators.required,
          createdOperationDateValidatorForCompany(this.companyStateService.activeCompany, 'invoiceCustomer'),
        ],
      ],
      amount: [this.operation?.amount || '', [Validators.required]],
    });

    if (!this.operation) {
      this.formGroup.addControl('isCreditInvoice', new UntypedFormControl(false, [Validators.required]));
      this.formGroup.addControl('category', new UntypedFormControl('', [Validators.required]));
      this.formGroup.addControl('customer', new UntypedFormControl('', [Validators.required]));
      this.formGroup.addControl('memo', new UntypedFormControl(null));
      this.formGroup.addControl('invoiceNumber', new UntypedFormControl('', [Validators.required]));
    }
  }

  async onSubmit(): Promise<void> {
    this.formGroup.markAllAsTouched();

    if (!this.formGroup.invalid) {
      this.isLoading.set(true);
      let result: boolean;

      if (this.operation) {
        result = await this.updateIncomeOperation();
      } else {
        result = await this.saveExpenseOperation();
      }

      if (result) {
        this.modalRef.close();
      }
      this.isLoading.set(false);
    }
  }

  private async saveExpenseOperation(): Promise<boolean> {
    const { date, isCreditInvoice, category, memo, amount, customer, invoiceNumber } = this.formGroup.value;
    const customerName: string | undefined = this.customers.find((c) => c.id === customer)?.name;
    // eslint-disable-next-line @typescript-eslint/typedef
    let invoiceCustomerLabel = `N° ${invoiceNumber}`;
    if (customerName) {
      invoiceCustomerLabel += ` - ${customerName}`;
    }
    invoiceCustomerLabel += ' 🧾';
    if (memo) {
      invoiceCustomerLabel += ` - ${memo}`;
    }
    const operationCreated: Operation | null = await this.notValidatedOperationsStateService.createOperation(
      this.companyStateService.activeCompany.id,
      {
        type: 'invoiceCustomer',
        date,
        memo: invoiceCustomerLabel,
        amount,
        isInbound: !isCreditInvoice,
        metadata: { invoiceNumber, clientName: customerName, status: ['pending'] },
        breakdowns: [
          {
            amount,
            categoryId: category.id,
          },
          {
            amount: 0,
            categoryId: -1,
            isCounterpart: true,
            associationData: {
              customerId: customer.id,
            },
          },
        ],
      },
      Array.from(this.filesMap.values()),
    );
    return !!operationCreated;
  }

  private async updateIncomeOperation(): Promise<boolean> {
    const { isCreditInvoice, date, amount } = this.formGroup.value;
    const updatedOperation: Operation = {
      ...this.operation,
      isInbound: !isCreditInvoice,
      date,
      amount,
    } as Operation;
    return !!(await this.notValidatedOperationsStateService.updateOperation(updatedOperation));
  }

  onUploadFiles(files: FileList): void {
    Array.from(files).forEach((file: File) => {
      const tmpSda: SourceDocumentAttachment =
        SourceDocumentAttachmentUtils.createSourceDocumentAttachmentFromFile(file);
      this.filesMap.set(tmpSda.sourceDocument.tempUuid as string, file);
      this.tmpSourceDocumentAttachments.update((tmpSdas) => [...tmpSdas, tmpSda]);
    });
  }

  deleteSourceDocumentAttachment(sourceDocumentAttachment: SourceDocumentAttachment): void {
    this.filesMap.delete(sourceDocumentAttachment.sourceDocument.tempUuid as string);
    this.tmpSourceDocumentAttachments.update((tmpSdas) =>
      tmpSdas.filter((tmp) => tmp.sourceDocument.tempUuid !== sourceDocumentAttachment.sourceDocument.tempUuid),
    );
  }
}
