import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DougsDatePipe, PillComponent } from '@dougs/ds';
import { EcommerceSales } from '@dougs/ecommerce/dto';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EcommerceBetaPillComponent } from '@dougs/ecommerce/ui';
import { Operation } from '@dougs/operations/dto';
import { EcommerceOperationSynchronizationService } from '../../../../../services/ecommerce-operation-synchronization.service';

@Component({
  selector: 'dougs-ecommerce-overview',
  templateUrl: './ecommerce-overview.component.html',
  styleUrls: ['./ecommerce-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, PillComponent, DougsDatePipe, EcommerceBetaPillComponent],
})
export class EcommerceOverviewComponent {
  @Input() operation?: Operation;
  @Input() ecommerceSales?: EcommerceSales;

  constructor(public readonly ecommerceOperationSynchronizationService: EcommerceOperationSynchronizationService) {}
}
