import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { distinctUntilChanged, tap } from 'rxjs/operators';
import { evalString } from '@dougs/core/utils';
import { DropdownComponent } from '@dougs/ds';
import { Association, Breakdown, Operation } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
import { NumberToStringPipe } from '../pipes/number-to-string.pipe';
import { HandleSlotActionComponentService } from './handle-slot-action.component.service';
import { OperationComponentService } from './operation.component.service';

@Injectable()
export class OperationVatComponentService {
  private _operation!: Operation;
  private readonly _breakdown: BehaviorSubject<Breakdown | null> = new BehaviorSubject<Breakdown | null>(null);
  private _vatAmount!: number;

  breakdown$: Observable<Breakdown | null> = this._breakdown.asObservable();
  vatControl = new FormControl<string>('0', { updateOn: 'blur', nonNullable: true });
  vatControlChanges$ = this.vatControl.valueChanges.pipe(
    distinctUntilChanged(),
    tap((vat: string) => {
      const vatAmount: number = Math.round(parseFloat(evalString(vat)) * 100) / 100;
      this.operationComponentService.updateVat(this._breakdown.value, vatAmount);
    }),
  );
  isDisabled = false;

  constructor(
    private readonly operationComponentService: OperationComponentService,
    private readonly operationService: OperationService,
    private readonly numberToStringPipe: NumberToStringPipe,
    private readonly handleSlotActionComponentService: HandleSlotActionComponentService,
  ) {}

  get operation(): Operation {
    return this._operation;
  }

  set operation(operation: Operation) {
    this._operation = operation;
    this.populateVatControl(operation, this._breakdown.value);
    this.isDisabled = this.shouldDisableVat(operation, this._breakdown.value);
  }

  set breakdown(breakdown: Breakdown) {
    void this.handleVatExemption(this.operation, this._breakdown.value, breakdown);
    this._breakdown.next(breakdown);
    this.populateVatControl(this.operation, breakdown);
    this._vatAmount = breakdown ? breakdown.vatAmount : this.operation.vatAmount;
    this.isDisabled = this.shouldDisableVat(this.operation, breakdown);
  }

  get vatAmount(): number {
    return this._vatAmount;
  }

  set vatAmount(value) {
    this._vatAmount = value;
  }

  public async handleVatExemption(
    operation: Operation,
    previousBreakdown: Breakdown | null,
    currentBreakdown: Breakdown,
  ): Promise<void> {
    if (!this.vatControl.dirty || !previousBreakdown || !currentBreakdown) {
      return;
    }

    this.vatControl.markAsPristine();

    const currentVatExemptionAssociation: Association | undefined = currentBreakdown?.associations.find(
      (association) => association.name === 'vatExemptionReason',
    );

    const previousVatExemptionAssociation: Association | undefined = previousBreakdown?.associations.find(
      (association) => association.name === 'vatExemptionReason',
    );

    if (currentVatExemptionAssociation && !previousVatExemptionAssociation) {
      const value: unknown | null | undefined = await lastValueFrom(
        this.handleSlotActionComponentService.handleSlotAction(
          operation,
          currentBreakdown,
          currentVatExemptionAssociation,
          currentVatExemptionAssociation.slots.reason,
        ),
      );

      if (value) {
        this.operationComponentService.updateAssociation(
          currentBreakdown,
          currentVatExemptionAssociation.slots.reason,
          value,
        );
      }
    }
  }

  populateVatControl(operation: Operation, breakdown: Breakdown | null): void {
    if (operation || breakdown) {
      this.vatControl.setValue(
        this.numberToStringPipe.transform(breakdown ? breakdown.vatAmount : operation.vatAmount),
        { emitEvent: false },
      );
    }
  }

  selectVat(breakdown: Breakdown, vatPercentage: number): void {
    const vatAmount: number = Math.round(breakdown.amount * (1 - 1 / (1 + vatPercentage)) * 100) / 100;
    this.vatControl.setValue(this.numberToStringPipe.transform(vatAmount));
    this.vatControl.markAsDirty();
  }

  shouldDisableVat(operation: Operation, breakdown: Breakdown | null): boolean {
    return this.operationService.isReadOnly(operation) || !breakdown?.isVatAmountEditable;
  }

  onVatEnter(event: Event, dropdownVat: DropdownComponent): void {
    (event.target as HTMLElement).blur();
    if (dropdownVat.showing) {
      dropdownVat.hide();
    }
  }

  selectInput(e: Event): void {
    (e.target as HTMLInputElement).select();
  }
}
