import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import {
  DougsDatePipe,
  ModalCloseDirective,
  ModalContentDirective,
  ModalTitleDirective,
  PanelInfoComponent,
} from '@dougs/ds';
import { SelectCandidateCarComponentService } from '../../services/select-candidate-car.component.service';

@Component({
  selector: 'dougs-select-candidate-car',
  templateUrl: './select-candidate-car.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    CommonModule,
    PanelInfoComponent,
    DougsDatePipe,
  ],
  providers: [SelectCandidateCarComponentService],
})
export class SelectCandidateCarComponent {
  constructor(public readonly selectCandidateCarComponentService: SelectCandidateCarComponentService) {}
}
