import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Params, RouterLink } from '@angular/router';
import { SourceDocumentType } from '@dougs/core/files';
import { ControlFormFieldDirective } from '@dougs/ds';
import { SourceDocumentMatch } from '@dougs/operations/dto';
import { OperationReceiptMatchingComponentService } from '../../../../services/operation-receipt-matching.component.service';

@Component({
  selector: 'dougs-operation-receipt-matching',
  standalone: true,
  imports: [CommonModule, ControlFormFieldDirective, RouterLink],
  providers: [OperationReceiptMatchingComponentService],
  templateUrl: './operation-receipt-matching.component.html',
  styleUrls: ['./operation-receipt-matching.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OperationReceiptMatchingComponent {
  public queryParams?: Params;
  private _sourceDocumentMatch!: SourceDocumentMatch;

  @Input()
  set sourceDocumentMatch(value: SourceDocumentMatch) {
    this._sourceDocumentMatch = value;
    const queryParamsKey: string =
      this.sourceDocumentMatch?.sourceDocument?.type === SourceDocumentType.VENDOR_INVOICE
        ? 'vendorInvoiceId'
        : 'salesInvoiceId';
    this.queryParams = { [queryParamsKey]: this.sourceDocumentMatch?.sourceDocument?.externalId };
  }

  get sourceDocumentMatch(): SourceDocumentMatch {
    return this._sourceDocumentMatch;
  }

  constructor(public readonly operationReceiptMatchingComponentService: OperationReceiptMatchingComponentService) {}

  protected readonly SourceDocumentType = SourceDocumentType;
}
