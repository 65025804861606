<div *ngIf="isEcommerceSaleSubSectionVisible" @slideOut class="mx-24 mt-32" [class.mb-16]="isLast">
  <div class="subsection__line mb-8 pb-8">
    <div class="subsection__line__title">
      <img alt="flag-{{ subSection.flag }}" *ngIf="subSection.flag" src="images/flags/{{ subSection.flag }}.svg" />
      <p class="small bold color-primary-700">{{ subSection.label }}</p>
    </div>
    <i
      *ngIf="subSection.isDeletable && !disabled"
      (click)="onRemoveSubSection(section, subSection)"
      class="pointer mr-8 fal fa-times color-primary-700"
    ></i>
  </div>
  <div *ngFor="let sale of subSection.sales; let last = last; trackBy: 'breakdownId' | trackBy">
    <dougs-ecommerce-sales-line
      @slideIn
      [sale]="sale"
      [operation]="operation"
      [sectionId]="section.sectionId"
      [subSectionId]="subSection.subSectionId"
      [disabled]="disabled"
      [isLast]="last"
    ></dougs-ecommerce-sales-line>
  </div>
  <div
    *ngIf="subSection.addButton && !disabled"
    (click)="operationEcommerceComponentService.onAddEcommerceSale(section, subSection, operation)"
    class="subsection__add bold pointer ml-48 mt-8"
  >
    + {{ subSection.addButton }}
  </div>
</div>
