<dougs-pill
  *ngIf="operationPillsComponentService.feeBreakdown$ | async as feeBreakdown"
  [type]="feeBreakdown.isInbound ? 'success' : 'warning'"
  class="mr-8 mt-8"
>
  commission {{ feeBreakdown.isInbound ? '+' : '-' }}{{ feeBreakdown.amount | currency: 'EUR' }}
</dougs-pill>
<dougs-pill type="success" *ngIf="operationPillsComponentService.isRefund$ | async" class="mr-8 mt-8">
  remboursement
</dougs-pill>

<ng-container *ngIf="operationPillsComponentService.operation$ | async as operation">
  <dougs-pill type="primary" *ngIf="operation.isInvoice" class="mr-8 mt-8">
    facture {{ operation.transaction?.metadata?.plan === 'standalone' ? 'manuelle' : '' }}
  </dougs-pill>
  <dougs-pill type="primary" *ngIf="operation.isInvoice" class="mr-8 mt-8">
    {{ operationPillsComponentService.invoiceStatusString$ | async }}
  </dougs-pill>
  <dougs-pill type="error" *ngIf="operation.metadata?.isTmpInvoice" class="mr-8 mt-8"> Facture provisoire </dougs-pill>
  <dougs-pill type="error" *ngIf="operation.excluded && operation.exclusionReason === 'manual'" class="mr-8 mt-8">
    exclue manuellement
  </dougs-pill>
  <dougs-pill type="error" *ngIf="operation.excluded && operation.exclusionReason !== 'manual'" class="mr-8 mt-8">
    exclue automatiquement
  </dougs-pill>
  <dougs-pill type="warning" *ngIf="operation.isDraft" class="mr-8 mt-8">brouillon</dougs-pill>
  <dougs-pill *ngIf="operation.isMiscellaneous" class="mr-8 mt-8">opération comptable</dougs-pill>
  <dougs-pill *ngIf="operation.isReconciliation" class="mr-8 mt-8">réconciliation</dougs-pill>
  <dougs-pill *ngIf="operation.isDispatch" class="mr-8 mt-8">ventilation</dougs-pill>
  <dougs-pill type="admin" *ngIf="operation.autogenerated" class="mr-8 mt-8">autogénérée</dougs-pill>
  <dougs-pill *ngIf="operation.transaction?.isDeferred" class="mr-8 mt-8">
    débit différé du {{ operation.transaction?.releaseDate | date: 'dd/MM' }}
  </dougs-pill>
  <dougs-pill
    type="admin"
    *ngIf="(userStateService.loggedInUser$ | async).isAccountantOrAdmin && operation.transaction?.isManual"
    class="mt-8 mr-8"
  >
    saisie manuelle
  </dougs-pill>
  <dougs-pill
    type="admin"
    *ngIf="(userStateService.loggedInUser$ | async).isAccountantOrAdmin && operation.detachDeleted"
    class="mt-8 mr-8"
  >
    forcée
  </dougs-pill>
</ng-container>
