import { Injectable } from '@angular/core';
import { RecursivePartial } from '@dougs/core/utils';
import { ModalService } from '@dougs/ds';
import { Breakdown, Operation } from '@dougs/operations/dto';
import { OperationService, ValidatedOperationsStateService } from '@dougs/operations/shared';
import {
  CashPaymentModalComponent,
  ExpenseOperationModalComponent,
  KilometricIndemnityModalComponent,
  VendorInvoiceOperationModalComponent,
} from '../modals';
import { OperationEcommerceMetricsService } from './operation-ecommerce-metrics.service';
import { OperationMetricsService } from './operation-metrics.service';
import { OperationComponentService } from './operation.component.service';

@Injectable()
export class OperationActionsComponentService {
  isLoading = false;

  constructor(
    private readonly modalService: ModalService,
    private readonly operationMetricsService: OperationMetricsService,
    private readonly operationEcommerceMetricsService: OperationEcommerceMetricsService,
    private readonly validatedOperationsStateService: ValidatedOperationsStateService,
    private readonly operationComponentService: OperationComponentService,
    private readonly operationService: OperationService,
  ) {}

  public editOperation(operation: Operation): void {
    switch (operation.type) {
      case 'expense':
        this.modalService.open(ExpenseOperationModalComponent, {
          data: {
            operation,
          },
        });
        break;
      case 'kilometricIndemnity':
        this.modalService.open(KilometricIndemnityModalComponent, {
          data: {
            operation,
          },
        });
        break;
      case 'vendorInvoice':
        this.modalService.open(VendorInvoiceOperationModalComponent, {
          data: {
            operation,
          },
        });
        break;
      case 'cashPayment':
        this.modalService.open(CashPaymentModalComponent, {
          data: {
            operation,
          },
        });
        break;
    }
  }

  public async removeOperation(
    operation: Operation,
    isInOperationListModal: boolean,
    mixpanelModalName?: string,
  ): Promise<void> {
    if (!this.isLoading) {
      this.operationMetricsService.trackRemoveOperation(isInOperationListModal, mixpanelModalName);
      this.isLoading = true;
      this.operationComponentService.hideOperation();
      const isDeleted = await this.validatedOperationsStateService.removeOperation(operation);

      if (!isDeleted) {
        this.operationComponentService.showOperation();
      }

      this.isLoading = false;
    }
  }

  public validateOperation(
    operation: Operation,
    isInOperationListModal: boolean,
    mixpanelModalName?: string,
    forceUndraft?: boolean,
  ): void {
    if (!this.isLoading) {
      this.isLoading = true;

      if (operation.metadata.salesChannelId) {
        this.operationEcommerceMetricsService.trackEcommerceOperationValidated(operation);
      }

      this.operationMetricsService.trackValidateOperation(operation);

      const mainBreakdown: Breakdown | null = this.operationService.getMainBreakdown(operation);

      this.operationMetricsService.trackValidateBreakdownOperation(
        operation,
        this.operationService.getSelectedCategory(mainBreakdown).wording,
        isInOperationListModal,
        mixpanelModalName,
      );
      let partialOperation: RecursivePartial<Operation> = {
        validated: !operation.validated,
      };
      if (forceUndraft) {
        partialOperation = { ...partialOperation, isDraft: !operation.isDraft };
      }
      this.operationComponentService.updateOperation(partialOperation, true);

      this.isLoading = false;
    }
  }
}
