import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, Input } from '@angular/core';
import { Attachment } from '@dougs/core/files';
import {
  FileInputComponent,
  FilePillComponent,
  LoaderComponent,
  PanelInfoComponent,
  SourceDocumentPillComponent,
} from '@dougs/ds';
import { SalesChannel } from '@dougs/ecommerce/dto';
import { Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { UserStateService } from '@dougs/user/shared';
import { OperationEcommerceDispatchFilesComponentService } from '../../../../services/operation-ecommerce-dispatch-files.component.service';

@Component({
  selector: 'dougs-operation-ecommerce-dispatch-files',
  templateUrl: './operation-ecommerce-dispatch-files.component.html',
  styleUrls: ['./operation-ecommerce-dispatch-files.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    PanelInfoComponent,
    FileInputComponent,
    FilePillComponent,
    SourceDocumentPillComponent,
  ],
  providers: [OperationEcommerceDispatchFilesComponentService],
})
export class OperationEcommerceDispatchFilesComponent {
  @Input()
  set operation(operation: Operation) {
    this.operationEcommerceDispatchFilesComponentService.operation = operation;
  }

  @Input()
  set salesChannels(salesChannels: SalesChannel[]) {
    this.operationEcommerceDispatchFilesComponentService.salesChannels = salesChannels;
  }

  constructor(
    @Inject(OPERATION_STATE_TOKEN) public readonly operationsStateService: AbstractOperationsStateService<any>,
    public readonly operationEcommerceDispatchFilesComponentService: OperationEcommerceDispatchFilesComponentService,
    public readonly userStateService: UserStateService,
  ) {}

  trackById(index: number, item: Attachment): string | number {
    return item.id;
  }
}
