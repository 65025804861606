import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  HostListener,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { groupByKey } from '@dougs/core/utils';
import { DividerComponent, LoaderComponent, PanelInfoComponent, PillComponent, TooltipDirective } from '@dougs/ds';
import { EcommerceSaleStateService, SalesChannelStateService } from '@dougs/ecommerce/shared';
import { Breakdown, Operation, OperationSection } from '@dougs/operations/dto';
import { AbstractOperationsStateService, OPERATION_STATE_TOKEN, OperationService } from '@dougs/operations/shared';
import { UserStateService } from '@dougs/user/shared';
import { BreakdownService } from '../../../../services/breakdown.service';
import { HandleSlotActionComponentService } from '../../../../services/handle-slot-action.component.service';
import { OperationDetailsComponentService } from '../../../../services/operation-details.component.service';
import { OperationEcommerceMetricsService } from '../../../../services/operation-ecommerce-metrics.service';
import { OperationEcommerceComponentService } from '../../../../services/operation-ecommerce.component.service';
import { EcommerceOperationDetailsComponent } from '../ecommerce/ecommerce-operation-details/ecommerce-operation-details.component';
import { EcommerceShopifyOperationPanelComponent } from '../ecommerce/ecommerce-shopify-operation-panel/ecommerce-shopify-operation-panel.component';
import { OperationBreakdownComponent } from '../operation-breakdown/operation-breakdown.component';
import { OperationEcommerceDispatchFilesComponent } from '../operation-ecommerce-dispatch-files/operation-ecommerce-dispatch-files.component';
import { OperationFilesComponent } from '../operation-files/operation-files.component';
import { OperationDetailsAccountingLinesComponent } from './operation-details-accounting-lines/operation-details-accounting-lines.component';
import { OperationDetailsActionsComponent } from './operation-details-actions/operation-details-actions.component';

@Component({
  selector: 'dougs-operation-details',
  templateUrl: './operation-details.component.html',
  styleUrls: ['./operation-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    LoaderComponent,
    PanelInfoComponent,
    PillComponent,
    DividerComponent,
    TooltipDirective,
    OperationDetailsActionsComponent,
    OperationDetailsAccountingLinesComponent,
    OperationFilesComponent,
    OperationBreakdownComponent,
    OperationEcommerceDispatchFilesComponent,
    EcommerceOperationDetailsComponent,
    EcommerceShopifyOperationPanelComponent,
  ],
  providers: [OperationDetailsComponentService, OperationEcommerceComponentService, HandleSlotActionComponentService],
})
export class OperationDetailsComponent implements OnInit {
  showAddBreakdown = true;
  private _operation!: Operation;
  groupedBreakdowns!: { [key: string]: Breakdown[] };
  shouldShowEcommerceDispatchFile = false;
  isSalesChannelEcommerceOperation = false;
  totalAmountExcludingFee = 0;

  @Input()
  set operation(operation: Operation) {
    this._operation = operation;
    if (operation) {
      this.showAddBreakdown = !this.operationService.isReadOnly(this.operation);
      this.operationEcommerceService.setEcommerceOperation(operation);
      this.isSalesChannelEcommerceOperation = this.operationService.isSalesChannelEcommerceOperation(operation);
      this.shouldShowEcommerceDispatchFile =
        this.operationDetailsComponentService.shouldShowEcommerceDispatchFile(operation);
      this.totalAmountExcludingFee = this.operationService.getTotalAmountExcludingFee(operation);
    }
    if (operation?.breakdowns?.length) {
      const breakdowns = this.operationDetailsComponentService.orderBreakdownByCreation(this.operation.breakdowns);
      this.groupedBreakdowns = groupByKey((breakdown: Breakdown) => breakdown.section, breakdowns);
    } else {
      // Reset here is needed in case the operation is updated with no breakdowns (delete last breakdown)
      this.groupedBreakdowns = {};
    }
  }

  get operation(): Operation {
    return this._operation;
  }

  @Input() isInOperationListModal = false;
  @Input() mixpanelModalName?: string;
  @Input() forceEditable = false;
  @Input() noPadding = false;
  @Input() shouldShowDate = true;
  @Input() disableCall = false;
  @Input() isCompact = false;
  @Input() enableFileAnimation = false;
  @Input() disableReceiptMatching = false;

  @Output() emitOperationAction: EventEmitter<boolean> = new EventEmitter();

  constructor(
    @Inject(OPERATION_STATE_TOKEN) private readonly operationsStateService: AbstractOperationsStateService<any>,
    public readonly userStateService: UserStateService,
    public readonly operationService: OperationService,
    public readonly operationEcommerceService: OperationEcommerceComponentService,
    public readonly salesChannelState: SalesChannelStateService,
    public readonly ecommerceSaleStateService: EcommerceSaleStateService,
    public readonly operationDetailsComponentService: OperationDetailsComponentService,
    public readonly companyStateService: CompanyStateService,
    public readonly operationEcommerceMetricsService: OperationEcommerceMetricsService,
    public readonly breakdownService: BreakdownService,
  ) {}

  async ngOnInit(): Promise<void> {
    if (!this.disableCall) {
      await this.operationsStateService.refreshOperationById(this.operation.companyId, this.operation.id);
    }
    if (this.isSalesChannelEcommerceOperation) {
      await this.ecommerceSaleStateService.refreshEcommerceSales(this.operation);
    }
    if (this.operation.metadata.salesChannelId) {
      this.operationEcommerceMetricsService.trackEcommerceOperationOpened(
        this.operation,
        this.operationEcommerceService.salesChannel,
      );
    }
  }

  trackBySection(index: number, section: OperationSection): string {
    return section.id;
  }

  trackByBreakdown(index: number, breakdown: Breakdown): number {
    return breakdown.id;
  }

  @HostListener('window:keydown.Meta.ArrowRight', ['$event'])
  @HostListener('window:keydown.Control.ArrowRight', ['$event'])
  addMiscellaneousOperationCategory(event: KeyboardEvent): void {
    event.preventDefault();
    this.operationDetailsComponentService.addBreakdownFromMiscellaneousOperation(this.operation, this.showAddBreakdown);
  }

  @HostListener('window:keydown.Meta.Backspace', ['$event'])
  @HostListener('window:keydown.Control.Backspace', ['$event'])
  removeMiscellaneousOperationCategory(event: KeyboardEvent): void {
    event.preventDefault();
    this.operationDetailsComponentService.onRemoveBreakdown();
  }
}
