import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalCloseDirective, ModalContentDirective, ModalTitleDirective, PanelInfoComponent } from '@dougs/ds';
import { SelectCandidateLoanComponentService } from '../../services/select-candidate-loan.component.service';

@Component({
  selector: 'dougs-select-candidate-loan',
  templateUrl: './select-candidate-loan.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, CommonModule, PanelInfoComponent],
  providers: [SelectCandidateLoanComponentService],
})
export class SelectCandidateLoanComponent {
  constructor(public readonly selectCandidateLoanComponentService: SelectCandidateLoanComponentService) {}
}
