<ng-container *ngIf="operationVatComponentService.vatControlChanges$ | async"></ng-container>
<div
  *ngIf="operationVatComponentService.breakdown$ | async as breakdown"
  [ngClass]="[displayAsField ? '' : 'operation-detail__vat']"
>
  <div *ngIf="!displayAsField" [ngClass]="{ 'mr-8': !isCompact, 'mr-4 operation-detail__vat__compact': isCompact }">
    dont TVA
    <ng-container *ngIf="breakdown.vatRate">{{ breakdown.vatRate * 100 | number: '0.0-2' }}%</ng-container>
  </div>
  <dougs-form-field
    *ngIf="!operationVatComponentService.isDisabled && !forceDisabled"
    [dougsDropdownTrigger]="dropdownVat"
    [size]="displayAsField ? 'medium' : 'small'"
    [noMargin]="displayAsField"
    [ngClass]="[displayAsField ? '' : 'mt-4 operation-detail__vat__input']"
  >
    <label *ngIf="displayAsField" for="vat" dougsFormFieldLabel>TVA</label>
    <input
      (focus)="operationVatComponentService.selectInput($event)"
      type="text"
      id="vat"
      [disabled]="forceDisabled"
      dougsFormFieldControl
      [formControl]="operationVatComponentService.vatControl"
      (keydown.enter)="operationVatComponentService.onVatEnter($event, dropdownVat)"
    />
    <i dougsFormFieldSuffix class="fal fa-euro-sign"></i>
  </dougs-form-field>

  <dougs-form-field
    *ngIf="operationVatComponentService.isDisabled || forceDisabled"
    [size]="displayAsField ? 'medium' : 'small'"
    [noMargin]="displayAsField"
    [ngClass]="{ compact: isCompact, 'mt-4 operation-detail__vat__input': !displayAsField }"
  >
    <label *ngIf="displayAsField" for="vat" dougsFormFieldLabel>TVA</label>
    <input type="text" dougsFormFieldControl [disabled]="true" [(ngModel)]="operationVatComponentService.vatAmount" />
    <i dougsFormFieldSuffix class="far fa-euro-sign"></i>
  </dougs-form-field>
  <dougs-dropdown #dropdownVat [widthToRefElement]="false">
    <dougs-dropdown-option [closeOnClick]="true" (click)="operationVatComponentService.selectVat(breakdown, 0.2)">
      <span class="operation-detail__vat__option">
        <span class="color-gray-325">TVA à</span>
        20%
      </span>
    </dougs-dropdown-option>
    <dougs-dropdown-option [closeOnClick]="true" (click)="operationVatComponentService.selectVat(breakdown, 0.1)">
      <span class="operation-detail__vat__option">
        <span class="color-gray-325">TVA à</span>
        10%
      </span>
    </dougs-dropdown-option>
    <dougs-dropdown-option [closeOnClick]="true" (click)="operationVatComponentService.selectVat(breakdown, 0.055)">
      <span class="operation-detail__vat__option">
        <span class="color-gray-325">TVA à</span>
        5,5%
      </span>
    </dougs-dropdown-option>
    <dougs-dropdown-option [closeOnClick]="true" (click)="operationVatComponentService.selectVat(breakdown, 0)">
      <span class="operation-detail__vat__option"><span class="color-gray-325">Pas de TVA</span></span>
    </dougs-dropdown-option>
  </dougs-dropdown>
</div>
