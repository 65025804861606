import { Injectable } from '@angular/core';
import { MetricsService } from '@dougs/core/metrics';
import { Operation } from '@dougs/operations/dto';

@Injectable({ providedIn: 'root' })
export class OperationMetricsService {
  constructor(private readonly metricsService: MetricsService) {}

  public trackCategoryModalOpening(): void {
    this.metricsService.pushMixpanelEvent('click-categorize-breakdown', { Source: 'accountingModule' });
  }

  public trackBreakdownCreating(): void {
    this.metricsService.pushMixpanelEvent('click-create-breakdown', { Source: 'accountingModule' });
  }

  public trackBreakdownRemoving(): void {
    this.metricsService.pushMixpanelEvent('click-delete-breakdown', { Source: 'accountingModule' });
  }

  public trackCategoryChange(isInOperationListModal: boolean, mixpanelModalName?: string): void {
    this.metricsService.pushMixpanelEvent('Accounting Operation Breakdown Categorized FrontSide', {
      'CTA Location': isInOperationListModal ? 'Modal Operation List' : 'Accounting',
      'Modal Name': isInOperationListModal ? mixpanelModalName : null,
    });
  }

  public trackFilesUpload(
    isInOperationListModal: boolean,
    inCategorizationHelp: boolean,
    mixpanelModalName?: string,
  ): void {
    if (inCategorizationHelp) {
      this.metricsService.pushMixpanelEvent('Categorization Help File Attached');
      return;
    }

    this.metricsService.pushMixpanelEvent('Accounting Operation Breakdown Filed Attached FrontSide', {
      'CTA Location': isInOperationListModal ? 'Modal Operation List' : 'Accounting',
      'Modal Name': isInOperationListModal ? mixpanelModalName : null,
    });
  }

  public trackValidateOperation(operation: Operation): void {
    this.metricsService.pushMixpanelEvent(
      operation.validated ? 'click-unvalidate-operation' : 'click-validate-operation',
      { Source: 'accountingModule' },
    );
  }

  public trackValidateBreakdownOperation(
    operation: Operation,
    categoryName: string,
    isInOperationListModal: boolean,
    mixpanelModalName?: string,
  ): void {
    this.metricsService.pushMixpanelEvent(
      operation.validated
        ? 'Accounting Operation Breakdown Edited FrontSide'
        : 'Accounting Operation Breakdown Validated FrontSide',
      {
        'CTA Location': isInOperationListModal ? 'Modal Operation List' : 'Accounting',
        'Modal Name': isInOperationListModal ? mixpanelModalName : null,
        'Category Name': categoryName,
      },
    );
  }

  public trackRemoveOperation(isInOperationListModal: boolean, mixpanelModalName?: string): void {
    this.metricsService.pushMixpanelEvent('click-delete-operation', { Source: 'accountingModule' });
    this.metricsService.pushMixpanelEvent('Accounting Operation Breakdown Deleted FrontSide', {
      'CTA Location': isInOperationListModal ? 'Modal Operation List' : 'Accounting',
      'Modal Name': isInOperationListModal ? mixpanelModalName : null,
    });
  }

  public trackOperationAssociation(
    categoryName: string,
    associationName: string,
    valueChangedType: 'new' | 'updated' | 'deleted' | 'unchanged',
    isInOperationListModal: boolean,
    mixpanelModalName?: string,
  ): void {
    this.metricsService.pushMixpanelEvent('Accounting Operation Breakdown Associated FrontSide', {
      'CTA Location': isInOperationListModal ? 'Modal Operation List' : 'Accounting',
      'Modal Name': isInOperationListModal ? mixpanelModalName : null,
      'Category Name': categoryName,
      'Association name': associationName,
      'Value changed type': valueChangedType,
    });
  }

  public trackOperationFileAttached(operation: Operation): void {
    this.metricsService.pushMixpanelEvent('Accounting Operation File Attached', {
      'CTA Location': 'Operation Detail Button',
      'Operation Id': operation.id,
    });
  }
}
