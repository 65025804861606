import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import {
  AvatarComponent,
  AvatarUrlPipe,
  BlankStateComponent,
  ButtonComponent,
  DougsDatePipe,
  LoaderComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  TrackByPipe,
} from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { OperationChangesHistoryModalComponentService } from '../../services/operation-changes-history-modal.component.service';

@Component({
  selector: 'dougs-operation-changes-history-modal',
  templateUrl: './operation-changes-history-modal.component.html',
  styleUrls: ['./operation-changes-history-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    LoaderComponent,
    AvatarComponent,
    CommonModule,
    BlankStateComponent,
    ButtonComponent,
    AvatarUrlPipe,
    TrackByPipe,
    DougsDatePipe,
  ],
  providers: [OperationChangesHistoryModalComponentService],
})
export class OperationChangesHistoryModalComponent implements OnInit {
  public objectKeys = Object.keys;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      operation: Operation;
    },
    public readonly operationChangesHistoryModalComponentService: OperationChangesHistoryModalComponentService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async ngOnInit(): Promise<void> {
    await this.operationChangesHistoryModalComponentService.refreshOperationHistoryChanges();
    this.cdr.markForCheck();
  }
}
