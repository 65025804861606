import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BehaviorSubject, filter, Observable } from 'rxjs';
import { concatMap, map, take, tap } from 'rxjs/operators';
import { ConfirmationModalComponent, ModalService } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { OperationChangesHistoryModalComponent } from '../modals/operation-changes-history-modal/operation-changes-history-modal.component';
import { OperationComponentService } from './operation.component.service';

@Injectable()
export class OperationDetailsActionsComponentService {
  private readonly _operation: BehaviorSubject<Operation | null> = new BehaviorSubject<Operation | null>(null);
  private readonly _showDatepicker: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _shouldShowAccountingReportFormControl: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false,
  );

  operation$: Observable<Operation | null> = this._operation.asObservable();
  showDatepicker$: Observable<boolean> = this._showDatepicker.asObservable();
  shouldShowAccountingReportFormControl$: Observable<boolean> =
    this._shouldShowAccountingReportFormControl.asObservable();

  noteFormControl: FormControl<string> = new FormControl<string>('', { updateOn: 'blur', nonNullable: true });

  noteFormControlValueChanges$: Observable<string> = this.noteFormControl.valueChanges.pipe(
    tap((note) =>
      this.operationComponentService.updateOperation({
        note,
      }),
    ),
  );

  dateFormControl: FormControl<string> = new FormControl<string>('', {
    updateOn: 'blur',
    nonNullable: true,
  });

  dateFormControlValueChanges$: Observable<void> = this.dateFormControl.valueChanges.pipe(
    filter(() => this.dateFormControl.valid),
    concatMap((date) => this.openConfirmationModal(date)),
  );

  journalFormControl: FormControl<string> = new FormControl<string>('', { nonNullable: true });

  journalFormControlValueChanges$: Observable<string> = this.journalFormControl.valueChanges.pipe(
    tap((journal) =>
      this.operationComponentService.updateOperation({
        metadata: {
          journal,
        },
      }),
    ),
  );

  accountingReportFormControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });

  accountingReportFormControlValueChanges$: Observable<boolean> = this.accountingReportFormControl.valueChanges.pipe(
    tap((isAccountingReport) => {
      this.operationComponentService.updateOperation({
        type: isAccountingReport ? 'miscellaneous:manual:accountingReport' : 'miscellaneous:manual',
        metadata: {
          journal: isAccountingReport ? 'RAN' : 'OD',
        },
      });
    }),
  );

  reversableFormControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });

  reversableFormControlValueChanges$: Observable<boolean> = this.reversableFormControl.valueChanges.pipe(
    tap((isReversable) => {
      this.operationComponentService.updateOperation({
        metadata: {
          isReversable,
        },
      });
    }),
  );

  excludedFormControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });

  excludedFormControlValueChanges$: Observable<boolean> = this.excludedFormControl.valueChanges.pipe(
    tap((excluded) => {
      this.operationComponentService.updateOperation({
        excluded,
      });
    }),
  );

  manuallyLockedFormControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });

  manuallyLockedFormControlValueChanges$: Observable<boolean> = this.manuallyLockedFormControl.valueChanges.pipe(
    tap((manuallyLocked) => {
      this.operationComponentService.updateOperation({
        manuallyLocked,
      });
    }),
  );

  detachDeletedFormControl: FormControl<boolean> = new FormControl<boolean>(false, { nonNullable: true });

  detachDeletedFormControlValueChanges$: Observable<boolean> = this.detachDeletedFormControl.valueChanges.pipe(
    tap((detachDeleted) => {
      this.operationComponentService.updateOperation({
        detachDeleted,
      });
    }),
  );

  constructor(
    private readonly operationComponentService: OperationComponentService,
    private readonly modalService: ModalService,
  ) {}

  set operation(operation: Operation) {
    this._operation.next(operation);

    if (this._operation) {
      this.populateFormControls(operation);
      this._shouldShowAccountingReportFormControl.next(this.shouldShowAccountingReportFormControl(operation));
      this._showDatepicker.next(this.shouldShowDatepicker(operation));
    }
  }

  populateFormControls(operation: Operation): void {
    this.noteFormControl.setValue(operation.note, { emitEvent: false });
    this.dateFormControl.setValue(operation.date, { emitEvent: false });
    this.journalFormControl.setValue(operation.metadata?.journal || '', { emitEvent: false });
    this.accountingReportFormControl.setValue(operation.type === 'miscellaneous:manual:accountingReport', {
      emitEvent: false,
    });
    this.reversableFormControl.setValue(operation.metadata?.isReversable || false, { emitEvent: false });
    this.excludedFormControl.setValue(operation.excluded, { emitEvent: false });
    this.manuallyLockedFormControl.setValue(operation.manuallyLocked, { emitEvent: false });
    this.detachDeletedFormControl.setValue(operation.detachDeleted, { emitEvent: false });
  }

  shouldShowDatepicker(operation: Operation): boolean {
    return (
      operation.isExpense || operation.isVendorInvoice || operation.isKilometricIndemnity || operation.isMiscellaneous
    );
  }

  openConfirmationModal(date: string): Observable<void> {
    return this.modalService
      .open(ConfirmationModalComponent, {
        data: {
          title: 'Confirmation',
          body: `Êtes-vous sûr de vouloir changer la date de cette opération ?`,
          noText: 'Non',
          yesText: 'Oui',
          secondaryColor: true,
        },
      })
      .afterClosed$.pipe(
        take(1),
        map((changed) => {
          if (changed.data) {
            this.operationComponentService.updateOperation({
              date,
            });
          }
        }),
      );
  }

  shouldShowAccountingReportFormControl(operation: Operation): boolean {
    return operation.type.startsWith('miscellaneous:manual');
  }

  onShowChangesHistoryModal(operation: Operation): void {
    this.modalService.open(OperationChangesHistoryModalComponent, {
      data: {
        operation,
      },
    });
  }

  setDefaultMessage(): void {
    const defaultMessage = 'Pouvez-vous joindre un justificatif à cette opération ?';
    this._operation.next({ ...(this._operation.value as Operation), ...{ message: defaultMessage } });
    this.operationComponentService.updateMessage(defaultMessage);
  }
}
