import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MODAL_DATA, ModalRef, ModalService, SelectModalComponent } from '@dougs/ds';
import { AssociationSlotCandidate, AssociationSlotCandidates } from '@dougs/operations/dto';
import { Car } from '@dougs/vehicles/dto';
import { RENTAL_CARS } from '@dougs/vehicles/shared';

@Injectable()
export class SelectCandidateCarComponentService {
  private readonly _showEndedCars: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _notEndedCars: BehaviorSubject<AssociationSlotCandidate[]> = new BehaviorSubject<
    AssociationSlotCandidate[]
  >([]);
  private readonly _endedCars: BehaviorSubject<AssociationSlotCandidate[]> = new BehaviorSubject<
    AssociationSlotCandidate[]
  >([]);

  notEndedCars$: Observable<AssociationSlotCandidate[]> = this._notEndedCars.asObservable();
  endedCars$: Observable<AssociationSlotCandidate[]> = this._endedCars.asObservable();
  showEndedCars$: Observable<boolean> = this._showEndedCars.asObservable();

  constructor(
    @Inject(MODAL_DATA)
    public data: { candidates: AssociationSlotCandidates; enableSearch: boolean },
    private readonly modalRef: ModalRef<Record<string, unknown>, AssociationSlotCandidate>,
    private readonly modalService: ModalService,
  ) {
    this._notEndedCars.next(
      this.data.candidates.items.filter((car) => !car.isEnded).sort((a, b) => a.label.localeCompare(b.label)),
    );
    this._endedCars.next(
      this.data.candidates.items.filter((car) => car.isEnded).sort((a, b) => a.label.localeCompare(b.label)),
    );
  }

  selectItem(item: Record<string, unknown>): void {
    this.modalRef.close(item);
  }

  async createCar(e: Event): Promise<void> {
    e.preventDefault();
    const { AddVehicleModalComponent } = await import('@dougs/vehicles/ui');
    const carCreated: Car | undefined | null = (
      await lastValueFrom(
        this.modalService.open<Car>(AddVehicleModalComponent, {
          data: this.data.candidates.descriptor.candidateRecordFilter,
        }).afterClosed$,
      )
    ).data;

    if (carCreated) {
      this.modalRef.close({ id: carCreated.id });
    }
  }

  displayAllCars(e: Event): void {
    e.preventDefault();
    this._showEndedCars.next(true);
  }

  async selectRentalCar(): Promise<void> {
    await lastValueFrom(
      this.modalService
        .open<Record<string, string>>(SelectModalComponent, {
          data: {
            items: RENTAL_CARS.items,
            title: 'Sélectionner un véhicule',
          },
        })
        .afterClosed$.pipe(
          tap((res) => {
            if (res.data) {
              this.selectItem(res.data);
            }
          }),
        ),
    );
  }
}
