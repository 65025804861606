import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ModalCloseDirective, ModalContentDirective, ModalTitleDirective, PanelInfoComponent } from '@dougs/ds';
import { SelectCandidateInvestmentComponentService } from '../../services/select-candidate-investment.component.service';

@Component({
  selector: 'dougs-select-candidate-investment',
  templateUrl: './select-candidate-investment.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    InfiniteScrollModule,
    CommonModule,
    PanelInfoComponent,
  ],
  providers: [SelectCandidateInvestmentComponentService],
})
export class SelectCandidateInvestmentComponent {
  constructor(
    public readonly selectCandidateInvestmentComponentService: SelectCandidateInvestmentComponentService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  trackByIndex(index: number): number {
    return index;
  }

  async onScroll(): Promise<void> {
    await this.selectCandidateInvestmentComponentService.onScroll();
    this.cdr.markForCheck();
  }
}
