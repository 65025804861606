import { Injectable } from '@angular/core';
import { format, parse } from 'date-fns';
import { BehaviorSubject, Observable } from 'rxjs';
import { Breakdown, Operation } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';

@Injectable()
export class OperationPillsComponentService {
  private readonly _operation: BehaviorSubject<Operation | null> = new BehaviorSubject<Operation | null>(null);
  private readonly _feeBreakdown: BehaviorSubject<Breakdown | null> = new BehaviorSubject<Breakdown | null>(null);
  private readonly _invoiceStatusString: BehaviorSubject<string> = new BehaviorSubject<string>('');
  private readonly _isRefund: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private readonly _isTemporaryInvoice: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  operation$: Observable<Operation | null> = this._operation.asObservable();
  feeBreakdown$: Observable<Breakdown | null> = this._feeBreakdown.asObservable();
  invoiceStatusString$: Observable<string> = this._invoiceStatusString.asObservable();
  isRefund$: Observable<boolean> = this._isRefund.asObservable();
  isTemporaryInvoice$: Observable<boolean> = this._isTemporaryInvoice.asObservable();

  constructor(private readonly operationService: OperationService) {}

  set operation(operation: Operation) {
    this._operation.next(operation);
    this._feeBreakdown.next(this.operationService.getFeeBreakdown(operation));
    this._isRefund.next(this.operationService.isRefund(operation));
    this._invoiceStatusString.next(this.getInvoiceStatusString(operation));
    this._isTemporaryInvoice.next(this.isTemporaryInvoice(operation));
  }

  private getInvoiceStatusString(operation: Operation): string {
    if (operation) {
      const paidAt = format(
        parse(operation.metadata.paidAt?.toString() || operation.date, 'yyyy-MM-dd', new Date()),
        'dd/MM/yyyy',
      );
      const status = operation.metadata.status;

      if (!status) {
        return '';
      }
      if (status.includes('overPaid')) {
        return `Trop payée le ${paidAt}`;
      }
      if (status.includes('overCredited')) {
        return `Trop d'avoir le ${paidAt}`;
      }
      if (status.includes('pending')) {
        return 'En attente de paiement';
      }
      if (status.includes('credited')) {
        return `Avoir le ${paidAt}`;
      }
      if (status.includes('paid')) {
        return `Payée le ${paidAt}`;
      }
      if (status.includes('partiallyPaid')) {
        return `Paiement partiel le ${paidAt}`;
      }
      if (status.includes('partiallyCredited')) {
        return `Avoir partiel le ${paidAt}`;
      }
    }

    return '';
  }

  private isTemporaryInvoice(operation: Operation): boolean {
    return operation.metadata?.isTmpInvoice || false;
  }
}
