import { Injectable } from '@angular/core';
import { Breakdown, Operation, OperationSection } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';

@Injectable({
  providedIn: 'root',
})
export class BreakdownService {
  constructor(private readonly operationService: OperationService) {}

  shouldShowBreakdown(breakdown: Breakdown): boolean {
    return !breakdown.isFee && !breakdown.isCounterpart;
  }

  shouldShowBreakdownMetadata(breakdown: Breakdown): boolean {
    return !!(
      breakdown.wording ||
      breakdown.quantity > 1 ||
      (breakdown.metadata && breakdown.metadata?.afterUnitAmountText)
    );
  }

  canRemoveBreakdown(operation: Operation, breakdown: Breakdown): boolean {
    return !this.operationService.isReadOnly(operation) && breakdown.isDeletable;
  }

  getSignedAmount(operation: Operation, breakdown: Breakdown, section: OperationSection): number {
    return breakdown.amount * (section.invertSign ? -1 : 1) * (breakdown.isInbound !== operation.isInbound ? -1 : 1);
  }
}
