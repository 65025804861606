import { Injectable } from '@angular/core';
import { Operation } from '@dougs/operations/dto';

@Injectable()
export class OperationAmountComponentService {
  public shouldShowOriginalCurrency(operation: Operation): boolean {
    return !!(
      (operation.isBank || operation.isInvoice) &&
      operation.transaction?.originalCurrency &&
      operation.transaction.originalAmount
    );
  }

  public shouldShowDifferentCurrency(operation: Operation): boolean {
    return (
      (operation.isBank || operation.isInvoice) && operation.transaction && operation.transaction.currency !== 'EUR'
    );
  }
}
