<div dougsModalTitle>
  <h6>{{ data.candidates.descriptor.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent class="modal-select-association-container">
  <dougs-form-field *ngIf="data.candidates.items.length !== 0 && data.enableSearch" class="mb-8">
    <i class="fal fa-search" dougsFormFieldPrefix></i>
    <input dougsAutofocus type="text" [formControl]="searchControl" dougsFormFieldControl placeholder="Rechercher" />
  </dougs-form-field>
  <p class="small" *ngIf="data.candidates.items.length === 0" [innerHTML]="data.candidates.descriptor.defaultText"></p>
  <ul class="select-candidate">
    <li *ngFor="let item of itemSearch" class="p-8" (click)="selectItem(item)">
      <img *ngIf="item.iconUrl" class="mr-16" [src]="item.iconUrl" [alt]="item.label" />
      <div>
        {{ item.label }}
        <p class="small" [innerHTML]="item.angular2Description || item.description"></p>
      </div>
    </li>
  </ul>
  <dougs-panel-info *ngIf="showOnlyPreferredItems">
    <div>
      Vous ne trouvez pas votre {{ data.candidates.descriptor.description.long }}&nbsp;?
      {{ data.candidates.descriptor.description.gender === 'F' ? 'Seules' : 'Seuls' }}
      les {{ data.candidates.descriptor.description.shortPlural }} les plus utilis{{
        data.candidates.descriptor.description.gender === 'F' ? 'ées' : 'és'
      }}
      sont affich{{ data.candidates.descriptor.description.gender === 'F' ? 'ées' : 'és' }}. Si vous le souhaitez, vous
      pouvez
      <a href="#" (click)="displayAll($event)">
        afficher {{ data.candidates.descriptor.description.gender === 'F' ? 'toutes' : 'tous' }} les
        {{ data.candidates.descriptor.description.shortPlural }}
      </a>
    </div>
  </dougs-panel-info>
  <dougs-panel-info
    *ngIf="
      !showOnlyPreferredItems &&
      (data.candidates.descriptor.additionalMessage || data.candidates.descriptor.angular2AdditionalMessage)
    "
  >
    <div *ngIf="data.candidates.descriptor.angular2AdditionalMessage">
      <span>{{ data.candidates.descriptor.angular2AdditionalMessage }}</span>
      <span
        *ngIf="data.candidates.descriptor.angular2AdditionalTextButton"
        class="underline"
        (click)="onOpenIntercom()"
      >
        {{ data.candidates.descriptor.angular2AdditionalTextButton }}
      </span>
    </div>
    <div *ngIf="!data.candidates.descriptor.angular2AdditionalMessage">
      {{ data.candidates.descriptor.additionalMessage }}
    </div>
  </dougs-panel-info>
</div>
