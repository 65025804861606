import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  BlurOnEnterDirective,
  ControlFormFieldDirective,
  FormFieldComponent,
  PillComponent,
  SuffixFormFieldDirective,
} from '@dougs/ds';
import { Breakdown, Operation, OperationCategorizationHelpStatus, OperationSection } from '@dougs/operations/dto';
import { CategoryComponent } from '../../../../modals/category-modal/category.component';
import { NumberToStringPipe } from '../../../../pipes/number-to-string.pipe';
import { OperationBreakdownComponentService } from '../../../../services/operation-breakdown.component.service';
import { OperationMetricsService } from '../../../../services/operation-metrics.service';
import { OperationBreakdownAssociationComponent } from '../operation-breakdown-association/operation-breakdown-association.component';
import { OperationVatComponent } from '../operation-vat/operation-vat.component';

@Component({
  selector: 'dougs-operation-breakdown',
  templateUrl: './operation-breakdown.component.html',
  styleUrls: ['./operation-breakdown.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    CategoryComponent,
    FormsModule,
    OperationBreakdownAssociationComponent,
    PillComponent,
    FormFieldComponent,
    NumberToStringPipe,
    ControlFormFieldDirective,
    BlurOnEnterDirective,
    OperationVatComponent,
    SuffixFormFieldDirective,
  ],
  providers: [OperationBreakdownComponentService],
})
export class OperationBreakdownComponent {
  @Input() set operation(operation: Operation) {
    this.operationBreakdownComponentService.operation = operation;
  }

  @Input() set section(section: OperationSection) {
    this.operationBreakdownComponentService.section = section;
  }

  @Input() set forceEditable(forceEditable: boolean) {
    this.operationBreakdownComponentService.forceEditable = forceEditable;
  }

  @Input() set forceDisabled(forceDisabled: boolean) {
    this.operationBreakdownComponentService.forceDisabled = forceDisabled;
  }

  @Input() isCompact = false;
  @Input() showCategoryLabel = false;

  @Input()
  set breakdown(breakdown: Breakdown) {
    this.operationBreakdownComponentService.breakdown = breakdown;
  }

  @Output() emitOperationAction: EventEmitter<boolean> = new EventEmitter();

  emitOperationAction$: Observable<boolean> = this.operationBreakdownComponentService.emitOperationAction$.pipe(
    tap((value) => this.emitOperationAction.emit(value)),
  );

  constructor(
    public readonly operationMetricsService: OperationMetricsService,
    public readonly operationBreakdownComponentService: OperationBreakdownComponentService,
  ) {}

  protected readonly OperationCategorizationHelpStatus = OperationCategorizationHelpStatus;
}
