import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { stringToNumber } from '@dougs/core/utils';
import {
  ControlFormFieldDirective,
  FormFieldComponent,
  ModalService,
  PillComponent,
  SuffixFormFieldDirective,
  TooltipDirective,
} from '@dougs/ds';
import { EcommerceSale } from '@dougs/ecommerce/dto';
import { Operation } from '@dougs/operations/dto';
import { NumberToStringPipe } from '../../../../../pipes/number-to-string.pipe';
import { OperationEcommerceMetricsService } from '../../../../../services/operation-ecommerce-metrics.service';
import { OperationEcommerceComponentService } from '../../../../../services/operation-ecommerce.component.service';

@Component({
  selector: 'dougs-ecommerce-sales-line',
  templateUrl: './ecommerce-sales-line.component.html',
  styleUrls: ['./ecommerce-sales-line.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    PillComponent,
    FormFieldComponent,
    ControlFormFieldDirective,
    FormsModule,
    NumberToStringPipe,
    TooltipDirective,
    SuffixFormFieldDirective,
  ],
  animations: [
    trigger('slideOut', [
      transition(':leave', [
        style({ height: '*', visibility: 'visible', opacity: 0.6 }),
        animate('200ms ease-in', style({ height: '0', visibility: 'hidden', opacity: 0 })),
      ]),
    ]),
  ],
})
export class EcommerceSalesLineComponent {
  private _sale!: EcommerceSale;
  get sale(): EcommerceSale {
    return this._sale;
  }

  @Input()
  set sale(sale: EcommerceSale) {
    this._sale = sale;
    this.saleAmount = this.sale.isRefund ? -this.sale.amount : this.sale.amount;
  }

  @Input() operation!: Operation;
  @Input() sectionId!: string;
  @Input() subSectionId!: string;
  @Input() disabled!: boolean;
  @Input() isLast = false;

  saleAmount!: number;

  public showClose = false;
  public isEcommerceSaleLineVisible = true;

  constructor(
    public readonly operationEcommerceComponentService: OperationEcommerceComponentService,
    public readonly modalService: ModalService,
    private readonly operationEcommerceMetricsService: OperationEcommerceMetricsService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  onAmountChange(amountString: string): void {
    const amount: number = stringToNumber(amountString);
    this.saleAmount = amount;
    this.operationEcommerceComponentService.onAmountChange(
      amount,
      this.sale,
      this.operation,
      this.sectionId,
      this.subSectionId,
    );
  }

  onVatAmountChange(amountString: string): void {
    const vatAmount: number = stringToNumber(amountString);
    this.operationEcommerceComponentService.onAmountChange(
      this.sale.amount,
      this.sale,
      this.operation,
      this.sectionId,
      this.subSectionId,
      vatAmount,
    );
  }

  async onRemoveSaleLine(): Promise<void> {
    this.operationEcommerceMetricsService.trackEcommerceOperationDispatchSalesRevenueRemoved(
      this.sectionId,
      this.subSectionId,
      this.sale.saleId,
      this.operation,
      this.operationEcommerceComponentService.salesChannel,
    );
    this.isEcommerceSaleLineVisible = false;
    const ecommerceSaleLineRemoved: boolean = await this.operationEcommerceComponentService.deleteEcommerceSale(
      this.sale,
      this.operation,
      this.sectionId,
      this.subSectionId,
    );
    if (!ecommerceSaleLineRemoved) {
      this.isEcommerceSaleLineVisible = true;
      this.cdr.markForCheck();
    }
  }
}
