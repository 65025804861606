<div class="operation-detail__amount">
  <span class="operation-detail__amount__different-currency" *ngIf="shouldShowDifferentCurrency">
    {{ operation.transaction.amount | currency: operation.transaction.currency : 'symbol-narrow' }}
    {{ operation.transaction.currency }}
    <i
      class="fal fa-question-circle"
      [dougsTooltip]="
        'Taux de change : 1€ = ' +
        (operation.transaction.changeRate | currency: operation.transaction.currency : 'symbol' : '1.0-4')
      "
    ></i>
  </span>
  <span class="operation-detail__amount__different-currency" *ngIf="shouldShowOriginalCurrency">
    {{ operation.transaction.originalAmount | currency: operation.transaction.originalCurrency : 'symbol-narrow' }}
    <i
      *ngIf="operation.transaction.originalChangeRate"
      class="fal fa-question-circle"
      [dougsTooltip]="
        'Taux de change : 1€ = ' +
        (operation.transaction.originalChangeRate
          | currency: operation.transaction.originalCurrency : 'symbol' : '1.0-4')
      "
    ></i>
  </span>
  <span
    class="operation-detail__amount__detail"
    [ngClass]="{ 'color-success': operation.isInbound, 'color-error': !operation.isInbound }"
    *ngIf="isDefaultOperation"
  >
    {{ operation.isInbound ? '+' : '-' }} {{ amountExcludingFee | currency: 'EUR' }}
  </span>
  <span class="operation-detail__amount__detail" *ngIf="operation.isKilometricIndemnity">
    {{ mainBreakdown?.associationData.kilometers }} Km
  </span>
  <span class="operation-detail__amount__different-currency" *ngIf="operation.isKilometricIndemnity">
    {{ operation.amount | currency: 'EUR' }}
  </span>
  <dougs-operation-vat
    (click)="$event.stopPropagation()"
    [operation]="operation"
    [breakdown]="mainBreakdown"
    *ngIf="operation.hasVat"
  ></dougs-operation-vat>
</div>
