import { Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { combineLatest, concatMap, filter, from, Observable, of, ReplaySubject, withLatestFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { toPromise } from '@dougs/core/utils';
import { ModalService } from '@dougs/ds';
import {
  EcommerceSaleErrors,
  EcommerceShopSyncModalComponentData,
  SalesChannel,
  ShopifyAuthenticatedSession,
} from '@dougs/ecommerce/dto';
import { EcommerceSaleStateService, SalesChannelStateService, ShopifyStateService } from '@dougs/ecommerce/shared';
import { Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { OperationEcommerceMetricsService } from './operation-ecommerce-metrics.service';

@Injectable()
export class EcommerceOperationSynchronizationService {
  private readonly synchronizableServices: string[] = ['shopify'];
  private readonly operationSubject: ReplaySubject<Operation> = new ReplaySubject<Operation>(1);
  private readonly shopifySalesChannel$: Observable<SalesChannel | undefined> =
    this.salesChannelStateService.salesChannels$.pipe(
      withLatestFrom(this.operationSubject),
      filter(([_, operation]) => !!operation),
      map(([salesChannels, operation]) =>
        this.getShopifySalesChannelIdByOperationSalesChannelId(operation, salesChannels),
      ),
    );
  private readonly isRefreshingOperation: WritableSignal<boolean> = signal<boolean>(false);
  private readonly isErrorReportLoaded: WritableSignal<boolean> = signal<boolean>(false);

  operation$: Observable<Operation | undefined> = this.operationSubject.asObservable();
  isSynchronizable$: Observable<boolean> = this.shopifySalesChannel$.pipe(
    map((salesChannel) => !!salesChannel && this.synchronizableServices.includes(salesChannel.type)),
  );
  isSynchronized$: Observable<boolean> = this.shopifySalesChannel$.pipe(
    concatMap((salesChannel: SalesChannel | undefined) => {
      if (salesChannel) {
        return from(this.shopifyStateService.isAuthenticated(salesChannel.companyId, salesChannel.id)).pipe(
          map((session: ShopifyAuthenticatedSession) => session.isAuthenticated),
        );
      }
      return of(false);
    }),
  );
  updateErrorReport$: Observable<EcommerceSaleErrors | null> = combineLatest([
    this.shopifySalesChannel$,
    this.operation$,
  ]).pipe(
    filter(([shopifySalesChannel, operation]) => !!shopifySalesChannel && !!operation),
    concatMap(([salesChannel, operation]) =>
      from(
        this.ecommerceSaleStateService.getEcommerceErrorReport(operation as Operation, salesChannel as SalesChannel),
      ),
    ),
    tap(() => this.isErrorReportLoaded.set(true)),
  );
  hasSalesError$: Observable<boolean> = combineLatest([
    this.ecommerceSaleStateService.salesErrors$,
    this.operation$,
  ]).pipe(
    filter(([salesErrors, operation]) => !!salesErrors && !!operation),
    map(([salesErrors, operation]) => {
      return (
        !!salesErrors &&
        !!operation &&
        (salesErrors.delivery?.orderDetails.length > 0 ||
          salesErrors.expedition?.orderDetails.length > 0 ||
          salesErrors.from?.orderDetails.length > 0 ||
          salesErrors.vat?.orderDetails.length > 0) &&
        !!operation.metadata.shopifySynchStatus
      );
    }),
  );
  shouldShowSyncBanner$: Observable<boolean> = combineLatest([this.operation$, this.isSynchronizable$]).pipe(
    filter(([operation, isSynchronizable]) => !!operation && !operation.validated && isSynchronizable),
    concatMap(() => this.isSynchronized$),
    map((isSynchronized) => !isSynchronized),
  );

  isErrorReportLoaded$: Signal<boolean> = this.isErrorReportLoaded.asReadonly();
  isRefreshingOperation$: Signal<boolean> = this.isRefreshingOperation.asReadonly();

  constructor(
    private readonly salesChannelStateService: SalesChannelStateService,
    private readonly ecommerceSaleStateService: EcommerceSaleStateService,
    private readonly operationEcommerceMetricsService: OperationEcommerceMetricsService,
    private readonly shopifyStateService: ShopifyStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly modalService: ModalService,
    @Inject(OPERATION_STATE_TOKEN) private readonly operationsStateService: AbstractOperationsStateService<any>,
  ) {}

  set operation(operation: Operation) {
    this.operationSubject.next(operation);
  }

  async openSynchronizationModal(): Promise<void> {
    const { EcommerceShopSyncModalComponent } = await import('@dougs/ecommerce/ui');
    const salesChannel: SalesChannel | undefined = await toPromise(this.shopifySalesChannel$);
    const operation: Operation | undefined = await toPromise(this.operation$);

    if (salesChannel && operation) {
      this.operationEcommerceMetricsService.trackEcommerceDispatchToSynchronizeClicked(salesChannel, operation);
      this.modalService.open<void, EcommerceShopSyncModalComponentData>(EcommerceShopSyncModalComponent, {
        data: { salesChannel, origin: 'dispatch' },
      });
    }
  }

  async openSalesAdjustmentModal(): Promise<void> {
    const { EcommerceSalesAdjustmentModalComponent } = await import(
      '../modals/ecommerce-sales-adjustment-modal/ecommerce-sales-adjustment-modal.component'
    );
    const salesChannel: SalesChannel | undefined = await toPromise(this.shopifySalesChannel$);

    if (salesChannel) {
      this.modalService.open<void, { salesChannel: SalesChannel }>(EcommerceSalesAdjustmentModalComponent, {
        data: { salesChannel },
      });
    }
  }

  async refreshShopifyDispatch(): Promise<void> {
    const operation: Operation | undefined = await toPromise(this.operation$);

    if (operation && operation.metadata.salesChannelId && !this.isRefreshingOperation$()) {
      const salesChannel: SalesChannel | undefined = await toPromise(this.shopifySalesChannel$);

      this.isRefreshingOperation.set(true);
      this.isErrorReportLoaded.set(false);

      this.operationEcommerceMetricsService.trackEcommerceOperationDispatchRefreshClicked(operation, salesChannel);
      await this.operationsStateService.refreshShopifyDispatchOperation(
        operation.companyId,
        operation.metadata.salesChannelId,
        operation.id,
      );

      this.isRefreshingOperation.set(false);
    }
  }

  private getShopifySalesChannelIdByOperationSalesChannelId(
    operation: Operation,
    salesChannels: SalesChannel[],
  ): SalesChannel | undefined {
    if (operation.type === 'dispatch:ecommerce:salesChannel') {
      return salesChannels.find(
        (salesChannel) => salesChannel.type === 'shopify' && operation?.metadata?.salesChannelId === salesChannel.id,
      );
    }
    return undefined;
  }
}
