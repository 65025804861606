<div dougsModalTitle>
  <h6>{{ selectCandidateLoanComponentService.data.candidates.descriptor.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent class="modal-select-association-container">
  <p class="small" *ngIf="!selectCandidateLoanComponentService.data.candidates.items.length">
    Vous n'avez pas encore renseigné d'emprunt dans Dougs.
  </p>
  <ul class="select-candidate">
    <ng-container *ngFor="let item of selectCandidateLoanComponentService.data.candidates.items">
      <li
        *ngIf="item.label && item.value"
        class="p-8"
        (click)="selectCandidateLoanComponentService.selectItem({ id: item.value })"
      >
        <div>
          {{ item.label }}
          <p class="small" [innerHTML]="item.description"></p>
        </div>
      </li>
    </ng-container>
  </ul>
  <dougs-panel-info class="mt-8">
    Votre emprunt n'est pas dans la liste&nbsp;?
    <a href="#" (click)="selectCandidateLoanComponentService.createLoan($event)">Créez-le ici</a>
    .
  </dougs-panel-info>
</div>
