<div class="operation-detail__actions">
  <dougs-button *ngIf="isLocked" [color]="operation.validated ? 'success' : 'primary'">
    <span class="operation-detail__actions__button-locked">
      <i class="far fa-lock"></i>
    </span>
  </dougs-button>
  <dougs-button
    (click)="
      $event.stopPropagation();
      operationActionsComponentService.validateOperation(operation, isInOperationListModal, mixpanelModalName)
    "
    *ngIf="!isLocked"
    [round]="true"
    [disabled]="operation.transaction && !operation.transaction.date"
    [dougsTooltip]="
      operation.transaction && !operation.transaction.date
        ? 'Vous ne pouvez pas valider cette opération, car la banque ne nous a pas encore transmis sa date de valeur.'
        : ''
    "
    [noWrap]="true"
    [color]="operation.validated ? 'success' : 'primary'"
    class="operation-detail__actions__button-validate"
  >
    <div class="operation-detail__actions__button-validate__container">
      <span
        class="operation-detail__actions__button-validate__icon"
        [ngClass]="{
          'mr-4': !isEditable && !isDeletable && !operation.validated,
          'only-validate': isEditable || isDeletable || operation.validated
        }"
      >
        <i class="fas" [ngClass]="{ 'fa-check': !operation.validated, 'fa-pencil': operation.validated }"></i>
      </span>
      <span class="operation-detail__actions__button-validate__text">{{
        !isEditable && !isDeletable && !operation.validated ? 'Valider' : ''
      }}</span>
    </div>
  </dougs-button>
  <dougs-button
    [round]="true"
    (click)="removeOperation($event)"
    *ngIf="isDeletable"
    [noWrap]="true"
    color="delete"
    class="operation-detail__actions__button-delete ml-8"
  >
    <span
      class="operation-detail__actions__button-delete__icon"
      [ngClass]="{ 'mr-4': !isEditable && !isDeletable, 'only-validate': isEditable || isDeletable }"
    >
      <i class="fas fa-times"></i>
    </span>
  </dougs-button>
  <dougs-button
    [round]="true"
    (click)="editOperation($event)"
    *ngIf="isEditable"
    [noWrap]="true"
    color="secondary"
    class="operation-detail__actions__button-edit ml-8"
  >
    <span
      class="operation-detail__actions__button-edit__icon"
      [ngClass]="{ 'mr-4': !isEditable && !isDeletable, 'only-validate': isEditable || isDeletable }"
    >
      <i class="far fa-pen"></i>
    </span>
  </dougs-button>
</div>
