<div *ngIf="sourceDocumentMatch?.sourceDocument?.externalId" class="receipt-matching mt-4" dougsFormFieldControl>
  <i class="fal fa-sparkles color-yellow-500"></i>
  <p class="tiny">Une facture semble correspondre</p>
  <a
    class="color-primary-700"
    (click)="$event.stopPropagation(); operationReceiptMatchingComponentService.trackVendorInvoiceDrawerOpened()"
    [routerLink]="[]"
    [queryParams]="queryParams"
    ><p class="tiny">Voir la facture</p></a
  >
</div>
