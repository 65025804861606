import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'numberToString',
  standalone: true,
})
export class NumberToStringPipe implements PipeTransform {
  transform(number: number, forceDecimal?: boolean): string {
    const roundedAmount: number = Math.round(number * 100) / 100;
    let [integer, decimals] = roundedAmount.toString().split('.') as string[];
    integer = integer.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    if (decimals || forceDecimal) {
      decimals = (decimals || '').padEnd(2, '0');
    }
    return decimals ? integer + ',' + decimals : integer;
  }
}
