<form class="form-investment" (ngSubmit)="onSubmit()" [formGroup]="formGroup" *ngIf="formGroup">
  <div dougsModalTitle>
    <h6>{{ operation ? 'Editer une facture de vente' : 'Ajouter une facture de vente' }}</h6>
    <i dougsModalClose class="fal fa-times"></i>
  </div>
  <div dougsModalContent>
    <dougs-panel-info *ngIf="date?.hasError('companyLockDate')" [type]="'error'" class="mb-16">
      <span>{{ date?.getError('companyLockDate') }}</span>
    </dougs-panel-info>
    <div class="form-field-line">
      <dougs-input-datepicker formControlName="date"></dougs-input-datepicker>
      <dougs-form-field>
        <label dougsFormFieldLabel for="amount">Montant</label>
        <input id="amount" type="number" formControlName="amount" min="0" step="0.01" dougsFormFieldControl />
        <i dougsFormFieldSuffix class="fas fa-euro-sign"></i>
        <span *ngIf="formService.isControlInvalid(amount)" dougsFormFieldError>
          <span *ngIf="amount.hasError('required')">Ce champ est requis</span>
        </span>
      </dougs-form-field>
      <dougs-form-field>
        <label dougsFormFieldLabel for="invoiceNumber">Numéro de Facture</label>
        <input id="invoiceNumber" type="text" formControlName="invoiceNumber" dougsFormFieldControl />
        <span *ngIf="formService.isControlInvalid(invoiceNumber)" dougsFormFieldError>
          <span *ngIf="invoiceNumber.hasError('required')">Ce champ est requis</span>
        </span>
      </dougs-form-field>
    </div>
    <dougs-form-field class="mb-24">
      <dougs-checkbox
        formControlName="isCreditInvoice"
        appearance="toggle"
        [showYesNo]="true"
        label="C'est une facture d'avoir"
      ></dougs-checkbox>
    </dougs-form-field>

    <dougs-form-field *ngIf="!operation">
      <label dougsFormFieldLabel>Catégorie</label>
      <dougs-category
        formControlName="category"
        [categoryType]="isCreditInvoice.value ? 'incomeCredit' : 'income'"
        [breakdownIsInbound]="true"
        dougsFormFieldControl
      ></dougs-category>
      <span *ngIf="formService.isControlInvalid(category)" dougsFormFieldError>
        <span *ngIf="category.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>
    <dougs-form-field *ngIf="!operation">
      <label dougsFormFieldLabel>Client</label>
      <dougs-select
        maxHeight="200"
        [searchable]="true"
        formControlName="customer"
        placeholder="Sélectionner un client"
        dougsFormFieldControl
      >
        <dougs-select-option *ngFor="let customer of customers; trackBy: 'id' | trackBy" [value]="customer.id">
          {{ customer.name }}
        </dougs-select-option>
      </dougs-select>
      <span *ngIf="formService.isControlInvalid(customer)" dougsFormFieldError>
        <span *ngIf="customer.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>
    <dougs-form-field *ngIf="!operation">
      <label dougsFormFieldLabel for="memo">Mémo</label>
      <input id="memo" type="text" formControlName="memo" dougsFormFieldControl />
    </dougs-form-field>
    <dougs-form-field *ngIf="!operation">
      <div class="files mt-8">
        <div class="file-list">
          <dougs-file-input (uploadFiles)="onUploadFiles($event)"></dougs-file-input>
          <dougs-source-document-pill
            *ngFor="let sourceDocumentAttachment of tmpSourceDocumentAttachments$(); trackBy: 'id' | trackBy"
            [sourceDocumentAttachment]="sourceDocumentAttachment"
            (deleteSourceDocumentAttachment)="deleteSourceDocumentAttachment($event)"
          ></dougs-source-document-pill>
        </div>
      </div>
    </dougs-form-field>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
    <dougs-button type="submit">
      {{ operation ? 'Modifier' : 'Ajouter' }}
    </dougs-button>
  </div>
</form>
<dougs-loader-fullpage *ngIf="isLoading$()">
  {{ operation ? 'Modification' : 'Création' }} en cours...
</dougs-loader-fullpage>
