<ng-container *ngIf="expenseOperationModalComponentService.refreshPartners$ | async"></ng-container>
<ng-container *ngIf="expenseOperationModalComponentService.populateDefaultPartner$ | async"></ng-container>

<form
  class="form-investment"
  (ngSubmit)="expenseOperationModalComponentService.onSubmit()"
  [formGroup]="expenseOperationModalComponentService.formGroup"
  *ngIf="expenseOperationModalComponentService.formGroup"
>
  <div dougsModalTitle>
    <h6>
      {{
        expenseOperationModalComponentService.data?.operation ? 'Editer une note de frais' : 'Ajouter une note de frais'
      }}
    </h6>
    <i (click)="expenseOperationModalComponentService.closeModal()" class="fal fa-times pointer"></i>
  </div>
  <div dougsModalContent>
    <dougs-panel-info class="mb-16">
      Les notes de frais sont des dépenses faites dans l'intérêt de votre entreprise mais
      <b>payées avec vos moyens personnels</b>
      , par exemple :
      <ul>
        <li>
          <b>Frais engagées à la création</b>
          (les frais de greffe, rédaction des statuts, annonces légales...)
        </li>
        <li>
          <b>Les achats ou frais généraux payés avec votre carte bleue</b>
          personnelle car vous avez oublié votre carte bleue professionnelle.
        </li>
        <li>
          <b>Frais compris dans la convention de mise à disposition</b>
          (loyer, électricité, box internet ...)
        </li>
      </ul>
      En savoir plus :
      <a
        rel="noopener"
        href="https://aide.dougs.fr/fr/articles/429153-tutoriel-video-dougs-qu-est-ce-qu-une-note-de-frais"
        target="_blank"
      >
        Qu'est-ce qu'une note de frais ?
      </a>
      <br />
      Article :
      <a
        rel="noopener"
        href="https://www.dougs.fr/blog/comment-mettre-en-place-une-convention-de-mise-a-disposition/"
        target="_blank"
      >
        Qu'est-ce qu'une convention de mise à disposition ?
      </a>
    </dougs-panel-info>
    <dougs-panel-info
      *ngIf="expenseOperationModalComponentService.date?.hasError('companyLockDate')"
      [type]="'error'"
      class="mb-16"
    >
      <span>{{ expenseOperationModalComponentService.date?.getError('companyLockDate') }}</span>
    </dougs-panel-info>
    <div class="form-field-line">
      <dougs-input-datepicker formControlName="date"></dougs-input-datepicker>
      <dougs-form-field>
        <label dougsFormFieldLabel for="amount">Montant</label>
        <input
          id="amount"
          type="number"
          formControlName="amount"
          min="0"
          step="0.01"
          pattern="^[0-9]+(,|\.[0-9]{1,2})?$"
          dougsFormFieldControl
        />
        <i dougsFormFieldSuffix class="fas fa-euro-sign"></i>
        <span *ngIf="formService.isControlInvalid(expenseOperationModalComponentService.amount)" dougsFormFieldError>
          <span *ngIf="expenseOperationModalComponentService.amount.hasError('required')">Ce champ est requis</span>
          <span *ngIf="expenseOperationModalComponentService.amount.hasError('pattern')">
            Maximum deux chiffres après la virgule
          </span>
        </span>
      </dougs-form-field>
    </div>
    <dougs-form-field *ngIf="!expenseOperationModalComponentService.data?.operation">
      <label dougsFormFieldLabel>Catégorie</label>
      <dougs-category
        formControlName="category"
        categoryType="expense"
        [operationDate]="expenseOperationModalComponentService.formGroup.get('date')?.value"
        placeholder="Sélectionnez une catégorie (ex. : Restaurant, Carburant pour véhicule)"
        dougsFormFieldControl
      ></dougs-category>
      <span *ngIf="formService.isControlInvalid(expenseOperationModalComponentService.category)" dougsFormFieldError>
        <span *ngIf="expenseOperationModalComponentService.category.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>
    <dougs-form-field *ngIf="!expenseOperationModalComponentService.data?.operation">
      <label dougsFormFieldLabel>Associé</label>
      <dougs-select formControlName="partner" placeholder="Sélectionner un associé" dougsFormFieldControl>
        <dougs-select-option
          *ngFor="
            let partner of expenseOperationModalComponentService.activeAssociateCeoDirectorPartners$ | async;
            trackBy: 'id' | trackBy
          "
          [value]="partner.id"
        >
          {{ partner.fullName }}
        </dougs-select-option>
      </dougs-select>
      <span *ngIf="formService.isControlInvalid(expenseOperationModalComponentService.partner)" dougsFormFieldError>
        <span *ngIf="expenseOperationModalComponentService.partner.hasError('required')">Ce champ est requis</span>
      </span>
    </dougs-form-field>
    <dougs-form-field *ngIf="!expenseOperationModalComponentService.data?.operation">
      <label dougsFormFieldLabel for="memo">Mémo</label>
      <input id="memo" type="text" formControlName="memo" dougsFormFieldControl />
    </dougs-form-field>
    <div class="files mt-8" *ngIf="!expenseOperationModalComponentService.data?.operation">
      <div class="file-list">
        <dougs-file-input
          (uploadFiles)="expenseOperationModalComponentService.onUploadFiles($event)"
          [shouldAddVendorInvoice]="true"
          (attachFromVendorInvoice)="
            expenseOperationModalComponentService.openVendorInvoiceModal(
              expenseOperationModalComponentService.data?.operation
            )
          "
        ></dougs-file-input>
        <dougs-source-document-pill
          *ngFor="
            let sourceDocumentAttachment of expenseOperationModalComponentService.allSourceDocumentAttachments$();
            trackBy: 'id' | trackBy
          "
          [sourceDocumentAttachment]="sourceDocumentAttachment"
          [canDelete]="true"
          (deleteSourceDocumentAttachment)="
            expenseOperationModalComponentService.onDeleteSourceDocumentAttachment($event)
          "
        ></dougs-source-document-pill>
      </div>
    </div>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" (click)="expenseOperationModalComponentService.closeModal()">Annuler</dougs-button>
    <dougs-button
      *ngIf="
        expenseOperationModalComponentService.data?.operation &&
        !expenseOperationModalComponentService.data?.operation?.manuallyLocked
      "
      color="delete"
      (click)="expenseOperationModalComponentService.removeExpenseOperation()"
    >
      Supprimer la note de frais
    </dougs-button>
    <dougs-button type="submit">
      {{ expenseOperationModalComponentService.data?.operation ? 'Modifier' : 'Ajouter' }}
    </dougs-button>
  </div>
</form>
<dougs-loader-fullpage *ngIf="expenseOperationModalComponentService.isLoading$()">
  {{ expenseOperationModalComponentService.data?.operation ? 'Modification' : 'Création' }} en cours...
</dougs-loader-fullpage>
