import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalCloseDirective, ModalContentDirective, ModalTitleDirective, PanelInfoComponent } from '@dougs/ds';
import { SelectCandidateSupplierComponentService } from '../../services/select-candidate-supplier.component.service';

@Component({
  selector: 'dougs-select-candidate-supplier',
  templateUrl: './select-candidate-supplier.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, CommonModule, PanelInfoComponent],
  providers: [SelectCandidateSupplierComponentService],
})
export class SelectCandidateSupplierComponent {
  constructor(public readonly selectCandidateSupplierComponentService: SelectCandidateSupplierComponentService) {}
}
