import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoutingPipe, URL } from '@dougs/core/routing';
import { ModalCloseDirective, ModalContentDirective, ModalTitleDirective, PanelInfoComponent } from '@dougs/ds';
import { PaymentsProcessor } from '@dougs/ecommerce/dto';
import { SelectCandidatePaymentsProcessorComponentService } from '../../services/select-candidate-payments-processor.component.service';

@Component({
  selector: 'dougs-select-candidate-payments-processor',
  templateUrl: './select-candidate-payments-processor.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    CommonModule,
    PanelInfoComponent,
    RouterModule,
    RoutingPipe,
  ],
  providers: [SelectCandidatePaymentsProcessorComponentService],
})
export class SelectCandidatePaymentsProcessorComponent {
  protected readonly URL = URL;

  constructor(
    public readonly selectCandidatePaymentsProcessorComponentService: SelectCandidatePaymentsProcessorComponentService,
  ) {}

  trackByValue(_: number, paymentsProcessor: PaymentsProcessor): number {
    return paymentsProcessor.id;
  }
}
