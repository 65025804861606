<ng-container *ngIf="operationEcommerceService.salesChannelsByOperation$ | async"></ng-container>
<div class="operation-breakdowns__container py-16" [ngClass]="{ 'py-16': !isCompact, 'pb-16 pt-8': isCompact }">
  <ng-container
    *ngIf="
      (operationDetailsComponentService.canSeeOldEcommerceDispatchOperation$ | async) ||
        !isSalesChannelEcommerceOperation;
      else ecommerceOperationDetails
    "
  >
    <div [ngClass]="{ 'px-72': !noPadding && !isCompact, 'px-8': noPadding, 'px-16': isCompact }">
      <dougs-panel-info
        [ngClass]="{ 'mt-8 mb-24 mr-80 ml-80': !isCompact }"
        [small]="isCompact"
        *ngIf="operation?.description"
      >
        {{ operation.description }}
      </dougs-panel-info>
      <dougs-operation-ecommerce-dispatch-files
        *ngIf="shouldShowEcommerceDispatchFile"
        [operation]="operation"
        [salesChannels]="salesChannelState.salesChannels$ | async"
      ></dougs-operation-ecommerce-dispatch-files>
    </div>
    <div *ngFor="let section of operation?.sections; let i = index; trackBy: trackBySection">
      <div
        [ngClass]="{ 'px-72': !noPadding && !isCompact, 'px-8': noPadding, 'px-16': isCompact }"
        class="mb-16 mt-8 section-title"
        *ngIf="operation?.sections?.length > 1"
      >
        <h3>{{ i + 1 }}. {{ section.label }}</h3>
        <p class="small section-description">{{ section.description }}</p>
      </div>
      <ng-container *ngFor="let breakdown of groupedBreakdowns[section.id]; trackBy: trackByBreakdown">
        <div
          *ngIf="breakdownService.shouldShowBreakdown(breakdown)"
          [ngClass]="{ 'px-72': !noPadding && !isCompact, 'px-8': noPadding, 'px-16': isCompact }"
          class="pt-8 pb-4"
        >
          <dougs-operation-breakdown
            [forceEditable]="forceEditable"
            [operation]="operation"
            [section]="section"
            [breakdown]="breakdown"
            [isCompact]="isCompact"
            (emitOperationAction)="emitOperationAction.emit($event)"
          ></dougs-operation-breakdown>
        </div>
      </ng-container>
      <div
        *ngIf="showAddBreakdown"
        [ngClass]="{ 'px-72': !noPadding && !isCompact, 'px-8': noPadding, 'px-16 compact': isCompact }"
        class="operation-breakdowns__add-breakdown bold"
        (click)="operationDetailsComponentService.onAddBreakdown(operation, section.id)"
      >
        + Ajouter un détail
      </div>
      <div
        [ngClass]="{ 'px-72': !noPadding && !isCompact, 'px-8': noPadding, 'px-16': isCompact }"
        class="mt-8 mb-8"
        *ngIf="section.id === 'main'"
      >
        <dougs-divider></dougs-divider>
      </div>
      <div
        [ngClass]="{ 'px-72': !noPadding && !isCompact, 'px-8': noPadding, 'px-16 compact': isCompact }"
        class="operation-breakdowns__total__container"
      >
        <ng-container *ngIf="!operation.allowUnbalanced && section.id === 'main'">
          <i *ngIf="operation.isTotalAmountValid; else totalInvalid" class="fas fa-check-circle valid-total"></i>
          <ng-template #totalInvalid>
            <i
              class="fas fa-exclamation-circle invalid-total"
              dougsTooltip="Le total des détails n'est pas égal au total de l'opération."
            ></i>
          </ng-template>
        </ng-container>
        <span *ngIf="section.id === 'main'" class="operation-breakdowns__total__amount bold ml-8">
          {{ totalAmountExcludingFee | currency: 'EUR' }} TTC
        </span>
        <span
          *ngIf="section.id !== 'main' && groupedBreakdowns[section.id]"
          class="operation-breakdowns__total__amount bold ml-8"
        >
          {{ operationService.getTotalSectionAmountComputed(groupedBreakdowns[section.id]) | currency: 'EUR' }} TTC
        </span>
      </div>
      <div class="mt-8 mb-8" *ngIf="section.id !== 'main'">
        <dougs-divider></dougs-divider>
      </div>
    </div>
  </ng-container>
  <ng-template #ecommerceOperationDetails>
    <dougs-ecommerce-operation-details
      [ecommerceSales]="ecommerceSaleStateService.sales$ | async"
      [operation]="operation"
      [shouldShowEcommerceDispatchFile]="shouldShowEcommerceDispatchFile"
    ></dougs-ecommerce-operation-details>
  </ng-template>
  <dougs-operation-files
    *ngIf="!isSalesChannelEcommerceOperation || (ecommerceSaleStateService.sales$ | async)"
    [ngClass]="{
      'px-72': !noPadding && !isSalesChannelEcommerceOperation && !isCompact,
      'px-8': noPadding && !isSalesChannelEcommerceOperation,
      'px-24': isSalesChannelEcommerceOperation && !isCompact,
      'px-16': isCompact
    }"
    class="mt-16"
    [operation]="operation"
    [enableFileAnimation]="enableFileAnimation"
    [isInOperationListModal]="isInOperationListModal"
    [mixpanelModalName]="mixpanelModalName"
    [disableReceiptMatching]="disableReceiptMatching"
  ></dougs-operation-files>
  <div
    class="operation-detail__admin mt-16 mb-8"
    [ngClass]="{ 'mx-0 py-24': noPadding, 'mx-72 py-24': !noPadding && !isCompact, 'mx-16': isCompact }"
    *ngIf="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin"
  >
    <dougs-operation-details-accounting-lines
      *ngIf="operation?.accountingLines?.length"
      [accountingLines]="operation.accountingLines"
      [isCompact]="isCompact"
    ></dougs-operation-details-accounting-lines>
    <dougs-operation-details-actions
      [shouldShowDate]="shouldShowDate"
      [operation]="operation"
      [isCompact]="isCompact"
    ></dougs-operation-details-actions>
  </div>
</div>
