import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { TooltipDirective } from '@dougs/ds';
import { Breakdown, Operation } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
import { OperationAmountComponentService } from '../../../../services/operation-amount.component.service';
import { OperationVatComponent } from '../operation-vat/operation-vat.component';

@Component({
  selector: 'dougs-operation-amount',
  templateUrl: './operation-amount.component.html',
  styleUrls: ['./operation-amount.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TooltipDirective, OperationVatComponent],
  providers: [OperationAmountComponentService],
})
export class OperationAmountComponent {
  isDefaultOperation = false;
  mainBreakdown: Breakdown | null = null;
  amountExcludingFee = 0;
  shouldShowDifferentCurrency = false;
  shouldShowOriginalCurrency = false;

  @Input()
  @HostBinding('class.compact')
  isCompact = false;

  private _operation!: Operation;

  get operation(): Operation {
    return this._operation;
  }

  @Input()
  set operation(operation: Operation) {
    this._operation = operation;
    this.isDefaultOperation = this.operationService.isDefaultOperation(operation);
    this.mainBreakdown = this.operationService.getMainBreakdown(operation);
    this.amountExcludingFee = this.operationService.getAmountExcludingFee(operation);
    this.shouldShowDifferentCurrency = this.operationAmountComponentService.shouldShowDifferentCurrency(operation);
    this.shouldShowOriginalCurrency = this.operationAmountComponentService.shouldShowOriginalCurrency(operation);
  }

  constructor(
    private readonly operationService: OperationService,
    private readonly operationAmountComponentService: OperationAmountComponentService,
  ) {}
}
