<div dougsModalTitle>
  <h6>Historique</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent>
  <dougs-loader *ngIf="operationChangesHistoryModalComponentService.isLoading"></dougs-loader>
  <ng-container
    *ngIf="
      !operationChangesHistoryModalComponentService.isLoading &&
        operationChangesHistoryModalComponentService.operationHistoryChanges?.length > 0;
      else blankState
    "
  >
    <div
      class="changes-history"
      *ngFor="
        let changeHistory of operationChangesHistoryModalComponentService.operationHistoryChanges;
        trackBy: 'id' | trackBy
      "
    >
      <div class="changes-history__title mb-8">
        <dougs-avatar
          class="mr-8"
          size="small"
          [image]="
            changeHistory?.user?.profile
              | avatarUrl
                : 'https://res.cloudinary.com/dougs/image/upload/w_256/l_text:Roboto_150:RO,co_white/v1484600002/lavanda_kfg5h7.png'
          "
        ></dougs-avatar>
        <span>
          {{ changeHistory.userId ? changeHistory.user.profile.fullName : 'Le robot' }} a mis à jour le
          {{ changeHistory.createdAt | date: 'dd/MM/yyyy HH:mm' }} le model {{ changeHistory.modelName }} avec l'id
          {{ changeHistory.modelId }}
        </span>
        <span *ngIf="operationChangesHistoryModalComponentService.hasBeenCreated(changeHistory)">
          (Il s'agit d'une création)
        </span>
        <span *ngIf="operationChangesHistoryModalComponentService.hasBeenRemoved(changeHistory)">
          (Il s'agit d'une suppression)
        </span>
      </div>
      <div class="table mt-8 mb-8" *ngIf="changeHistory.current && objectKeys(changeHistory.current)?.length > 0">
        <table>
          <thead>
            <tr>
              <th>Données</th>
              <th>Précédente valeur</th>
              <th>Nouvelle valeur</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let key of objectKeys(changeHistory.current)">
              <td>{{ key }}</td>
              <td>{{ changeHistory.previous[key] !== null ? (changeHistory.previous[key] | json) : '' }}</td>
              <td>{{ changeHistory.current[key] !== null ? (changeHistory.current[key] | json) : '' }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>
  <ng-template #blankState>
    <div *ngIf="!operationChangesHistoryModalComponentService.isLoading">
      <dougs-blank-state>
        <span class="blank-state-label">Aucune modification pour le moment</span>
      </dougs-blank-state>
    </div>
  </ng-template>
</div>
<div dougsModalFooter>
  <dougs-button color="secondary" dougsModalClose>Annuler</dougs-button>
</div>
