<ng-container *ngIf="emitOperationAction$ | async"></ng-container>
<ng-container *ngIf="operationBreakdownComponentService.onRemoveBreakdown$ | async"></ng-container>

<ng-container *ngIf="operationBreakdownComponentService.breakdown$ | async as breakdown">
  <ng-container *ngIf="operationBreakdownComponentService.isBreakdownVisible$ | async">
    <div
      class="operation-breakdown__metadata"
      *ngIf="(operationBreakdownComponentService.showBreakdownMetadata$ | async) && !isCompact"
    >
      <div class="operation-breakdown__metadata__wording">{{ breakdown.wording }}</div>
      <div class="operation-breakdown__metadata__detail" *ngIf="!breakdown.metadata.additionalWording">
        {{ breakdown.quantity }}
        &#10005; {{ breakdown.unitAmount | currency: 'EUR' }}
        <span
          *ngIf="breakdown.metadata.afterUnitAmountText"
          [innerHTML]="breakdown.metadata.afterUnitAmountText"
        ></span>
        <span *ngIf="!breakdown.metadata.afterUnitAmountText && breakdown.metadata.depreciationPercent">
          (dépr.&nbsp;{{ breakdown.metadata.depreciationPercent }}%)
        </span>
      </div>
      <div class="operation-breakdown__metadata__detail" *ngIf="breakdown.metadata.additionalWording">
        {{ breakdown.metadata.additionalWording }}
      </div>
    </div>
    <div *ngIf="!isCompact" class="operation-breakdown__line mb-8">
      <div>
        <dougs-category
          class="operation-breakdown__category"
          dougsFormFieldControl
          [hasAlreadySendHelp]="
            operationBreakdownComponentService.operation?.metadata?.categorizationHelpStatus ===
            OperationCategorizationHelpStatus.PENDING
          "
          [ngModel]="operationBreakdownComponentService.currentCategory"
          [toFilled]="operationBreakdownComponentService.currentCategory?.id === -1"
          [categoryType]="'operation'"
          [operationId]="operationBreakdownComponentService.operation.id"
          [breakdownId]="breakdown.id"
          [breakdownIsInbound]="breakdown.isInbound"
          [disabled]="operationBreakdownComponentService.disabledModification$ | async"
          [wrap]="true"
          (click)="operationMetricsService.trackCategoryModalOpening()"
          (ngModelChange)="operationBreakdownComponentService.onCategoryChange($event)"
        ></dougs-category>
        <div class="operation-breakdown__associations mt-8">
          <div *ngFor="let association of breakdown?.associations" class="mb-4">
            <dougs-operation-breakdown-association
              [operation]="operationBreakdownComponentService.operation"
              [breakdown]="breakdown"
              *ngIf="association.show"
              [association]="association"
            ></dougs-operation-breakdown-association>
          </div>
        </div>
      </div>
      <div class="operation-breakdown__pills" *ngIf="breakdown.isCounterpart || breakdown.isRefund">
        <dougs-pill type="success">{{ breakdown.isCounterpart ? 'contrepartie' : 'remboursement' }}</dougs-pill>
      </div>
      <div class="operation-breakdown__amount">
        <div class="operation-breakdown__amount__container">
          <dougs-form-field [noMargin]="true" [autoWidth]="true">
            <input
              class="operation-breakdown__amount__container__input"
              type="text"
              dougsFormFieldControl
              [ngModel]="operationBreakdownComponentService.signedAmount | numberToString"
              [disabled]="
                (operationBreakdownComponentService.disabledModification$ | async) && !breakdown.isAmountEditable
              "
              [ngModelOptions]="{ updateOn: 'blur' }"
              (ngModelChange)="operationBreakdownComponentService.onAmountChange($event)"
              dougsBlurOnEnter
            />
            <i dougsFormFieldSuffix class="fal fa-euro-sign"></i>
          </dougs-form-field>
          <dougs-operation-vat
            *ngIf="breakdown.hasVat"
            class="operation-breakdown__vat"
            [forceDisabled]="operationBreakdownComponentService.disabledModification$ | async"
            [operation]="operationBreakdownComponentService.operation"
            [breakdown]="breakdown"
          ></dougs-operation-vat>
        </div>
        <div class="remove-button__container">
          <i
            *ngIf="operationBreakdownComponentService.showRemoveBreakdownButton$ | async"
            class="fal fa-times ml-16 remove-button"
            (click)="operationBreakdownComponentService.onRemoveBreakdown()"
          ></i>
        </div>
      </div>
    </div>
    <p *ngIf="showCategoryLabel" class="small mb-4">Catégorie</p>
    <div class="operation-breakdown__compact-line" *ngIf="isCompact">
      <div class="operation-breakdown__compact-line__left">
        <div
          *ngIf="operationBreakdownComponentService.showBreakdownMetadata$ | async"
          class="operation-breakdown__metadata__wording compact"
        >
          {{ breakdown.wording }}
        </div>
        <dougs-category
          dougsFormFieldControl
          [hasAlreadySendHelp]="
            operationBreakdownComponentService.operation?.metadata?.categorizationHelpStatus ===
            OperationCategorizationHelpStatus.PENDING
          "
          [ngModel]="operationBreakdownComponentService.currentCategory"
          [toFilled]="operationBreakdownComponentService.currentCategory?.id === -1"
          [isCompact]="true"
          [categoryType]="'operation'"
          [operationId]="operationBreakdownComponentService.operation.id"
          [breakdownId]="breakdown.id"
          [breakdownIsInbound]="breakdown.isInbound"
          [disabled]="operationBreakdownComponentService.disabledModification$ | async"
          [wrap]="true"
          (click)="operationMetricsService.trackCategoryModalOpening()"
          (ngModelChange)="operationBreakdownComponentService.onCategoryChange($event)"
        ></dougs-category>
        <div
          class="operation-breakdown__pills"
          [ngClass]="{ compact: isCompact }"
          *ngIf="breakdown.isCounterpart || breakdown.isRefund"
        >
          <dougs-pill type="success">{{ breakdown.isCounterpart ? 'contrepartie' : 'remboursement' }}</dougs-pill>
        </div>
        <ng-container *ngIf="operationBreakdownComponentService.showBreakdownMetadata$ | async">
          <div class="operation-breakdown__metadata__detail compact" *ngIf="!breakdown.metadata.additionalWording">
            {{ breakdown.quantity }}
            &#10005; {{ breakdown.unitAmount | currency: 'EUR' }}
            <span
              *ngIf="breakdown.metadata.afterUnitAmountText"
              [innerHTML]="breakdown.metadata.afterUnitAmountText"
            ></span>
            <span *ngIf="!breakdown.metadata.afterUnitAmountText && breakdown.metadata.depreciationPercent">
              (dépr.&nbsp;{{ breakdown.metadata.depreciationPercent }}%)
            </span>
          </div>
          <div class="operation-breakdown__metadata__detail compact" *ngIf="breakdown.metadata.additionalWording">
            {{ breakdown.metadata.additionalWording }}
          </div>
        </ng-container>
        <div class="operation-breakdown__compact-line__left__amount">
          <div class="operation-breakdown__amount">
            <div class="operation-breakdown__amount__container compact">
              <dougs-form-field size="small" [noMargin]="true" [autoWidth]="true" [isCompact]="isCompact">
                <input
                  class="operation-breakdown__amount__container__input"
                  type="text"
                  dougsFormFieldControl
                  [ngModel]="operationBreakdownComponentService.signedAmount | numberToString"
                  [disabled]="
                    (operationBreakdownComponentService.disabledModification$ | async) || !breakdown.isAmountEditable
                  "
                  [ngModelOptions]="{ updateOn: 'blur' }"
                  (ngModelChange)="operationBreakdownComponentService.onAmountChange($event)"
                  dougsBlurOnEnter
                />
                <i dougsFormFieldSuffix class="fal fa-euro-sign"></i>
              </dougs-form-field>
              <dougs-operation-vat
                *ngIf="breakdown.hasVat"
                class="operation-breakdown__vat"
                [operation]="operationBreakdownComponentService.operation"
                [forceDisabled]="operationBreakdownComponentService.disabledModification$ | async"
                [breakdown]="breakdown"
                [isCompact]="isCompact"
              ></dougs-operation-vat>
            </div>
          </div>
        </div>
        <div class="operation-breakdown__associations compact mt-8">
          <div *ngFor="let association of breakdown?.associations" class="mb-4">
            <dougs-operation-breakdown-association
              [operation]="operationBreakdownComponentService.operation"
              [breakdown]="breakdown"
              *ngIf="association.show"
              [association]="association"
            ></dougs-operation-breakdown-association>
          </div>
        </div>
      </div>
      <div class="remove-button__container">
        <i
          *ngIf="operationBreakdownComponentService.showRemoveBreakdownButton$"
          class="fal fa-times ml-16 remove-button"
          (click)="operationBreakdownComponentService.onRemoveBreakdown()"
        ></i>
      </div>
    </div>
  </ng-container>
</ng-container>
