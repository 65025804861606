import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { ModalService, TrackByPipe } from '@dougs/ds';
import { EcommerceSale, EcommerceSaleSection, EcommerceSaleSubSection } from '@dougs/ecommerce/dto';
import { Operation } from '@dougs/operations/dto';
import { OperationEcommerceMetricsService } from '../../../../../services/operation-ecommerce-metrics.service';
import { OperationEcommerceComponentService } from '../../../../../services/operation-ecommerce.component.service';
import { EcommerceSalesLineComponent } from '../ecommerce-sales-line/ecommerce-sales-line.component';

@Component({
  selector: 'dougs-ecommerce-sub-section',
  templateUrl: './ecommerce-sub-section.component.html',
  styleUrls: ['./ecommerce-sub-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, TrackByPipe, EcommerceSalesLineComponent],
  animations: [
    trigger('slideOut', [
      transition(':leave', [
        style({ height: '*', visibility: 'visible', opacity: 0.6 }),
        animate('200ms ease-in', style({ height: '0', visibility: 'hidden', opacity: 0 })),
      ]),
    ]),
    trigger('slideIn', [
      transition(':enter', [
        style({ height: '0', visibility: 'hidden', opacity: 0 }),
        animate('200ms ease-in', style({ height: '*', visibility: 'visible', opacity: 1 })),
      ]),
    ]),
  ],
})
export class EcommerceSubSectionComponent {
  private _sale!: EcommerceSale;
  get sale(): EcommerceSale {
    return this._sale;
  }

  @Input()
  set sale(sale: EcommerceSale) {
    this._sale = sale;
    this.saleAmount = this.sale.isRefund ? -this.sale.amount : this.sale.amount;
  }

  @Input() hiddenSubSectionKeys!: string[];
  @Input() subSection!: EcommerceSaleSubSection;
  @Input() operation!: Operation;

  @Input() section!: EcommerceSaleSection;
  @Input() disabled!: boolean;
  @Input() isLast = false;

  saleAmount!: number;

  public showClose = false;
  public isEcommerceSaleSubSectionVisible = true;

  constructor(
    public readonly operationEcommerceComponentService: OperationEcommerceComponentService,
    public readonly modalService: ModalService,
    private readonly operationEcommerceMetricsService: OperationEcommerceMetricsService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  async onRemoveSubSection(section: EcommerceSaleSection, subSection: EcommerceSaleSubSection): Promise<void> {
    this.operationEcommerceMetricsService.trackEcommerceOperationDispatchOriginLocationRemoved(
      this.section.sectionId,
      this.subSection.subSectionId,
      this.operation,
      this.operationEcommerceComponentService.salesChannel,
    );
    this.isEcommerceSaleSubSectionVisible = false;
    const subSectionRemoved: boolean = await this.operationEcommerceComponentService.deleteSubSection(
      this.operation,
      section,
      subSection,
    );
    if (!subSectionRemoved) {
      this.isEcommerceSaleSubSectionVisible = true;
      this.cdr.markForCheck();
    }
  }
}
