<form
  class="form-kilometric-indemnity"
  (ngSubmit)="kilometricIndemnityModalComponentService.onSubmit()"
  [formGroup]="kilometricIndemnityModalComponentService.formGroup"
  *ngIf="kilometricIndemnityModalComponentService.formGroup"
>
  <div dougsModalTitle>
    <h6>
      {{
        kilometricIndemnityModalComponentService.data?.operation
          ? 'Editer une indemnité kilométrique'
          : 'Ajouter une indemnité kilométrique'
      }}
    </h6>
    <i (click)="kilometricIndemnityModalComponentService.closeModal()" class="fal fa-times pointer"></i>
  </div>
  <div dougsModalContent>
    <dougs-panel-info class="mb-16">
      Les indemnités kilométriques couvrent les
      <b>frais relatifs à l'usage d'un véhicule personnel lors de déplacements professionnels</b>
      . Ces frais prennent en compte l'usure générale, soit :
      <ul>
        <li>Carburant</li>
        <li>Nettoyage</li>
        <li>L'entretien et l'assurance</li>
      </ul>
      En savoir plus :
      <a
        rel="noopener"
        href="https://aide.dougs.fr/fr/articles/2158966-tutoriel-video-dougs-passer-vos-frais-de-vehicule-sur-votre-entreprise"
        target="_blank"
      >
        Passer vos frais de véhicules sur votre entreprise
      </a>
    </dougs-panel-info>
    <dougs-panel-info
      *ngIf="kilometricIndemnityModalComponentService.date?.hasError('companyLockDate')"
      [type]="'error'"
      class="mb-16"
    >
      <span>{{ kilometricIndemnityModalComponentService.date?.getError('companyLockDate') }}</span>
    </dougs-panel-info>
    <div class="form-field-line">
      <dougs-input-datepicker formControlName="date"></dougs-input-datepicker>
      <dougs-form-field>
        <label dougsFormFieldLabel for="kilometers">Distance (km)</label>
        <input
          placeholder="0"
          min="1"
          id="kilometers"
          type="number"
          formControlName="kilometers"
          dougsFormFieldControl
        />
        <span
          *ngIf="formService.isControlInvalid(kilometricIndemnityModalComponentService.kilometers)"
          dougsFormFieldError
        >
          <span *ngIf="kilometricIndemnityModalComponentService.kilometers.hasError('required')">
            Ce champ est requis
          </span>
          <span *ngIf="kilometricIndemnityModalComponentService.kilometers.hasError('min')">
            Ce champ doit être un nombre supérieur à 0
          </span>
        </span>
      </dougs-form-field>
    </div>
    <dougs-form-field [noMargin]="true">
      <label dougsFormFieldLabel for="memo">Memo</label>
      <input id="memo" type="text" formControlName="memo" dougsFormFieldControl />
    </dougs-form-field>
  </div>
  <div dougsModalFooter>
    <dougs-button color="secondary" (click)="kilometricIndemnityModalComponentService.closeModal()"
      >Annuler</dougs-button
    >
    <dougs-button
      *ngIf="
        kilometricIndemnityModalComponentService.data?.operation &&
        !kilometricIndemnityModalComponentService.data?.operation?.manuallyLocked
      "
      color="delete"
      (click)="kilometricIndemnityModalComponentService.removeKilometricIndemnity()"
    >
      Supprimer l'indemnité kilométrique
    </dougs-button>
    <dougs-button type="submit">
      {{ kilometricIndemnityModalComponentService.data?.operation ? 'Modifier' : 'Ajouter' }}
    </dougs-button>
  </div>
</form>
<dougs-loader-fullpage *ngIf="kilometricIndemnityModalComponentService.isLoading">
  {{ kilometricIndemnityModalComponentService.data?.operation ? 'Modification' : 'Création' }} en cours...
</dougs-loader-fullpage>
