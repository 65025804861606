import { Inject, Injectable } from '@angular/core';
import { MetricsService } from '@dougs/core/metrics';
import { MODAL_DATA, ModalRef } from '@dougs/ds';
import { AssociationSlotCandidate, AssociationSlotCandidates, Operation } from '@dougs/operations/dto';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class SelectCandidatePaymentsProcessorComponentService {
  constructor(
    @Inject(MODAL_DATA)
    public data: { candidates: AssociationSlotCandidates; operation: Operation; enableSearch: boolean },
    private readonly modalRef: ModalRef<Record<string, string>, AssociationSlotCandidate>,
    public readonly userStateService: UserStateService,
    public readonly metricsService: MetricsService,
  ) {}

  selectItem(item: Record<string, string>): void {
    this.modalRef.close(item);
  }

  closeModal(): void {
    this.metricsService.pushMixpanelEvent('e-Commerce Payments processor created', {
      'CTA Location': 'Accounting Module',
    });
    this.modalRef.close();
  }
}
