<div *ngIf="operation && ecommerceSales; else emptyState" class="overview__container p-16 mb-24">
  <div class="overview__label">
    <h5>{{ ecommerceSales?.salesChannelName }}</h5>
    <div class="overview__label__date-sync mt-4">
      <p class="small">{{ operation.date | date: 'MMMM yyyy' | titlecase }}</p>
      <ng-container *ngIf="ecommerceOperationSynchronizationService.isSynchronizable$ | async">
        <ng-container *ngIf="ecommerceOperationSynchronizationService.isSynchronized$ | async; else notSync">
          <dougs-pill type="success" class="ml-8">
            <i class="fal fa-check-circle mr-4"></i>
            Boutique synchronisée
          </dougs-pill>
          <dougs-ecommerce-beta-pill class="ml-8" />
        </ng-container>
        <ng-template #notSync>
          <dougs-pill type="warning" class="ml-8">
            <i class="fal fa-circle-xmark mr-4"></i>
            Boutique non synchronisée
          </dougs-pill>
        </ng-template>
      </ng-container>
    </div>
  </div>
  <div class="overview__amount">
    <div class="overview__amount__column">
      <h5 class="color-primary">{{ ecommerceSales?.totalAmount ?? 0 | currency: 'EUR' }}</h5>
      <p class="small">Total des ventes TTC</p>
    </div>
    <div class="overview__amount__column">
      <h5>{{ ecommerceSales?.totalVatAmount ?? 0 | currency: 'EUR' }}</h5>
      <p class="small">Total des TVA encaissées</p>
    </div>
  </div>
</div>

<ng-template #emptyState>
  <div class="overview__container p-16 mb-24">
    <div class="overview__label">
      <div class="skeleton title"></div>
      <div class="skeleton subtitle mt-4"></div>
    </div>
    <div class="overview__amount">
      <div class="overview__amount__column">
        <div class="skeleton title"></div>
        <div class="skeleton subtitle mt-4"></div>
      </div>
      <div class="overview__amount__column">
        <div class="skeleton title"></div>
        <div class="skeleton subtitle mt-4"></div>
      </div>
    </div>
  </div>
</ng-template>
