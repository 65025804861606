<ng-container *ngIf="ecommerceSales && !(operationEcommerceComponentService.isLoading$ | async); else loader">
  <ng-container *ngIf="operationEcommerceComponentService.updateEcommerceSale$ | async"></ng-container>
  <ng-container *ngIf="operationEcommerceComponentService.salesChannelsByOperation$ | async"></ng-container>
  <div
    *ngIf="ecommerceOperationSynchronizationService.shouldShowSyncBanner$ | async"
    class="synchronize-banner p-16 mb-24"
    @foldInOut
  >
    <dougs-ecommerce-beta-pill [isLight]="true" class="mr-8" />
    <img src="images/marketplaces/shopify.svg" alt="Shopify logo" height="20px" class="mr-8" />
    <h6 (click)="ecommerceOperationSynchronizationService.openSynchronizationModal()" class="color-white pointer">
      Synchronisez votre boutique et laissez la magie opérer...
    </h6>
  </div>
  <div class="ecommerce-container px-24">
    <dougs-panel-info class="mb-16" *ngIf="operationEcommerceComponentService.shouldShowTutorialPanel$ | async">
      <i class="fal fa-lightbulb-on color-primary mr-4"></i>
      <b>Besoin d’aide sur votre ventilation ?</b> Laissez-vous guider dans ce
      <a
        rel="noopener"
        class="bold"
        [attr.href]="operationEcommerceComponentService.tutorialUrl$ | async"
        target="_blank"
        (click)="operationEcommerceComponentService.onResourceLinkClick(operation)"
        >tutoriel</a
      >.
    </dougs-panel-info>
    <dougs-ecommerce-overview [ecommerceSales]="ecommerceSales" [operation]="operation"></dougs-ecommerce-overview>
    <div class="ecommerce-container__main-buttons">
      <dougs-pill
        *ngIf="
          (ecommerceOperationSynchronizationService.isSynchronized$ | async) &&
          (userStateService.loggedInUser$ | async)?.isAccountantOrAdmin
        "
        (click)="ecommerceOperationSynchronizationService.refreshShopifyDispatch()"
        type="admin"
        class="mr-8 pointer"
      >
        <i
          [class.ecommerce-container__main-buttons__refresh-icon--rotate]="
            ecommerceOperationSynchronizationService.isRefreshingOperation$()
          "
          class="ecommerce-container__main-buttons__refresh-icon color-purple-700 fal fa-redo mr-8"
        ></i>
        Rafraîchir
      </dougs-pill>
      <dougs-button
        *ngIf="!operation.validated"
        color="secondary"
        (click)="operationEcommerceComponentService.openDeleteAllConfirmation(operation)"
      >
        <i class="fal fa-times mr-4"></i>
        Effacer tout
      </dougs-button>
    </div>
    <dougs-operation-ecommerce-dispatch-files
      *ngIf="shouldShowEcommerceDispatchFile"
      [operation]="operation"
      [salesChannels]="salesChannelStateService.salesChannels$ | async"
    ></dougs-operation-ecommerce-dispatch-files>
    <dougs-ecommerce-shopify-operation-panel *ngIf="operation.metadata.shopifySynchStatus" class="mt-24" @foldInOut />
    <div class="mt-24 mb-48" *ngFor="let section of ecommerceSales.sections; trackBy: 'sectionId' | trackBy">
      <dougs-ecommerce-section-title
        [section]="section"
        [operation]="operation"
        [salesChannel]="operationEcommerceComponentService.salesChannelsByOperation$ | async"
      ></dougs-ecommerce-section-title>
      <ng-container *ngFor="let subSection of section.subSections; let last = last; trackBy: 'subSectionId' | trackBy">
        <dougs-ecommerce-sub-section
          @slideIn
          [subSection]="subSection"
          [operation]="operation"
          [section]="section"
          [disabled]="disabled"
          [isLast]="last"
        ></dougs-ecommerce-sub-section>
      </ng-container>
      <dougs-button
        (click)="operationEcommerceComponentService.onAddEcommerceSubSection(section, operation)"
        class="ml-24 mt-16"
        color="primary-light"
        *ngIf="section.addButton && !disabled"
      >
        <i class="fas fa-plus-circle mr-8 color-primary"></i>
        {{ section.addButton }}
      </dougs-button>
    </div>
  </div>
  <div class="px-24">
    <h3 class="mb-16">Vos justificatifs</h3>
    <dougs-panel-info type="primary-light">
      <p class="small color-primary-700">
        Attachez tous vos documents justificatifs tels que facture, rapport de vente, rapport de taxes...
      </p>
    </dougs-panel-info>
  </div>
</ng-container>

<ng-template #loader>
  <div class="loader px-24">
    <dougs-ecommerce-overview></dougs-ecommerce-overview>
    <div class="skeleton button mb-24"></div>
    <div *ngFor="let i of [1, 2, 3]; let isLast = last" [class.mb-32]="!isLast" class="section">
      <div class="skeleton title"></div>
      <div class="skeleton paragraph"></div>
      <div class="skeleton button"></div>
    </div>
  </div>
</ng-template>
