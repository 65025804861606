import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { ButtonComponent, LoaderComponent, PanelInfoComponent, PillComponent, TrackByPipe } from '@dougs/ds';
import { EcommerceSales } from '@dougs/ecommerce/dto';
import { EcommerceSaleStateService, SalesChannelStateService } from '@dougs/ecommerce/shared';
// eslint-disable-next-line @nx/enforce-module-boundaries
import { EcommerceBetaPillComponent } from '@dougs/ecommerce/ui';
import { Operation } from '@dougs/operations/dto';
import { UserStateService } from '@dougs/user/shared';
import { EcommerceOperationSynchronizationService } from '../../../../../services/ecommerce-operation-synchronization.service';
import { OperationEcommerceComponentService } from '../../../../../services/operation-ecommerce.component.service';
import { OperationEcommerceDispatchFilesComponent } from '../../operation-ecommerce-dispatch-files/operation-ecommerce-dispatch-files.component';
import { EcommerceOverviewComponent } from '../ecommerce-overview/ecommerce-overview.component';
import { EcommerceSectionTitleComponent } from '../ecommerce-section-title/ecommerce-section-title.component';
import { EcommerceShopifyOperationPanelComponent } from '../ecommerce-shopify-operation-panel/ecommerce-shopify-operation-panel.component';
import { EcommerceSubSectionComponent } from '../ecommerce-sub-section/ecommerce-sub-section.component';

@Component({
  selector: 'dougs-ecommerce-operation-details',
  templateUrl: './ecommerce-operation-details.component.html',
  styleUrls: ['./ecommerce-operation-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    PanelInfoComponent,
    ButtonComponent,
    LoaderComponent,
    OperationEcommerceDispatchFilesComponent,
    TrackByPipe,
    EcommerceOverviewComponent,
    EcommerceSubSectionComponent,
    EcommerceShopifyOperationPanelComponent,
    EcommerceSectionTitleComponent,
    PillComponent,
    EcommerceBetaPillComponent,
  ],
  providers: [EcommerceOperationSynchronizationService],
  animations: [
    trigger('slideIn', [
      transition(':enter', [
        style({ height: '0', visibility: 'hidden', opacity: 0 }),
        animate('200ms ease-out', style({ height: '*', visibility: 'visible', opacity: 1 })),
      ]),
    ]),
    trigger('foldInOut', [
      transition(':enter', [
        style({ height: 0, paddingTop: 0, paddingBottom: 0, marginTop: 0, overflow: 'hidden' }),
        animate('200ms ease-out', style({ height: '*', paddingTop: '*', paddingBottom: '*', marginTop: '*' })),
      ]),
      transition(':leave', [
        style({ overflow: 'hidden' }),
        animate('200ms ease-in', style({ height: 0, paddingTop: 0, paddingBottom: 0 })),
      ]),
    ]),
  ],
})
export class EcommerceOperationDetailsComponent implements OnDestroy {
  private _ecommerceSales!: EcommerceSales;

  get ecommerceSales(): EcommerceSales {
    return this._ecommerceSales;
  }

  @Input()
  set ecommerceSales(ecommerceSales: EcommerceSales) {
    this._ecommerceSales = ecommerceSales;
  }

  private _operation!: Operation;

  get operation(): Operation {
    return this._operation;
  }

  @Input()
  set operation(operation: Operation) {
    this._operation = operation;
    this.disabled = operation.validated && !this.forceEditable;
    this.operationEcommerceComponentService.setEcommerceOperation(operation);
    this.ecommerceOperationSynchronizationService.operation = operation;
  }

  disabled = false;

  @Input() shouldShowEcommerceDispatchFile!: boolean;
  @Input() forceEditable = false;

  constructor(
    public readonly salesChannelStateService: SalesChannelStateService,
    public readonly operationEcommerceComponentService: OperationEcommerceComponentService,
    public readonly ecommerceSaleStateService: EcommerceSaleStateService,
    public readonly ecommerceOperationSynchronizationService: EcommerceOperationSynchronizationService,
    public readonly userStateService: UserStateService,
  ) {}

  ngOnDestroy(): void {
    this.ecommerceSaleStateService.resetEcommerceSales();
  }
}
