<dougs-panel-info type="primary-light">
  <p class="small color-primary-700">{{ panelInfo.title }}</p>
  <ng-container *ngIf="panelInfo?.subTitles?.length"></ng-container>
  <p *ngFor="let subtitle of panelInfo?.subTitles; trackBy: 'label' | trackBy" class="tiny color-gray">
    <i
      class="fal mr-4"
      [ngClass]="{
        'color-primary fa-info-circle': subtitle.type === 'info',
        'color-warning fa-exclamation-triangle': subtitle.type === 'warning'
      }"
    ></i>
    {{ subtitle.label }}
  </p>
</dougs-panel-info>
