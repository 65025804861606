<div dougsModalTitle>
  <h6>{{ selectCandidatePaymentsProcessorComponentService.data.candidates.descriptor.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent class="modal-select-association-container">
  <p class="small" *ngIf="!selectCandidatePaymentsProcessorComponentService.data.candidates.items.length">
    Vous n'avez pas encore renseigné de moyen d'encaissement dans Dougs.
  </p>
  <ul class="select-candidate">
    <li
      *ngFor="let item of selectCandidatePaymentsProcessorComponentService.data.candidates.items; trackBy: trackByValue"
      class="p-8"
      (click)="selectCandidatePaymentsProcessorComponentService.selectItem({ id: item.value })"
    >
      <div>
        {{ item.label }}
      </div>
    </li>
  </ul>
  <dougs-panel-info class="mt-8">
    Votre service d'encaissement n’est pas dans la liste ? Créez-le dans un canal de vente depuis vos paramètres en
    <a
      [routerLink]="URL.SETTINGS | createUrl | async"
      (click)="selectCandidatePaymentsProcessorComponentService.closeModal()"
    >
      cliquant ici
    </a>
    .
  </dougs-panel-info>
</div>
