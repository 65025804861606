<ng-container *ngIf="selectCandidatePartnerComponentService.searchControlValueChanges$ | async"></ng-container>

<div dougsModalTitle>
  <h6>{{ selectCandidatePartnerComponentService.data.descriptor.title }}</h6>
  <i dougsModalClose class="fal fa-times"></i>
</div>
<div dougsModalContent class="modal-select-association-container">
  <dougs-form-field *ngIf="selectCandidatePartnerComponentService.data.items.length !== 0">
    <i class="fal fa-search" dougsFormFieldPrefix></i>
    <input
      type="text"
      [formControl]="selectCandidatePartnerComponentService.searchControl"
      dougsFormFieldControl
      placeholder="Rechercher une personne"
    />
  </dougs-form-field>
  <dougs-panel-info *ngIf="selectCandidatePartnerComponentService.data.items.length === 0">
    Aucune personne n'est paramétrée pour votre entreprise. Rendez-vous dans vos
    <a href="#" (click)="selectCandidatePartnerComponentService.showSettings($event)">paramètres</a>
    pour en ajouter/désigner une.
  </dougs-panel-info>
  <dougs-blank-state
    illustration="empty-search"
    *ngIf="(selectCandidatePartnerComponentService.candidates$ | async).length === 0"
  >
    <p class="small mt-16">Aucune personne ne correspond à votre recherche</p>
  </dougs-blank-state>
  <ul class="select-candidate">
    <li
      *ngFor="let item of selectCandidatePartnerComponentService.candidates$ | async"
      class="p-8"
      (click)="selectCandidatePartnerComponentService.selectItem(item)"
    >
      <dougs-avatar class="mr-16" [image]="item.iconUrl"></dougs-avatar>
      <div>
        {{ item.label }}
        <p class="tiny" *ngIf="item.isEnded && selectCandidatePartnerComponentService.shouldShowAllPartners">
          Personne sortie le {{ item.endDate | date: 'dd/MM/yyyy' }}
        </p>
      </div>
    </li>
  </ul>
  <p
    class="small mt-16"
    *ngIf="
      !selectCandidatePartnerComponentService.shouldShowAllPartners &&
      selectCandidatePartnerComponentService.hasEndedPartners
    "
  >
    Vous cherchez une personne sortie de l'entreprise&nbsp;?
    <a href="#" (click)="selectCandidatePartnerComponentService.showAllPartners($event)">
      Voir les personnes sorties de l'entreprise
    </a>
    .
  </p>
</div>
