import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  ButtonComponent,
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FileInputComponent,
  FilePillComponent,
  FormFieldComponent,
  InputDatepickerComponent,
  LabelFormFieldDirective,
  LoaderFullpageComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
  PanelInfoComponent,
  SelectComponent,
  SelectOptionComponent,
  SourceDocumentPillComponent,
  SuffixFormFieldDirective,
  TrackByPipe,
  VendorInvoicePillComponent,
} from '@dougs/ds';
import { PartnerStateService } from '@dougs/partners/shared';
import { ExpenseOperationModalComponentService } from '../../services/expense-operation-modal.component.service';
import { CategoryComponent } from '../category-modal/category.component';

@Component({
  selector: 'dougs-expense-operation-modal',
  templateUrl: './expense-operation-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    ModalFooterDirective,
    PanelInfoComponent,
    InputDatepickerComponent,
    FormFieldComponent,
    ControlFormFieldDirective,
    LabelFormFieldDirective,
    SuffixFormFieldDirective,
    ErrorFormFieldDirective,
    CategoryComponent,
    FileInputComponent,
    FilePillComponent,
    ButtonComponent,
    LoaderFullpageComponent,
    TrackByPipe,
    SelectComponent,
    SelectOptionComponent,
    VendorInvoicePillComponent,
    SourceDocumentPillComponent,
  ],
  providers: [ExpenseOperationModalComponentService],
})
export class ExpenseOperationModalComponent {
  constructor(
    public readonly formService: FormService,
    public readonly expenseOperationModalComponentService: ExpenseOperationModalComponentService,
    public readonly partnerStateService: PartnerStateService,
  ) {}
}
