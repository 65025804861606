import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'lengthWord',
  standalone: true,
})
export class LengthWordPipe implements PipeTransform {
  transform(value: string): boolean {
    return value.split(' ').some((val) => val.length > 20);
  }
}
