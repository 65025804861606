import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { CompanyStateService } from '@dougs/company/shared';
import { CompanyRefreshEstablishmentComponent } from '@dougs/company/ui';
import { ModalCloseDirective, ModalContentDirective, ModalTitleDirective } from '@dougs/ds';
import { SelectCandidateEstablishmentComponentService } from '../../services/select-candidate-establishment.component.service';

@Component({
  selector: 'dougs-select-candidate-establishment',
  templateUrl: './select-candidate-establishment.component.html',
  styleUrls: ['./select-candidate-establishment.component.scss'],
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    CommonModule,
    CompanyRefreshEstablishmentComponent,
  ],
  providers: [SelectCandidateEstablishmentComponentService],
})
export class SelectCandidateEstablishmentComponent {
  constructor(
    public readonly companyStateService: CompanyStateService,
    public readonly selectCandidateEstablishmentComponentService: SelectCandidateEstablishmentComponentService,
  ) {}
}
