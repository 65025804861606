import { Inject, Injectable } from '@angular/core';
import { MODAL_DATA } from '@dougs/ds';
import { ChangeHistory, Operation } from '@dougs/operations/dto';
import { OperationHistoryStateService } from '@dougs/operations/shared';

@Injectable()
export class OperationChangesHistoryModalComponentService {
  public operationHistoryChanges!: ChangeHistory[];
  public isLoading = true;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      operation: Operation;
    },
    private readonly operationHistoryStateService: OperationHistoryStateService,
  ) {}

  async refreshOperationHistoryChanges(): Promise<void> {
    this.isLoading = true;
    this.operationHistoryChanges = await this.operationHistoryStateService.getOperationHistoryChanges(
      this.data.operation,
    );
    this.isLoading = false;
  }

  public hasBeenCreated(change: ChangeHistory): boolean {
    return !Object.keys(change.previous).length && !!Object.keys(change.current).length;
  }

  public hasBeenRemoved(change: ChangeHistory): boolean {
    return !Object.keys(change.current).length && !!Object.keys(change.previous).length;
  }
}
