import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { SalesChannel } from '@dougs/ecommerce/dto';
import { Operation } from '@dougs/operations/dto';
import { OperationEcommerceMetricsService } from './operation-ecommerce-metrics.service';

@Injectable()
export class EcommerceSectionTitleComponentService {
  constructor(private readonly operationEcommerceMetricsService: OperationEcommerceMetricsService) {}

  private readonly isOpen: WritableSignal<boolean> = signal<boolean>(false);

  isOpen$: Signal<boolean> = this.isOpen.asReadonly();

  toggle(sectionId: string | undefined, operation: Operation, salesChannel: SalesChannel): void {
    this.isOpen.update((isOpen) => !isOpen);
    const action = this.isOpen() ? 'extend' : 'reduce';
    this.operationEcommerceMetricsService.trackEcommerceDispatchSectionHelperClicked(
      action,
      operation,
      sectionId,
      salesChannel,
    );
  }
}
