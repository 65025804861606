import { Inject, Injectable } from '@angular/core';
import { isAfter, isEqual } from 'date-fns';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { LoggerService } from '@dougs/core/logger';
import { SalesChannel } from '@dougs/ecommerce/dto';
import { Breakdown, Operation, OperationSection } from '@dougs/operations/dto';
import { AbstractOperationsStateService, OPERATION_STATE_TOKEN, OperationService } from '@dougs/operations/shared';
import { OperationMetricsService } from './operation-metrics.service';
import { OperationComponentService } from './operation.component.service';

@Injectable()
export class OperationDetailsComponentService {
  private readonly lastBreakdownSubject: Subject<Breakdown> = new Subject<Breakdown>();
  lastBreakdown$: Observable<Breakdown> = this.lastBreakdownSubject.asObservable();
  constructor(
    @Inject(OPERATION_STATE_TOKEN) private readonly refactoOperationsStateService: AbstractOperationsStateService<any>,
    private readonly operationMetricsService: OperationMetricsService,
    private readonly operationComponentService: OperationComponentService,
    private readonly companyStateService: CompanyStateService,
    private readonly operationService: OperationService,
    private readonly logger: LoggerService,
  ) {}

  public canSeeOldEcommerceDispatchOperation$: Observable<boolean> = this.companyStateService.activeCompany$.pipe(
    map((company) => company.flags.includes('oldEcommerceDispatch')),
  );

  public onAddBreakdown(operation: Operation, sectionId: string): void {
    this.operationMetricsService.trackBreakdownCreating();
    this.operationComponentService.addBreakdown(this.createBreakdown(operation, sectionId));
  }

  public onRemoveBreakdown(): void {
    const breakdowns: Breakdown[] = [...this.operationComponentService.operation.breakdowns];
    const lastBreakdown: Breakdown | undefined = this.orderBreakdownByCreation(
      breakdowns.filter((breakdown) => !breakdown.isFee && !breakdown.isCounterpart),
    ).pop();
    if (lastBreakdown) {
      this.lastBreakdownSubject.next(lastBreakdown);
    }
  }

  public addBreakdownFromMiscellaneousOperation(operation: Operation, showAddBreakdown: boolean): void {
    if (!showAddBreakdown) {
      return;
    }
    if (operation?.sections?.length > 0) {
      const lastSection: OperationSection = operation.sections[operation.sections.length - 1];
      this.onAddBreakdown(operation, lastSection.id);
    }
  }

  public orderBreakdownByCreation(breakdowns: Breakdown[] = []): Breakdown[] {
    return breakdowns.sort((a, b) => {
      if (!a.createdAt) {
        return 1;
      } else if (!b.createdAt) {
        return -1;
      }
      const aDate: Date = new Date(a.createdAt);
      const bDate: Date = new Date(b.createdAt);
      if (isEqual(aDate, bDate)) {
        return 0;
      }
      return isAfter(aDate, bDate) ? 1 : -1;
    });
  }

  shouldShowEcommerceDispatchFile(operation: Operation): boolean {
    return (
      this.operationService.isEcommerceOperation(operation) && isAfter(new Date(operation.date), new Date('2021-09-01'))
    );
  }

  isShopifyDispatchOperation(operation: Operation, salesChannels: SalesChannel[]): boolean {
    if (operation.type === 'dispatch:ecommerce:salesChannel') {
      return salesChannels.some(
        (salesChannel) => salesChannel.type === 'shopify' && operation?.metadata?.salesChannelId === salesChannel.id,
      );
    }
    return false;
  }

  private createBreakdown(operation: Operation, sectionId: string): Breakdown {
    const amountDifference: number = operation.amount - operation.totalAmount;
    const sectionFound: OperationSection | undefined = operation.sections.find((section) => section.id === sectionId);
    const isInbound: boolean = amountDifference >= 0 ? operation.isInbound : !operation.isInbound;

    return {
      categoryWording: 'Non catégorisé',
      section: sectionId,
      amount: Math.abs(amountDifference),
      isInbound: sectionFound?.invertSign ? !isInbound : isInbound,
    } as Breakdown;
  }
}
