import { Injectable } from '@angular/core';
import { MetricsService } from '@dougs/core/metrics';

@Injectable()
export class OperationReceiptMatchingComponentService {
  constructor(private readonly metricsService: MetricsService) {}

  trackVendorInvoiceDrawerOpened(): void {
    this.metricsService.pushMixpanelEvent('Vendor Invoice Drawer Opened', {
      Module: 'Accounting',
    });
  }
}
