<div class="file-list" *ngIf="operationFilesComponentService.operation$() as operation">
  <dougs-file-input
    (click)="$event.stopPropagation()"
    (uploadFiles)="
      operationFilesComponentService.onUploadFiles(
        operation,
        $event,
        isInOperationListModal,
        inCategorizationHelp,
        mixpanelModalName
      )
    "
    [shouldAddVendorInvoice]="true"
    [shouldAddSalesInvoice]="true"
    (attachFromVendorInvoice)="operationFilesComponentService.openVendorInvoiceModal(operation)"
    (attachFromSalesInvoice)="operationFilesComponentService.openAttachSalesInvoiceModal(operation)"
  ></dougs-file-input>
  @for (sourceDocumentAttachment of operation?.sourceDocumentAttachments ?? []; track 'id') {
    <dougs-source-document-pill
      (click)="$event.stopPropagation()"
      [sourceDocumentAttachment]="sourceDocumentAttachment"
      [enableAnimation]="enableFileAnimation"
      [canDelete]="!operation.validated"
      [disableRouting]="disableReceiptMatching"
      (deleteSourceDocumentAttachment)="operationFilesComponentService.detachSourceDocument($event)"
    />
  }
  <dougs-file-input
    *ngIf="(userStateService.loggedInUser$ | async)?.flags?.includes('canImportCsvFilesComptastart')"
    (click)="$event.stopPropagation()"
    (uploadFiles)="operationFilesComponentService.onImportCsvFile(operation, $event)"
    fileInputText="Importer un fichier CSV Comptastart"
  ></dougs-file-input>
</div>
