import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalCloseDirective, ModalContentDirective, ModalTitleDirective, PanelInfoComponent } from '@dougs/ds';
import { SelectCandidateSalesChannelComponentService } from '../../services/select-candidate-sales-channel.component.service';

@Component({
  selector: 'dougs-select-candidate-sales-channel',
  templateUrl: './select-candidate-sales-channel.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ModalTitleDirective, ModalCloseDirective, ModalContentDirective, CommonModule, PanelInfoComponent],
  providers: [SelectCandidateSalesChannelComponentService],
})
export class SelectCandidateSalesChannelComponent {
  constructor(
    public readonly selectCandidateSalesChannelComponentService: SelectCandidateSalesChannelComponentService,
  ) {}

  trackByValue(index: number): number {
    return index;
  }
}
