import { Inject, Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { SourceDocumentAttachment, SourceDocumentType } from '@dougs/core/files';
import { MetricsService } from '@dougs/core/metrics';
import { ModalService } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { AbstractOperationsStateService, OPERATION_STATE_TOKEN } from '@dougs/operations/shared';
import { OperationEcommerceMetricsService } from './operation-ecommerce-metrics.service';
import { OperationEcommerceComponentService } from './operation-ecommerce.component.service';
import { OperationMetricsService } from './operation-metrics.service';

@Injectable()
export class OperationFilesComponentService {
  constructor(
    @Inject(OPERATION_STATE_TOKEN) private readonly abstractOperationsStateService: AbstractOperationsStateService<any>,
    public readonly operationEcommerceComponentService: OperationEcommerceComponentService,
    private readonly operationMetricsService: OperationMetricsService,
    private readonly operationEcommerceMetricsService: OperationEcommerceMetricsService,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
  ) {}

  private readonly operation: WritableSignal<Operation | null> = signal(null);
  operation$: Signal<Operation | null> = this.operation.asReadonly();

  public enableFillAnimations = false;

  setOperation(operation: Operation): void {
    this.operation.set(operation);
  }

  async onUploadFiles(
    operation: Operation,
    files: FileList,
    isInOperationListModal: boolean,
    inCategorizationHelp: boolean,
    mixpanelModalName?: string,
  ): Promise<void> {
    this.enableFillAnimations = true;
    if (this.operationEcommerceComponentService.salesChannel) {
      this.operationEcommerceMetricsService.trackEcommerceOperationDispatchFilesAttached(
        Object.values(files).map((file: File) => file.name),
        operation,
        this.operationEcommerceComponentService.salesChannel,
      );
    }

    this.operationMetricsService.trackOperationFileAttached(operation);
    this.operationMetricsService.trackFilesUpload(isInOperationListModal, inCategorizationHelp, mixpanelModalName);
    await this.abstractOperationsStateService.uploadSourceDocuments(operation, Array.from(files));
  }

  async onImportCsvFile(operation: Operation, files: FileList): Promise<void> {
    await this.abstractOperationsStateService.uploadSourceDocuments(
      operation,
      Array.from(files),
      SourceDocumentType.MISCELLANEOUS_ACCOUNTING_LINES_CSV,
    );
  }

  async openVendorInvoiceModal(operation: Operation): Promise<void> {
    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { AttachVendorInvoiceModalComponent } = await import('@dougs/vendor-invoice/ui');
    await lastValueFrom(this.modalService.open(AttachVendorInvoiceModalComponent, { data: operation }).afterClosed$);
    await this.abstractOperationsStateService.refreshOperationById(operation.companyId, operation.id);
    this.metricsService.pushMixpanelEvent('Accounting Operation File Attached', {
      'CTA Location': 'Operation Detail Button',
      'Operation Id': operation.id,
      'File Location': 'Supplier Invoice',
    });
  }

  async openAttachSalesInvoiceModal(operation: Operation): Promise<void> {
    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { AttachSalesInvoiceModalComponent } = await import('@dougs/sales-invoice/ui');
    await lastValueFrom(this.modalService.open(AttachSalesInvoiceModalComponent, { data: operation }).afterClosed$);
    this.metricsService.pushMixpanelEvent('Accounting Operation File Attached', {
      'CTA Location': 'Operation Detail Button',
      'Operation Id': operation.id,
      'File Location': 'Sales Invoice',
    });
  }

  async detachSourceDocument(sourceDocumentAttachment: SourceDocumentAttachment): Promise<void> {
    if (this.operation()) {
      const initialOperation: Operation = { ...(this.operation() as Operation) };
      const updatedOperation: Operation = {
        ...initialOperation,
        sourceDocumentAttachments:
          initialOperation.sourceDocumentAttachments?.filter(
            (sourceDocumentAttachmentIterated) => sourceDocumentAttachmentIterated.id !== sourceDocumentAttachment.id,
          ) ?? [],
      };
      this.operation.set(updatedOperation);
      const hasBeenDetached = !!(await this.abstractOperationsStateService.detachSourceDocument(
        updatedOperation,
        sourceDocumentAttachment.id,
      ));
      if (this.operationEcommerceComponentService.salesChannel) {
        this.operationEcommerceMetricsService.trackEcommerceOperationDispatchFilesRemoved(
          sourceDocumentAttachment.sourceDocument.file.name ?? 'unknown',
          initialOperation,
          this.operationEcommerceComponentService.salesChannel,
        );
      }
      if (!hasBeenDetached) {
        this.operation.set(initialOperation);
      }
    }
  }
}
