import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { Intercom } from '@supy-io/ngx-intercom';
import { Subscription } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import {
  AutofocusDirective,
  ControlFormFieldDirective,
  FormFieldComponent,
  HelpModalComponent,
  MODAL_DATA,
  ModalCloseDirective,
  ModalContentDirective,
  ModalRef,
  ModalService,
  ModalTitleDirective,
  PanelInfoComponent,
  PrefixFormFieldDirective,
} from '@dougs/ds';
import { AssociationSlotCandidate, AssociationSlotCandidates } from '@dougs/operations/dto';

@Component({
  selector: 'dougs-select-association-candidate',
  templateUrl: './select-association-candidate.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    CommonModule,
    FormFieldComponent,
    ReactiveFormsModule,
    AutofocusDirective,
    PrefixFormFieldDirective,
    ControlFormFieldDirective,
    PanelInfoComponent,
  ],
})
export class SelectAssociationCandidateComponent implements OnInit, OnDestroy, AfterViewInit {
  searchControl = new UntypedFormControl('');
  itemSearch: AssociationSlotCandidate[] = [];
  showAll = false;
  europeanStateModalButton?: HTMLElement;

  private searchSubscription!: Subscription;

  constructor(
    @Inject(MODAL_DATA)
    public data: { candidates: AssociationSlotCandidates; enableSearch: boolean },
    private readonly modalRef: ModalRef<AssociationSlotCandidate, AssociationSlotCandidate>,
    private readonly modalService: ModalService,
    private readonly intercom: Intercom,
    private readonly elementRef: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.showOnlyPreferredItems) {
      this.itemSearch = this.data.candidates.items.filter((item) =>
        this.data.candidates.preferredItems.includes(item.value as string),
      );
    } else {
      this.itemSearch = this.data.candidates.items;
    }

    this.searchSubscription = this.searchControl.valueChanges
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((search: string) => {
        if (search) {
          this.showAll = true;
        }
        this.itemSearch = this.data.candidates.items.filter(
          (item: AssociationSlotCandidate) =>
            item.label?.toLowerCase().includes(search.trim().toLowerCase()) ||
            item.description?.toLowerCase().includes(search.trim().toLowerCase()),
        );
        this.cdr.markForCheck();
      });

    this.cdr.markForCheck();
  }

  ngAfterViewInit(): void {
    this.addClickEventListenerOnInjectedHTML();
  }

  get showOnlyPreferredItems() {
    return !this.showAll && this.data.candidates.preferredItems?.length > 0;
  }

  displayAll(e: Event) {
    e.preventDefault();
    this.showAll = true;

    this.itemSearch = this.data.candidates.items;
    this.cdr.markForCheck();
  }

  selectItem(item: AssociationSlotCandidate): void {
    this.modalRef.close(item);
  }

  public onOpenIntercom(): void {
    this.intercom.showNewMessage(this.data.candidates.descriptor.angular2AdditionalIntercomMessage);
  }

  private addClickEventListenerOnInjectedHTML(): void {
    this.europeanStateModalButton = this.elementRef.nativeElement.querySelector('.european-state-modal-button');
    if (this.europeanStateModalButton) {
      this.europeanStateModalButton.addEventListener('click', (e) => this.openEuropeanUnionStatesModal(e));
    }
  }

  private openEuropeanUnionStatesModal(e: Event): void {
    e.stopPropagation();
    this.modalService.open(HelpModalComponent, {
      data: {
        title: "Pays membres de l'Union européenne",
        body:
          "Les pays membres de l'Union européenne sont l'Allemagne, l'Autriche, la Belgique, la Bulgarie, Chypre, " +
          "la Croatie, le Danemark, l'Espagne, l'Estonie, la Finlande, " +
          "la France, la Grèce, la Hongrie, l'Irlande, l'Italie, la Lettonie, " +
          'la Lituanie, le Luxembourg, Malte, les Pays-Bas, la Pologne, ' +
          'le Portugal, la République tchèque, la Roumanie, ' +
          'la Slovaquie, la Slovénie et la Suède.',
      },
    });
  }

  ngOnDestroy() {
    this.searchSubscription.unsubscribe();
    if (this.europeanStateModalButton) {
      this.europeanStateModalButton.removeEventListener('click', (e) => this.openEuropeanUnionStatesModal(e));
    }
  }
}
