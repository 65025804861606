import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { EcommerceSaleSection, SalesChannel } from '@dougs/ecommerce/dto';
import { Operation } from '@dougs/operations/dto';
import { EcommerceSectionTitleComponentService } from '../../../../../services/ecommerce-section-title.component.service';
import { EcommercePanelInfoComponent } from '../ecommerce-panel-info/ecommerce-panel-info.component';

const ANIMATION = '300ms ease-in-out';

@Component({
  selector: 'dougs-ecommerce-section-title',
  standalone: true,
  imports: [CommonModule, EcommercePanelInfoComponent],
  providers: [EcommerceSectionTitleComponentService],
  templateUrl: './ecommerce-section-title.component.html',
  styleUrls: ['./ecommerce-section-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({ height: 0, marginTop: 0, overflow: 'hidden', opacity: 0 }),
        animate(ANIMATION, style({ height: '*', marginTop: '*', opacity: 1 })),
      ]),
      transition(':leave', [
        style({ overflow: 'hidden' }),
        animate(ANIMATION, style({ height: 0, marginTop: 0, opacity: 0 })),
      ]),
    ]),
  ],
})
export class EcommerceSectionTitleComponent {
  constructor(public readonly ecommerceSectionTitleComponentService: EcommerceSectionTitleComponentService) {}

  @Input() section: EcommerceSaleSection | undefined;
  @Input() operation?: Operation;
  @Input() salesChannel?: SalesChannel;
}
