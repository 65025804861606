import { Inject, Injectable } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { take } from 'rxjs/operators';
import { MODAL_DATA, ModalRef, ModalService } from '@dougs/ds';
import { Investment } from '@dougs/investment/dto';
import {
  Association,
  AssociationSlotCandidate,
  AssociationSlotCandidates,
  Breakdown,
  Operation,
} from '@dougs/operations/dto';
import { OperationAssociationStateService } from '@dougs/operations/shared';

@Injectable()
export class SelectCandidateInvestmentComponentService {
  offset = 0;
  limit = 100;

  constructor(
    @Inject(MODAL_DATA)
    public data: {
      candidates: AssociationSlotCandidates;
      breakdown: Breakdown;
      operation: Operation;
      association: Association;
    },
    private readonly modalRef: ModalRef<Record<string, string>, AssociationSlotCandidate>,
    private readonly modalService: ModalService,
    private readonly operationAssociationStateService: OperationAssociationStateService,
  ) {}

  selectItem(item: Record<string, string>): void {
    this.modalRef.close(item);
  }

  async createInvestment(e: Event): Promise<void> {
    e.preventDefault();
    // eslint-disable-next-line @nx/enforce-module-boundaries
    const { InvestmentModalComponent } = await import('@dougs/investment/ui');
    const result = (await lastValueFrom(
      this.modalService
        .open<Investment>(InvestmentModalComponent, {
          data: {
            mode: 'creation',
            operation: this.data.operation,
            breakdown: this.data.breakdown,
          },
        })
        .afterClosed$.pipe(take(1)),
    )) as { data: { id: number } };

    if (result.data) {
      this.selectItem({
        id: result.data.id.toString(),
      });
    }
  }

  async onScroll(): Promise<void> {
    this.offset++;

    const candidates: AssociationSlotCandidates | null = await this.operationAssociationStateService.loadMoreCandidates(
      this.data.operation.companyId,
      this.data.operation.id,
      this.data.breakdown.id,
      this.data.association.name,
      'investment',
      this.offset * this.limit,
    );

    if (candidates) {
      this.data.candidates.items = [...this.data.candidates.items, ...candidates.items];
    }
  }
}
