<div dougsModalTitle>
  <h6>{{ selectCandidateSalesChannelComponentService.data.candidates.descriptor.title }}</h6>
  <i dougsModalClose *ngIf="!selectCandidateSalesChannelComponentService.data.disableClose" class="fal fa-times"></i>
</div>
<div dougsModalContent class="modal-select-association-container">
  <p class="small" *ngIf="!selectCandidateSalesChannelComponentService.data.candidates.items.length">
    Vous n'avez pas encore renseigné de canal de ventes dans Dougs.
  </p>
  <ul class="select-candidate">
    <li
      *ngFor="let item of selectCandidateSalesChannelComponentService.data.candidates.items; trackBy: trackByValue"
      class="p-8"
      (click)="selectCandidateSalesChannelComponentService.selectItem({ id: item.value })"
    >
      <div>
        {{ item.label }}
      </div>
    </li>
  </ul>
  <dougs-panel-info class="mt-8">
    Votre canal de vente n'est pas dans la liste&nbsp;?
    <a href="#" (click)="selectCandidateSalesChannelComponentService.openCreateSalesChannelModal($event)">
      Créez-le ici
    </a>
    .
  </dougs-panel-info>
</div>
