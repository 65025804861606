import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonComponent, TooltipDirective } from '@dougs/ds';
import { Operation } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
import { OperationActionsComponentService } from '../../../../services/operation-actions.component.service';

@Component({
  selector: 'dougs-operation-actions',
  templateUrl: './operation-actions.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ButtonComponent, TooltipDirective, CommonModule],
})
export class OperationActionsComponent {
  isLoading = false;
  isEditable = false;
  isDeletable = false;
  isLocked = false;

  constructor(
    private readonly operationService: OperationService,
    public readonly operationActionsComponentService: OperationActionsComponentService,
  ) {}

  @Input() isInOperationListModal = false;
  @Input() mixpanelModalName?: string;

  @Output() emitOperationAction: EventEmitter<boolean> = new EventEmitter();

  private _operation!: Operation;

  get operation() {
    return this._operation;
  }

  @Input()
  set operation(operation: Operation) {
    this._operation = operation;
    this.isEditable = this.operationService.isOperationEditable(operation);
    this.isLocked = this.operationService.isLocked(operation);
    this.isDeletable = this.operationService.isOperationDeletable(operation);
  }

  editOperation(e: Event): void {
    this.emitOperationAction.emit(true);
    e.stopPropagation();
    this.operationActionsComponentService.editOperation(this.operation);
  }

  async removeOperation(e: Event): Promise<void> {
    this.emitOperationAction.emit(true);
    e.stopPropagation();
    await this.operationActionsComponentService.removeOperation(
      this.operation,
      this.isInOperationListModal,
      this.mixpanelModalName,
    );
  }
}
