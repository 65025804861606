<div class="operation-detail__associations">
  <ng-container *ngFor="let association of counterpartBreakdown?.associations">
    <dougs-operation-breakdown-association
      [operation]="operation"
      [breakdown]="counterpartBreakdown"
      class="mt-4"
      *ngIf="association.show && !operation.isDispatch"
      [association]="association"
      [isInOperationListModal]="isInOperationListModal"
      [mixpanelModalName]="mixpanelModalName"
    ></dougs-operation-breakdown-association>
  </ng-container>
  <ng-container *ngFor="let association of mainBreakdown?.associations">
    <dougs-operation-breakdown-association
      [operation]="operation"
      [breakdown]="mainBreakdown"
      class="mt-4"
      *ngIf="association.show && !operation.isDispatch && !isOperationOpen"
      [association]="association"
    ></dougs-operation-breakdown-association>
  </ng-container>
</div>
