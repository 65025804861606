import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Breakdown, Operation } from '@dougs/operations/dto';
import { OperationService } from '@dougs/operations/shared';
import { OperationBreakdownAssociationComponent } from '../operation-breakdown-association/operation-breakdown-association.component';

@Component({
  selector: 'dougs-operation-associations',
  templateUrl: './operation-associations.component.html',
  styleUrls: ['./operation-associations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, OperationBreakdownAssociationComponent],
})
export class OperationAssociationsComponent {
  private _operation!: Operation;
  counterpartBreakdown!: Breakdown | null;
  mainBreakdown!: Breakdown | null;

  @Input() isOperationOpen?: boolean = false;
  @Input() isInOperationListModal = false;
  @Input() mixpanelModalName?: string;

  @Input()
  set operation(operation: Operation) {
    this._operation = operation;
    this.counterpartBreakdown = this.operationService.getCounterPartBreakdown(operation);
    this.mainBreakdown = this.operationService.getMainBreakdown(operation);
  }

  get operation() {
    return this._operation;
  }

  constructor(private readonly operationService: OperationService) {}
}
